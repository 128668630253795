import {
    completeRegistration,
    initiatePurchase,
    purchase,
    verifyPhone,
} from '@perpay-web/fintech/beacon/eventDefinitions/Branch';
import {
    ANALYTICS_CREATE_APPLICATION_SUBMITTED,
    ANALYTICS_CREATE_PURCHASE_SUBMITTED_FROM_MAGENTO,
    ANALYTICS_SIGNUP_SUCCESS,
    ANALYTICS_ONBOARDING_VERIFY_PHONE_NUMBER,
} from '@perpay-web/fintech/constants/actionTypes';
import { getOrderAmountWithoutCredit } from '@perpay-web/fintech/utils/checkoutUtils';

const branchEventsMap = {
    [ANALYTICS_CREATE_APPLICATION_SUBMITTED]: purchase((action, prevState) => ({
        id: action.payload.order_uuid,
        uuid: action.payload.user_uuid,
        amount: getOrderAmountWithoutCredit(prevState),
    })),

    [ANALYTICS_SIGNUP_SUCCESS]: completeRegistration((action) => ({
        userId: action.payload.email,
        uuid: action.payload.uuid,
    })),

    [ANALYTICS_CREATE_PURCHASE_SUBMITTED_FROM_MAGENTO]: initiatePurchase(
        (action, prevState) => ({
            id: action.payload.order_uuid,
            uuid: action.payload.user_uuid,
            amount: getOrderAmountWithoutCredit(prevState),
        }),
    ),

    [ANALYTICS_ONBOARDING_VERIFY_PHONE_NUMBER]: verifyPhone((action) => ({
        userId: action.payload.email,
        uuid: action.payload.uuid,
    })),
};

export default branchEventsMap;
