import { createQuerySuggestionsPlugin as createAlgoliaQuerySuggestionsPlugin } from '@algolia/autocomplete-plugin-query-suggestions';
import {
    createLocalStorage,
    createRecentSearchesPlugin as createAlgoliaRecentSearchesPlugin,
    search,
} from '@algolia/autocomplete-plugin-recent-searches';
import { getAlgoliaResults } from '@algolia/autocomplete-preset-algolia';

import {
    AutocompleteCategoryList,
    styles,
} from '@perpay-web/storefront/components/composite/AutocompleteCategoryList/AutocompleteCategoryList';
import { AutocompleteQuerySuggestionItem } from '@perpay-web/storefront/components/composite/AutocompleteQuerySuggestionItem/AutocompleteQuerySuggestionItem';
import { AutocompleteRecentSearchItem } from '@perpay-web/storefront/components/composite/AutocompleteRecentSearchItem/AutocompleteRecentSearchItem';
import { AutocompleteRecentlyViewedList } from '@perpay-web/storefront/components/composite/AutocompleteRecentlyViewedList/AutocompleteRecentlyViewedList';
import TrendingSearchesList from '@perpay-web/storefront/components/composite/TrendingSearchesList/TrendingSearchesList';
import {
    OBJECT_ID,
    IN_STOCK,
} from '@perpay-web/storefront/constants/algoliaFilterTypes';
import {
    QUERY_SUGGESTIONS_INDEX_NAME,
    SEARCH_INDEX_NAME,
} from '@perpay-web/storefront/constants/algoliaSearchConfig';
import { retrieveViewedProducts } from '@perpay-web/storefront/utils/productUtils';
import { firstItemOf } from '@perpay-web/utils/arrayUtils';
import { formatProductDetailsURL } from '@perpay-web/utils/urlUtils';

import { and, or, equals, getSearchPath } from './algoliaUtils';
import {
    categoryMenuDefaultAdapter,
    categoryMenuNewArrivalsAdapter,
} from './categoryMenuAdapters';

const AutocompleteSource = {
    RecentSearches: 'Recent Searches',
    QuerySuggestions: 'Query Suggestions',
    TrendingSearches: 'Trending Searches',
    Categories: 'Categories',
    RecentlyViewed: 'Recently Viewed',
};

const RECENT_SEARCHES_LIMIT = 5;
const RECENTLY_VIEWED_LIMIT = 10;

export const getIsRecentSearch = (source) =>
    source.sourceId === AutocompleteSource.RecentSearches;

export const getIsQuerySuggestions = (source) =>
    source.sourceId === AutocompleteSource.QuerySuggestions;

const recentSearchesStorage = createLocalStorage({
    key: 'autocomplete',
    limit: RECENT_SEARCHES_LIMIT,
    search,
});

/**
 * @param {import('@algolia/autocomplete-plugin-recent-searches').CreateRecentSearchesLocalStorageOptions<AutocompleteQuerySuggestionsHit>} options
 */
export const createRecentSearchesPlugin = ({ onSelect, ...options }) =>
    createAlgoliaRecentSearchesPlugin({
        storage: recentSearchesStorage,
        transformSource: ({ source }) => ({
            ...source,
            sourceId: AutocompleteSource.RecentSearches,
            onSelect,
            getItemInputValue: ({ item }) => item.label,
            getItemUrl: ({ item }) => getSearchPath(item.label),
            templates: {
                item: AutocompleteRecentSearchItem,
            },
            ...options,
        }),
    });

/**
 * @param {import('@algolia/autocomplete-core').AutocompleteSource & import('@algolia/autocomplete-plugin-query-suggestions').CreateQuerySuggestionsPluginParams<AutocompleteQuerySuggestionsHit>} options
 */
export const createQuerySuggestionsPlugin = ({
    onSelect,
    searchClient,
    ...options
}) =>
    createAlgoliaQuerySuggestionsPlugin({
        ...options,
        searchClient,
        indexName: QUERY_SUGGESTIONS_INDEX_NAME,
        categoryAttribute: ['instant_search', 'facets', 'exact_matches'],
        transformSource: ({ source }) => ({
            ...source,
            sourceId: AutocompleteSource.QuerySuggestions,
            onSelect,
            getItems: (params) => {
                // When no query exists, do not show suggestions
                if (!params.query) {
                    return [];
                }
                return source.getItems(params);
            },
            getItemInputValue: ({ item }) => item.query,
            getItemUrl: ({ item }) => getSearchPath(item.query),
            templates: {
                item: AutocompleteQuerySuggestionItem,
            },
        }),
    });

export const createRecentlyViewedPlugin = ({
    onSelect,
    searchClient,
    ...options
}) => ({
    getSources() {
        return [
            {
                ...options,
                sourceId: AutocompleteSource.RecentlyViewed,
                onSelect,
                getItems: ({ query }) => {
                    if (query) return [];

                    const productIds = retrieveViewedProducts();

                    return getAlgoliaResults({
                        searchClient,
                        queries: [
                            {
                                indexName: SEARCH_INDEX_NAME,
                                params: {
                                    query,
                                    filters: and([
                                        or(
                                            productIds.map((id) =>
                                                equals(OBJECT_ID, id),
                                            ),
                                        ),
                                        equals(IN_STOCK, true),
                                    ]),
                                    hitsPerPage: RECENTLY_VIEWED_LIMIT,
                                },
                            },
                        ],
                        transformResponse: ({ hits }) =>
                            productIds
                                .map((id) => {
                                    const item = firstItemOf(hits).find(
                                        (hit) => hit.objectID === id,
                                    );

                                    if (!item) return null;

                                    return {
                                        objectID: item.objectID,
                                        name: item.name,
                                        image: firstItemOf(item.images),
                                        slug: item.slug,
                                    };
                                })
                                .filter(Boolean),
                    });
                },
                getItemInputValue: ({ item }) => item.name,
                getItemUrl: ({ item }) =>
                    formatProductDetailsURL(item.objectID, item.slug),
                templates: {
                    list: AutocompleteRecentlyViewedList,
                },
            },
        ];
    },
});

/**
 * @param {import('@algolia/autocomplete-core').AutocompleteSource} options
 * @returns {import('@algolia/autocomplete-core').AutocompleteSource}
 */
export const createCategoriesSource = (options = {}) => ({
    ...options,
    sourceId: AutocompleteSource.Categories,
    onSelect: options.onSelect,
    getItems: ({ query }) => {
        if (query) return [];

        return [
            {
                id: 1,
                name: 'New',
                href: categoryMenuNewArrivalsAdapter(),
                className: styles['star-icon'],
            },
            {
                id: 2,
                name: 'Electronics',
                href: categoryMenuDefaultAdapter('electronics'),
                className: styles['game-pad-icon'],
            },
            {
                id: 3,
                name: 'Home',
                href: categoryMenuDefaultAdapter('home'),
                className: styles['table-lamp-hanging-icon'],
            },
            {
                id: 4,
                name: 'Fashion',
                href: categoryMenuDefaultAdapter('fashion-beauty'),
                className: styles['tank-top-icon'],
            },
            {
                id: 5,
                name: 'Pet',
                href: categoryMenuDefaultAdapter('pet'),
                className: styles['dog-bone-icon'],
            },
            {
                id: 6,
                name: 'Lifestyle',
                href: categoryMenuDefaultAdapter('lifestyle'),
                className: styles['bicycle-icon'],
            },
            {
                id: 7,
                name: 'Auto',
                href: categoryMenuDefaultAdapter('auto'),
                className: styles['car-icon'],
            },
            {
                id: 8,
                name: 'Baby & Kids',
                href: categoryMenuDefaultAdapter('baby-kids'),
                className: styles['baby-trolley-icon'],
            },
        ];
    },
    getItemInputValue: ({ item }) => item.name,
    getItemUrl: ({ item }) => item.href,
    templates: {
        list: AutocompleteCategoryList,
    },
});

/**
 * @param {import('@algolia/autocomplete-core').AutocompleteSource} options
 * @returns {import('@algolia/autocomplete-core').AutocompleteSource}
 */
export const createTrendingSearchesSource = (options = {}) => ({
    ...options,
    sourceId: AutocompleteSource.TrendingSearches,
    onSelect: options.onSelect,
    getItems: ({ query }) => {
        if (query) return [];

        return [
            {
                id: 1,
                name: 'PS5',
            },
            {
                id: 2,
                name: 'TV',
            },
            {
                id: 3,
                name: 'iPhone',
            },
            {
                id: 4,
                name: 'iPad',
            },
            {
                id: 5,
                name: 'Laptop',
            },
            {
                id: 6,
                name: 'Shoes',
            },
            {
                id: 7,
                name: 'Xbox',
            },
            {
                id: 8,
                name: 'Apple Watch',
            },
            {
                id: 9,
                name: 'Jewelry',
            },
            {
                id: 10,
                name: 'Perfume',
            },
            {
                id: 11,
                name: 'Toys',
            },
            {
                id: 12,
                name: 'Mattress',
            },
            {
                id: 13,
                name: 'Ugg',
            },
        ];
    },
    getItemInputValue: ({ item }) => item.name,
    getItemUrl: ({ item }) => getSearchPath(item.name),
    templates: {
        list: TrendingSearchesList,
    },
});

export const clearAlgoliaRecentSearches = () => {
    let recentSearchList = recentSearchesStorage.getAll();
    while (recentSearchList.length > 0) {
        recentSearchList.forEach((item) => {
            recentSearchesStorage.onRemove(item.id);
        });

        recentSearchList = recentSearchesStorage.getAll();
    }
};

export const addAlgoliaRecentSearchItem = (value) => {
    const recentSearchList = recentSearchesStorage.getAll();
    if (!recentSearchList.some((item) => item.id === value)) {
        recentSearchesStorage.onAdd({
            id: value,
            label: value,
        });
    }
};

export const removeAlgoliaRecentSearchItem = (value) => {
    const recentSearchList = recentSearchesStorage.getAll();
    if (recentSearchList.some((item) => item.id === value)) {
        recentSearchesStorage.onRemove(value);
    }
};
