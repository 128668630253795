import { getIsReactNative } from '@perpay-web/native-client/getIsReactNative';
import { isBefore } from '@perpay-web/utils/dateUtils';
import { createLocalStorageCacheService } from '@perpay-web/utils/localStorageUtils';
import {
    isAndroidAgent,
    isAppBrowserAgent,
    isIosAgent,
} from '@perpay-web/utils/userAgentUtils';

const nativeUpgradeNotificationDataCacheService =
    createLocalStorageCacheService('NATIVE_UPGRADE_NOTIFICATION_DATA');

const isCordovaApp = () => isAppBrowserAgent() && !getIsReactNative();

export const shouldShowNativeUpgradeNotification = () => {
    if (!isCordovaApp()) return false;
    if (!isIosAgent() && !isAndroidAgent()) return false;

    const notificationData =
        nativeUpgradeNotificationDataCacheService.retrieve();

    if (!notificationData || !notificationData.expiration) return true;

    const expirationDate = new Date(notificationData.expiration);
    const now = new Date();

    return isBefore(expirationDate, now);
};

export const createNewNativeUpgradeNotification = (daysUntilExpiration) => {
    const expiration = new Date().setDate(
        new Date().getDate() + daysUntilExpiration,
    );
    nativeUpgradeNotificationDataCacheService.store({
        expiration,
    });
};
