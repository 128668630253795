export const createLocalStorageCacheService = (key) => {
    const retrieve = () => {
        if (!window.localStorage) {
            return null;
        }

        const value = window.localStorage.getItem(key);
        if (!value) {
            return null;
        }

        try {
            const parsedValue = JSON.parse(window.localStorage.getItem(key));
            return parsedValue;
        } catch (unusedError) {
            return null;
        }
    };

    const store = (value) => {
        if (window.localStorage) {
            window.localStorage.setItem(key, JSON.stringify(value));
        }
    };

    const remove = () => {
        if (window.localStorage) {
            window.localStorage.removeItem(key);
        }
    };

    return {
        retrieve,
        store,
        remove,
    };
};
