import { UseFetchCardApplicationStatusDataModuleProvider } from '@perpay-web/fintech/hooks/dataModule/useFetchCardApplicationStatusContext';
import { UsePasskeyAuthenticateBeginDataModuleProvider } from '@perpay-web/fintech/hooks/dataModule/usePasskeyAuthenticateBeginContext';
import { UsePasskeyAuthenticateCompleteDataModuleProvider } from '@perpay-web/fintech/hooks/dataModule/usePasskeyAuthenticateCompleteContext';
import { UsePasskeyRegisterBeginDataModuleProvider } from '@perpay-web/fintech/hooks/dataModule/usePasskeyRegisterBeginContext';
import { UsePasskeyRegisterCompleteDataModuleProvider } from '@perpay-web/fintech/hooks/dataModule/usePasskeyRegisterCompleteContext';
import { UseSetDeviceInformationDataModuleProvider } from '@perpay-web/fintech/hooks/dataModule/useSetDeviceInformationContext';
import { UseSubmitCardApplicationDataModuleProvider } from '@perpay-web/fintech/hooks/dataModule/useSubmitCardApplicationContext';
import { UseUpdateUserInfoDataModuleProvider } from '@perpay-web/fintech/hooks/dataModule/useUpdateUserInfoContext';
import { UseValidateAddressForCardMigrationProvider } from '@perpay-web/fintech/hooks/dataModule/useValidateAddressForCardMigrationContext';
import { createCombinedProvider } from '@perpay-web/utils/componentUtils';

const DataModuleProviders = createCombinedProvider(
    UseFetchCardApplicationStatusDataModuleProvider,
    UseUpdateUserInfoDataModuleProvider,
    UseSetDeviceInformationDataModuleProvider,
    UseSubmitCardApplicationDataModuleProvider,
    UseValidateAddressForCardMigrationProvider,
    UsePasskeyRegisterBeginDataModuleProvider,
    UsePasskeyRegisterCompleteDataModuleProvider,
    UsePasskeyAuthenticateBeginDataModuleProvider,
    UsePasskeyAuthenticateCompleteDataModuleProvider,
);

export default DataModuleProviders;
