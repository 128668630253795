import { createDataModuleActions } from '@perpay-web/data-module/createDataModuleActions';

const passkeyRegisterBeginActions = createDataModuleActions(
    'PASSKEY_REGISTER_BEGIN',
);
export const passkeyRegisterBegin = passkeyRegisterBeginActions.dataRequest;
export const passkeyRegisterBeginSuccess =
    passkeyRegisterBeginActions.dataSuccess;
export const passkeyRegisterBeginError = passkeyRegisterBeginActions.dataError;
export const passkeyRegisterBeginReset = passkeyRegisterBeginActions.dataReset;

const passkeyRegisterCompleteActions = createDataModuleActions(
    'PASSKEY_REGISTER_COMPLETE',
);
export const passkeyRegisterComplete =
    passkeyRegisterCompleteActions.dataRequest;
export const passkeyRegisterCompleteSuccess =
    passkeyRegisterCompleteActions.dataSuccess;
export const passkeyRegisterCompleteError =
    passkeyRegisterCompleteActions.dataError;
export const passkeyRegisterCompleteReset =
    passkeyRegisterCompleteActions.dataReset;

const passkeyAuthenticateBeginActions = createDataModuleActions(
    'PASSKEY_AUTHENTICATE_BEGIN',
);
export const passkeyAuthenticateBegin =
    passkeyAuthenticateBeginActions.dataRequest;
export const passkeyAuthenticateBeginSuccess =
    passkeyAuthenticateBeginActions.dataSuccess;
export const passkeyAuthenticateBeginError =
    passkeyAuthenticateBeginActions.dataError;
export const passkeyAuthenticateBeginReset =
    passkeyAuthenticateBeginActions.dataReset;

const passkeyAuthenticateCompleteActions = createDataModuleActions(
    'PASSKEY_AUTHENTICATE_COMPLETE',
);
export const passkeyAuthenticateComplete =
    passkeyAuthenticateCompleteActions.dataRequest;
export const passkeyAuthenticateCompleteSuccess =
    passkeyAuthenticateCompleteActions.dataSuccess;
export const passkeyAuthenticateCompleteError =
    passkeyAuthenticateCompleteActions.dataError;
export const passkeyAuthenticateCompleteReset =
    passkeyAuthenticateCompleteActions.dataReset;
