import { createAction } from '@reduxjs/toolkit';

import { useAnimatedModal } from '@perpay-web/components/base/Modal/useAnimatedModal';
import { useModal } from '@perpay-web/components/base/Modal/useModal';
import { useActionObservableCallback } from '@perpay-web/hooks/useActionObservableCallback';
import { hookAction$ } from '@perpay-web/services/singletons';
import { createContextProvider } from '@perpay-web/utils/componentUtils';

export const cardAutoPayMakePaymentsModalSetIsModalOpen = createAction(
    'CARD_AUTO_PAY_MAKE_PAYMENT_MODAL::SET_IS_MODAL_OPEN',
);

const useCardAutoPayMakePaymentModal = () => {
    const { isModalOpen, setIsModalOpen } = useModal();
    const {
        isAnimatedModalOpen,
        setIsAnimatedModalOpen,
        animatedModalClassName,
    } = useAnimatedModal({ isModalOpen, setIsModalOpen });

    return {
        setIsAnimatedModalOpen,
        isAnimatedModalOpen,
        animatedModalClassName,
    };
};

const providerFactory = (Context) => {
    const Provider = ({ children }) => {
        const hook = useCardAutoPayMakePaymentModal();

        useActionObservableCallback(
            hookAction$,
            cardAutoPayMakePaymentsModalSetIsModalOpen().type,
            (value) => hook.setIsAnimatedModalOpen(value),
        );

        return <Context.Provider value={hook}>{children}</Context.Provider>;
    };

    return Provider;
};

export const [
    CardAutoPayMakePaymentModalProvider,
    useCardAutoPayMakePaymentModalContext,
] = createContextProvider(providerFactory);
