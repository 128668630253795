import { composeDataModules } from '@perpay-web/data-module/composeDataModules';
import { fetchCardAccountApplicationForOnboardingDataModule } from '@perpay-web/fintech/dataModules/fetchCardAccountApplicationForOnboarding';
import { fetchFeaturesForOnboardingDataModule } from '@perpay-web/fintech/dataModules/fetchFeaturesForOnboarding';
import { fetchUserInfoDataModule } from '@perpay-web/fintech/dataModules/fetchUserInfo';
import { fetchUserStatusDataModule } from '@perpay-web/fintech/dataModules/fetchUserStatus';
import { gateCardApplyDataModule } from '@perpay-web/fintech/dataModules/gateCardApply';
import { useCardConsolidationABTestAdapter } from '@perpay-web/hooks/abTests/useCardConsolidationABTest';
import { useMetalCardABTestAdapter } from '@perpay-web/hooks/abTests/useMetalCardABTest';

const CardOnboardingSwitcherDataModule = composeDataModules([
    fetchUserStatusDataModule,
    fetchFeaturesForOnboardingDataModule,
    fetchCardAccountApplicationForOnboardingDataModule,
    fetchUserInfoDataModule,
    gateCardApplyDataModule,
    useMetalCardABTestAdapter,
    useCardConsolidationABTestAdapter,
]);

const getRoot = (state) => state.dataModules.cardOnboardingSwitcher;

export const cardOnboardingSwitcherDataModule =
    CardOnboardingSwitcherDataModule({
        getRoot,
        key: 'CARD_ONBOARDING_SWITCHER',
    });

export { getIsFirstTimeBorrower } from '@perpay-web/fintech/dataModules/fetchUserStatus';
