import { getCompanyUnverified } from '@perpay-web/fintech/selectors/entities/jobs';
import { getABTestEnabled } from '@perpay-web/fintech/selectors/shared/abTests';

// TODO: Remove once we have fully switched over to using new pinwheelEnabled column
const getPinwheelABFlag = (state) => getABTestEnabled(state, 'pinwheel');

export const getIsPinwheelEnabled = (state) => {
    const companyUnverified = getCompanyUnverified(state);
    if (companyUnverified) {
        return true;
    }

    return getPinwheelABFlag(state);
};
