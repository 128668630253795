import { MAX_SSN_VERIFICATION_ATTEMPTS } from '@perpay-web/fintech/constants/identityVerificationConstants';
import { getUserInfo } from '@perpay-web/fintech/selectors/entities/userInfo';

export const getRoot = (state) => state.ui.identityVerification;

export const getIsLoading = (state) => getRoot(state).loading;

export const getIsVerifyingIdentity = (state) =>
    getRoot(state).verifyingIdentity;

export const getIdentityVerificationErrors = (state) => getRoot(state).errors;

export const getServiceError = (state) => getRoot(state).serviceError;

export const getSuccessRedirectPath = (state) =>
    getRoot(state).successRedirectPath;

export const getCheckoutUUID = (state) => getRoot(state).checkoutUUID;

const getSsnVerificationAttempts = (state) =>
    getUserInfo(state).ssnVerificationAttempts;

export const getSsnVerificationAttemptsRemaining = (state) =>
    MAX_SSN_VERIFICATION_ATTEMPTS - getSsnVerificationAttempts(state);
