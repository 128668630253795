import {
    BACKEND_VERIFY_IDENTITY,
    STORE_VERIFY_IDENTITY_SUCCESS,
    STORE_VERIFY_IDENTITY_SERVICE_ERROR,
    STORE_VERIFY_IDENTITY_ERROR,
    FETCH_USER_INFO,
    REPLACE_USER_INFO,
    STORE_SET_VERIFY_IDENTITY_SUCCESS_REDIRECT_PATH,
    STORE_SET_VERIFY_IDENTITY_CHECKOUT_UUID,
    STORE_VERIFY_IDENTITY_RESET,
    STORE_VERIFY_IDENTITY_MOUNTED,
} from '@perpay-web/fintech/constants/actionTypes';
import { IDENTITY_VERIFICATION_STEPS } from '@perpay-web/fintech/constants/steps/identityVerificationSteps';
import { withReduxStepsReducer } from '@perpay-web/hooks/useReduxSteps';

const initialState = {
    mounted: false,
    verifyingIdentity: false,
    loading: true,
    identityVerified: false,
    errors: {},
    successRedirectPath: null,
    checkoutUUID: null,
    serviceError: false,
};

const identityVerificationReducer = (state = initialState, action = {}) => {
    const { type, payload } = action;

    switch (type) {
        case BACKEND_VERIFY_IDENTITY:
            return {
                ...state,
                verifyingIdentity: true,
            };
        case STORE_VERIFY_IDENTITY_SUCCESS:
            return {
                ...state,
                identityVerified: payload.ssnIsVerified,
                verifyingIdentity: false,
            };
        case STORE_VERIFY_IDENTITY_ERROR:
            return {
                ...state,
                verifyingIdentity: false,
                errors: payload,
            };
        case STORE_VERIFY_IDENTITY_SERVICE_ERROR:
            return {
                ...state,
                verifyingIdentity: false,
                errors: payload,
                serviceError: true,
            };
        case STORE_VERIFY_IDENTITY_MOUNTED:
            return { ...state, mounted: true };
        case FETCH_USER_INFO:
            return state.mounted ? { ...state, loading: true } : state;
        case REPLACE_USER_INFO:
            return state.mounted ? { ...state, loading: false } : state;
        case STORE_SET_VERIFY_IDENTITY_SUCCESS_REDIRECT_PATH:
            return { ...state, successRedirectPath: payload };
        case STORE_SET_VERIFY_IDENTITY_CHECKOUT_UUID:
            return { ...state, checkoutUUID: payload };
        case STORE_VERIFY_IDENTITY_RESET:
            return { ...state, ...initialState };
        default:
            return state;
    }
};

export default withReduxStepsReducer(
    IDENTITY_VERIFICATION_STEPS,
    identityVerificationReducer,
);
