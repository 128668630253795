import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { postAuthRedirect } from '@perpay-web/fintech/actions/router';
import { authentication } from '@perpay-web/fintech/settings/singletons';
import { useMount } from '@perpay-web/hooks/useMount';

export const useLoginRedirectCheck = () => {
    const [isLoadingLoginRedirectCheck, setIsLoadingLoginRedirectCheck] =
        useState(true);
    const dispatch = useDispatch();

    useMount(() => {
        const shouldRedirect = authentication.getIsAuthenticated();
        if (shouldRedirect) {
            dispatch(postAuthRedirect());
            return;
        }
        setIsLoadingLoginRedirectCheck(false);
    });

    return { isLoadingLoginRedirectCheck };
};
