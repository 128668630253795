import { EMPTY } from 'rxjs';
import { filter, mergeMap } from 'rxjs/operators';

import { clearAlgoliaRecentSearches } from '@perpay-web/storefront/utils/algoliaSources';
import { removeMagentoToken } from '@perpay-web/storefront/utils/magentoAccessTokenUtils';
import {
    removeAlgoliaCache,
    removeAlgoliaToken,
    removeAlgoliaSearchQueryId,
} from '@perpay-web/storefront/utils/searchUtils';

export const cleanupMagentoTokenOnLogout = (action$, state$, { auth$ }) =>
    auth$.pipe(
        filter(({ previousState, nextState }) => {
            const isTransition =
                previousState.tokenState !== nextState.tokenState;
            if (!isTransition) {
                return false;
            }
            return nextState.tokenState === 'unauthenticated';
        }),
        mergeMap(() => {
            removeMagentoToken();
            return EMPTY;
        }),
    );

export const cleanupAlgoliaOnLogout = (action$, state$, { auth$ }) =>
    auth$.pipe(
        filter(({ previousState, nextState }) => {
            const isTransition =
                previousState.tokenState !== nextState.tokenState;
            if (!isTransition) {
                return false;
            }
            // HACK: I would import this state enum value,
            // but I haven't done the work yet to make that possible.
            // Update this when the auth service is its own package.
            return nextState.tokenState === 'unauthenticated';
        }),
        mergeMap(() => {
            removeAlgoliaToken();
            removeAlgoliaCache();
            clearAlgoliaRecentSearches();
            removeAlgoliaSearchQueryId();

            // Don't pass anything for redux to dispatch
            return EMPTY;
        }),
    );
