import { ofType } from 'redux-observable';
import { switchMap, mergeMap } from 'rxjs/operators';

import * as actions from '@perpay-web/fintech/actions/entities/cardActivity';
import { CARD_ACTIVITY_PER_PAGE } from '@perpay-web/fintech/constants/cardActivity';
import { CARD_ACTIVITY_ENDPOINT } from '@perpay-web/fintech/constants/urls';
import { getLimitOffsetPaginatedEndpoint } from '@perpay-web/fintech/epics/utils/pagination';
import { handleErrorMessageWithFallback } from '@perpay-web/observable/operators/handleErrorMessageWithFallback';

export const fetchCardActivity = (action$, state$, { get }) =>
    action$.pipe(
        ofType(actions.fetchCardActivity().type),
        switchMap((action) => {
            const payload = action.payload || {};
            if (!payload.limit) {
                return get(CARD_ACTIVITY_ENDPOINT);
            }
            return get(
                getLimitOffsetPaginatedEndpoint({
                    endpoint: CARD_ACTIVITY_ENDPOINT,
                    limit: payload.limit,
                    offset: payload.offset || 0,
                }),
            );
        }),
        mergeMap((results) => [
            actions.fetchCardActivitySuccess({
                all: results.response,
                pageCount: Math.ceil(
                    (results.response || []).length / CARD_ACTIVITY_PER_PAGE,
                ),
            }),
        ]),
        handleErrorMessageWithFallback((error) => [
            actions.fetchCardActivityError(error),
        ]),
    );
