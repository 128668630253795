import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { routeToLocation } from '@perpay-web/fintech/actions/router';
import {
    SHOP_HOME,
    SHOP_SEARCH_PATH,
} from '@perpay-web/fintech/constants/paths';
import { SHOP_PATHS_BY_CATEGORY } from '@perpay-web/fintech/constants/shopCategories';
import { paths } from '@perpay-web/fintech/props/appPaths';
import { getLastReactStorefrontPage } from '@perpay-web/utils/routeUtils';

export const useShopPath = () => {
    const dispatch = useDispatch();

    const lastShopPath = getLastReactStorefrontPage() || paths.shop.path;

    const getCategoryHref = (category) => SHOP_PATHS_BY_CATEGORY[category];

    const redirectToSearch = useCallback(
        (query) => {
            const path =
                query && query.trim().length > 0
                    ? SHOP_SEARCH_PATH.replace(':query', query)
                    : SHOP_HOME;
            dispatch(routeToLocation(path));
        },
        [dispatch],
    );

    const routeToShop = useCallback(() => {
        dispatch(routeToLocation(paths.shop.path));
    }, [dispatch]);

    return {
        shopPath: paths.shop.path,
        lastShopPath,
        getCategoryHref,
        redirectToSearch,
        routeToShop,
    };
};
