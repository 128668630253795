import { createDataModuleActions } from '@perpay-web/data-module/createDataModuleActions';

const validateAddressForMVP2Actions = createDataModuleActions(
    'VALIDATE_ADDRESS_FOR_MVP_2',
);
export const validateAddressForMVP2 = validateAddressForMVP2Actions.dataRequest;
export const validateAddressSuccessForMVP2 =
    validateAddressForMVP2Actions.dataSuccess;
export const validateAddressErrorForMVP2 =
    validateAddressForMVP2Actions.dataError;
export const validateAddressResetForMVP2 =
    validateAddressForMVP2Actions.dataReset;

const validateAddressForCardMigrationActions = createDataModuleActions(
    'VALIDATE_ADDRESS_FOR_CARD_MIGRATION',
);
export const validateAddressForCardMigration =
    validateAddressForCardMigrationActions.dataRequest;
export const validateAddressSuccessForCardMigration =
    validateAddressForCardMigrationActions.dataSuccess;
export const validateAddressErrorForCardMigration =
    validateAddressForCardMigrationActions.dataError;
export const validateAddressResetForCardMigration =
    validateAddressForCardMigrationActions.dataReset;
