import { ofType } from 'redux-observable';
import { switchMap, mergeMap } from 'rxjs/operators';

import {
    fetchLearnMore as fetchLearnMoreAction,
    fetchLearnMoreSuccess,
    fetchLearnMoreError,
} from '@perpay-web/fintech/actions/entities/learnMore';
import { LEARN_MORE_ENDPOINT } from '@perpay-web/fintech/constants/urls';
import { handleError } from '@perpay-web/observable/operators/handleError';

export const fetchLearnMore = (action$, state$, { get }) =>
    action$.pipe(
        ofType(fetchLearnMoreAction().type),
        switchMap(() => get(LEARN_MORE_ENDPOINT)),
        mergeMap((results) => [fetchLearnMoreSuccess(results.response)]),
        handleError((error) => [fetchLearnMoreError(error)]),
    );
