import emitter from '@marvelapp/react-ab-test/lib/emitter';

import {
    DISABLED_VARIANT,
    ENABLED_VARIANT,
    PAYROLL_PHONE_HELP,
    PAYROLL_PHONE_HELP_RATIOS,
    UNVERIFIED_PAYROLL_PHONE_HELP,
    UNVERIFIED_PAYROLL_PHONE_HELP_RATIOS,
} from '@perpay-web/constants/experiments';
import { importReactAbTestExperimentDebugger } from '@perpay-web/fintech/utils/imports/importReactAbTestExperimentDebugger';
import { defineExperiment } from '@perpay-web/utils/experimentUtils';

const EXPERIMENT_PARAM = 'enabledAbTests';
const EXPERIMENT_PARAM_SEPARATOR = ',';
const EXPERIMENT_PARAM_KEY_VALUE_SEPARATOR = ':';

export function configureAbTesting() {
    if (!window.PRODUCTION && !window.STAGING) {
        // NOTE: We import this module dynamically to avoid bundling it with our main bundle.
        // Even if this block is removed during minification because of the production guard,
        // if it was a non-dynamic import it would remain in the main bundle.
        importReactAbTestExperimentDebugger().then((experimentDebugger) => {
            experimentDebugger.enable();
        });
    }

    // Define expirements here, e.g.
    // above:
    // import { defineExperiment } from '@perpay-web/fintech/utils/experimentUtils';
    //
    // import {
    //     RECURRING_PAYMENT_PORTAL,
    //     RECURRING_PAYMENT_PORTAL_DISABLED,
    //     RECURRING_PAYMENT_PORTAL_ENABLED,
    //     RECURRING_PAYMENT_PORTAL_RATIOS,
    // } from '@perpay-web/fintech/constants/experiments';
    //
    // here:
    // defineExperiment(
    //     RECURRING_PAYMENT_PORTAL,
    //     [RECURRING_PAYMENT_PORTAL_DISABLED, RECURRING_PAYMENT_PORTAL_ENABLED],
    //     RECURRING_PAYMENT_PORTAL_RATIOS,
    // );

    defineExperiment(
        PAYROLL_PHONE_HELP,
        [DISABLED_VARIANT, ENABLED_VARIANT],
        PAYROLL_PHONE_HELP_RATIOS,
    );

    defineExperiment(
        UNVERIFIED_PAYROLL_PHONE_HELP,
        [DISABLED_VARIANT, ENABLED_VARIANT],
        UNVERIFIED_PAYROLL_PHONE_HELP_RATIOS,
    );

    if (!window.PRODUCTION) {
        const enabledAbTestsParam =
            new URLSearchParams(window.location.search).get(EXPERIMENT_PARAM) ||
            '';
        const experiments = enabledAbTestsParam
            .split(EXPERIMENT_PARAM_SEPARATOR)
            .map((param) => param.split(EXPERIMENT_PARAM_KEY_VALUE_SEPARATOR));

        experiments.forEach(([experimentName, variantName]) => {
            emitter.setActiveVariant(experimentName, variantName);
        });
    }
}
