import { useEffect } from 'react';

import {
    PAYROLL_PRIMER_EDUCATIONAL_API_NAME,
    PAYROLL_PRIMER_EDUCATIONAL_STORE_NAME,
} from '@perpay-web/constants/src/experiments';
import { createDataModuleAdapter } from '@perpay-web/data-module/src/composeDataModules';
import { createDataModuleActions } from '@perpay-web/data-module/src/createDataModuleActions';
import { getIsLoadingOrUnrequested } from '@perpay-web/data-module/src/useDataModule';
import { useReduxDataModuleHookConnection } from '@perpay-web/data-module/src/useReduxDataModuleHookConnection';
import {
    getIsUserInTestGroup,
    useABTestDataModule,
} from '@perpay-web/hooks/abTests/useABTestDataModule';
import { hookAction$ } from '@perpay-web/services/singletons';

const dataModuleFetchActions = createDataModuleActions(
    'FETCH_PAYROLL_PRIMER_EDUCATIONAL_AB_TEST',
);
const fetchPayrollPrimerScreenABTest = dataModuleFetchActions.dataRequest;
const fetchPayrollPrimerScreenABTestSuccess =
    dataModuleFetchActions.dataSuccess;
const fetchPayrollPrimerScreenABTestError = dataModuleFetchActions.dataError;
const fetchPayrollPrimerScreenABTestReset = dataModuleFetchActions.dataReset;

export const usePayrollPrimerScreenABTest = () => {
    const dataModule = useABTestDataModule();
    const { state, dataRequest } = dataModule;

    const isLoading = getIsLoadingOrUnrequested(state);
    const isUserInTestGroup = getIsUserInTestGroup(
        state,
        PAYROLL_PRIMER_EDUCATIONAL_STORE_NAME,
    );

    useReduxDataModuleHookConnection({
        dataModule,
        hookAction$,
        request: fetchPayrollPrimerScreenABTest,
        success: fetchPayrollPrimerScreenABTestSuccess,
        error: fetchPayrollPrimerScreenABTestError,
        reset: fetchPayrollPrimerScreenABTestReset,
    });

    useEffect(() => {
        if (window.STORYBOOK) return;
        dataRequest(PAYROLL_PRIMER_EDUCATIONAL_API_NAME);
    }, [dataRequest]);

    return { isLoading, isUserInTestGroup };
};

export const usePayrollPrimerScreenABTestAdapter = createDataModuleAdapter(
    fetchPayrollPrimerScreenABTest,
    fetchPayrollPrimerScreenABTestSuccess,
    fetchPayrollPrimerScreenABTestError,
);
