import {
    STORE_CARD_CREATE_AUTO_PAY_REPLACE_SELECTED_BANK_UUID,
    STORE_CARD_CREATE_AUTO_PAY_REPLACE_FREQUENCY,
    STORE_CARD_CREATE_AUTO_PAY_SET_INITIAL_SELECTED_FREQUENCY,
    STORE_CARD_CREATE_AUTO_PAY_REPLACE_DATE,
    STORE_CARD_CREATE_AUTO_PAY_REPLACE_DATE_TYPE,
    STORE_CARD_CREATE_AUTO_PAY_REPLACE_AMOUNT,
    STORE_CARD_CREATE_AUTO_PAY_REPLACE_AMOUNT_TYPE,
    STORE_CARD_CREATE_AUTO_PAY_RESET_DATE_AND_AMOUNT,
    STORE_CARD_CREATE_AUTO_PAY_RESET,
} from '@perpay-web/fintech/constants/actionTypes';
import { ONE_TIME } from '@perpay-web/fintech/constants/autoPayScheduleFrequencyTypes';
import { CARD_CREATE_AUTO_PAY_STEPS } from '@perpay-web/fintech/constants/steps/cardCreateAutoPaySteps';
import { withReduxStepsReducer } from '@perpay-web/hooks/useReduxSteps';

const initialState = {
    selectedBankUuid: null,
    selectedFrequency: ONE_TIME,
    selectedDate: null,
    selectedDateType: null,
    amount: null,
    selectedAmountType: null,
};

const cardCreateAutoPayReducer = (state = initialState, action = {}) => {
    const { type, payload } = action;

    switch (type) {
        case STORE_CARD_CREATE_AUTO_PAY_REPLACE_SELECTED_BANK_UUID:
            return { ...state, selectedBankUuid: payload };
        case STORE_CARD_CREATE_AUTO_PAY_REPLACE_FREQUENCY:
        case STORE_CARD_CREATE_AUTO_PAY_SET_INITIAL_SELECTED_FREQUENCY:
            return { ...state, selectedFrequency: payload };
        case STORE_CARD_CREATE_AUTO_PAY_REPLACE_DATE:
            return { ...state, selectedDate: payload };
        case STORE_CARD_CREATE_AUTO_PAY_REPLACE_DATE_TYPE:
            return { ...state, selectedDateType: payload };
        case STORE_CARD_CREATE_AUTO_PAY_REPLACE_AMOUNT:
            return { ...state, amount: payload };
        case STORE_CARD_CREATE_AUTO_PAY_REPLACE_AMOUNT_TYPE:
            return { ...state, selectedAmountType: payload };
        case STORE_CARD_CREATE_AUTO_PAY_RESET_DATE_AND_AMOUNT:
            return {
                ...state,
                selectedDate: null,
                selectedDateType: null,
                amount: null,
                selectedAmountType: null,
            };
        case STORE_CARD_CREATE_AUTO_PAY_RESET:
            return { ...state, ...initialState };
        default:
            return state;
    }
};

export default withReduxStepsReducer(
    CARD_CREATE_AUTO_PAY_STEPS,
    cardCreateAutoPayReducer,
);
