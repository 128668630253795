import { composeDataModules } from '@perpay-web/data-module/composeDataModules';
import {
    getCardRewardsAvailable,
    getAllTimeCardRewardsBalance,
    fetchBorrowerCreditSummaryDataModule,
} from '@perpay-web/fintech/dataModules/fetchBorrowerCreditSummary';
import {
    getBorrowerCreditResults,
    getBorrowerCreditsPageCount,
    getBorrowerCreditsPageNumber,
    fetchBorrowerCreditsDataModule,
} from '@perpay-web/fintech/dataModules/fetchBorrowerCredits';

const BorrowerCreditsDataModule = composeDataModules([
    fetchBorrowerCreditsDataModule,
    fetchBorrowerCreditSummaryDataModule,
]);

const getRoot = (state) => state.dataModules.borrowerCredits;

export const borrowerCreditsDataModule = BorrowerCreditsDataModule({
    getRoot,
    key: 'BORROWER_CREDITS',
});

export {
    getCardRewardsAvailable,
    getAllTimeCardRewardsBalance,
    getBorrowerCreditResults,
    getBorrowerCreditsPageCount,
    getBorrowerCreditsPageNumber,
};
