import { composeDataModules } from '@perpay-web/data-module/composeDataModules';
import {
    coreSummaryDataModule,
    getPaymentPlanSummary,
} from '@perpay-web/fintech/dataModules/coreSummary';
import { fetchActivePaymentsDataModule } from '@perpay-web/fintech/dataModules/fetchActivePayments';
import {
    fetchMarketplaceDashboardSummaryDataModule,
    getNotification,
} from '@perpay-web/fintech/dataModules/fetchMarketplaceDashboardSummary';
import { useSpendingLimitLoyaltyABTestAdapter } from '@perpay-web/hooks/abTests/useSpendingLimitLoyaltyABTest';

const MarketplaceDetailsDataModule = composeDataModules([
    coreSummaryDataModule,
    fetchActivePaymentsDataModule,
    fetchMarketplaceDashboardSummaryDataModule,
    useSpendingLimitLoyaltyABTestAdapter,
]);

const getRoot = (state) => state.dataModules.marketplaceDetails;

export const marketplaceDetailsDataModule = MarketplaceDetailsDataModule({
    getRoot,
    key: 'MARKETPLACE_DETAILS',
});

export { getPaymentPlanSummary, getNotification };
