import { useDispatch, useSelector } from 'react-redux';

import { getIsPartnerOnboarded } from '@perpay-web/authentication/selectors';
import CardOnboardingSwitcher from '@perpay-web/fintech/components/screens/CardOnboardingSwitcher/CardOnboardingSwitcher';
import {
    IS_CONSOLIDATED_CARD_FLOW_ACTIVE,
    METAL_CARD_IS_LAUNCHED,
} from '@perpay-web/fintech/constants/flags';
import {
    cardOnboardingSwitcherDataModule,
    getIsFirstTimeBorrower,
} from '@perpay-web/fintech/dataModules/composed/cardOnboardingSwitcher';
import { useCardConsolidationABTest } from '@perpay-web/hooks/abTests/useCardConsolidationABTest';
import { useMetalCardABTest } from '@perpay-web/hooks/abTests/useMetalCardABTest';

const CardOnboardingSwitcherContainer = () => {
    const dispatch = useDispatch();

    const { isUserInTestGroup: isUserMetalCardEnabled } = useMetalCardABTest();
    const { isUserInTestGroup: isConsolidatedCardFlowEnabled } =
        useCardConsolidationABTest();

    const isLoading = useSelector(
        cardOnboardingSwitcherDataModule.getIsLoadingOrUnrequested,
    );
    const isFirstTimeBorrower = useSelector(getIsFirstTimeBorrower);
    const isMetalCard = isUserMetalCardEnabled && METAL_CARD_IS_LAUNCHED;
    const isConsolidatedCardFlow =
        isConsolidatedCardFlowEnabled && IS_CONSOLIDATED_CARD_FLOW_ACTIVE;
    const isPartnerOnboarded = useSelector(getIsPartnerOnboarded);

    return (
        <CardOnboardingSwitcher
            isLoading={isLoading}
            isFirstTimeBorrower={isFirstTimeBorrower}
            isMetalCard={isMetalCard}
            isConsolidatedCardFlow={isConsolidatedCardFlow}
            isPartnerOnboarded={isPartnerOnboarded}
            onMount={() => {
                dispatch(cardOnboardingSwitcherDataModule.dataRequest());
            }}
            onUnmount={() => {
                dispatch(cardOnboardingSwitcherDataModule.dataReset());
            }}
        />
    );
};

export default CardOnboardingSwitcherContainer;
