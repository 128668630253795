/**
 * Create a new Date object at the same date and time as the given date object.
 * This is useful because Date objects are mutable, so it helps to compute new values with
 * copies rather than mutating the existing values.
 */
export const copyDate = (date) => new Date(date);

/**
 * Add a provided x amount of months to a provided date
 */
export const addMonths = (date, numMonths) => {
    const newDate = new Date(date);
    return newDate.setMonth(newDate.getMonth() + numMonths);
};

/**
 * Create a date object representing the given date object's date in UTC.
 * d = new Date()
 * // 2022-01-21T19:37:43.099Z
 * toIsoDate(d)
 * // 2022-01-21T00:00:00.000Z
 * Non-ISO dates must be passed to the `toIsoDate` function before they can be passed to the
 * formatters below. The requirement for isoDates is is set by the `timeZone: 'UTC'`
 * configuration property. This is desirable because it can be ambiguous which timezone is used
 * when formatting non-ISO Date objects.
 */
export const toIsoDate = (date) =>
    new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));

/**
 * Returns true if date1 is before date2 (time excluded)
 * @param {Date} date1
 * @param {Date} date2
 * @returns {boolean}
 */
export const isDateBefore = (date1, date2) =>
    toIsoDate(date1) < toIsoDate(date2);

const monthDayNumericFormatter = new Intl.DateTimeFormat('en-US', {
    month: 'numeric',
    day: 'numeric',
    timeZone: 'UTC',
});

/**
 * Returns true if date1 is before date2 (exact time included)
 * @param {Date} date1
 * @param {Date} date2
 * @returns {boolean}
 */
export const isBefore = (date1, date2) => date1 < date2;

/**
 * Format an ISO8601 timestamp to display the day and month numerically
 * e.g. '2022-01-21' => '01/21'
 */
export function formatNumericMonthDay(isoDate) {
    const date = new Date(isoDate);
    return monthDayNumericFormatter.format(date);
}

const longWeekdayFormatter = new Intl.DateTimeFormat('en-US', {
    weekday: 'long',
    timeZone: 'UTC',
});

/**
 * Format an ISO8601 timestamp to display the name of the day of the week
 * e.g. '2022-01-21' => 'Friday'
 */
export function formatLongWeekday(isoDate) {
    const date = new Date(isoDate);
    return longWeekdayFormatter.format(date);
}

const longMonthNumericDayFormatter = new Intl.DateTimeFormat('en-US', {
    month: 'long',
    day: 'numeric',
    timeZone: 'UTC',
});

/**
 * Format an ISO8601 timestamp to display the name of the month and the numeric day of the week
 * e.g. '2022-01-21' => 'January 21'
 */
export function formatLongMonthNumericDay(isoDate) {
    const date = new Date(isoDate);
    return longMonthNumericDayFormatter.format(date);
}

const numericDayFormatter = new Intl.DateTimeFormat('en-US', {
    day: 'numeric',
    timeZone: 'UTC',
});

/**
 * Format an ISO8601 timestamp to display the name of the month and the numeric day of the week
 * e.g. '2022-01-21' => '21'
 */
export function formatNumericDay(isoDate) {
    const date = new Date(isoDate);
    return numericDayFormatter.format(date);
}

const longWeekdayNumericMonthDayYearFormatter = new Intl.DateTimeFormat(
    'en-US',
    {
        weekday: 'long',
        month: 'numeric',
        day: 'numeric',
        year: 'numeric',
        timeZone: 'UTC',
    },
);

/**
 * Format an ISO8601 timestamp to display the name of the day of the week and the numeric date.
 * e.g. '2022-01-21' => 'Friday, 1/21/2022'
 */
export function formatLongWeekdayNumericMonthDayYear(isoDate) {
    const date = new Date(isoDate);
    return longWeekdayNumericMonthDayYearFormatter.format(date);
}

const numericMonthDayYearFormatter = new Intl.DateTimeFormat('en-US', {
    month: 'numeric',
    day: 'numeric',
    year: 'numeric',
    timeZone: 'UTC',
});

/**
 * Format an ISO8601 timestamp to display the given date.
 * e.g. '2022-01-21' => '1/21/2022'
 */
export function formatNumericMonthDayYear(isoDate) {
    const date = new Date(isoDate);
    return numericMonthDayYearFormatter.format(date);
}

const localNumericMonthDayYearFormatter = new Intl.DateTimeFormat('en-US', {
    month: 'numeric',
    day: 'numeric',
    year: 'numeric',
});

/**
 * The use of this function is specific to dates returned by Deserve (CardActivity and CardStatements), not our system.
 * Format an ISO8601 timestamp to express the given date in user's timezone.
 * Following examples are for a user in "America/New_York" timezone (GMT-4).
 * e.g. '2022-06-09T03:32:43.958526Z' => '6/8/2022'
 *      '2022-06-09T04:32:43.958526Z' => '6/9/2022'
 */
export function formatLocalNumericMonthDayYear(isoDate) {
    const date = new Date(isoDate);
    return localNumericMonthDayYearFormatter.format(date);
}

const shortMonthNumericDayYearFormatter = new Intl.DateTimeFormat('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    timeZone: 'UTC',
});

/**
 * Format an ISO8601 timestamp to display the abbreviated name of the month,
 * the numeric day and the year.
 * e.g. '2022-01-21' => 'Jan 21, 2022'
 */
export function formatShortMonthNumericDayYear(isoDate) {
    const date = new Date(isoDate);
    return shortMonthNumericDayYearFormatter.format(date);
}

/**
 * Format a numeric date (as a string or number) to include the date ordinal
 * e.g. '21' => '21st'
 */
export function getDateOrdinal(day) {
    const numericDay = Number(day);
    if (numericDay > 3 && numericDay < 21) {
        return `${day}th`;
    }
    switch (numericDay % 10) {
        case 1:
            return `${day}st`;
        case 2:
            return `${day}nd`;
        case 3:
            return `${day}rd`;
        default:
            return `${day}th`;
    }
}

const localLongMonthNumericDayYearFormatter = new Intl.DateTimeFormat('en-US', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
});
/**
 * Format an ISO8601 timestamp to display the name of the month,
 * the numeric day and the year.
 * e.g. '2022-01-21' => 'January 21, 2022'
 */
export function formatLocalLongMonthNumericDayYear(isoDate) {
    const date = new Date(isoDate);

    return localLongMonthNumericDayYearFormatter.format(date);
}

/**
 * Format an ISO8601 timestamp to display the abbreviated name of the month,
 * the numeric day and the year.
 * e.g. '2022-01-21' => 'Jan 21st, 2022'
 */
export function formatShortMonthOrdinalDayYearFormatter(isoDate) {
    const date = new Date(isoDate);
    const formatParts = shortMonthNumericDayYearFormatter.formatToParts(date);
    const formattedDate = formatParts
        .map((part) => {
            if (part.type === 'day') {
                return getDateOrdinal(part.value);
            }
            return part.value;
        })
        .join('');

    return formattedDate;
}

const shortMonth2DigitYearFormatter = new Intl.DateTimeFormat('en-US', {
    month: 'short',
    year: '2-digit',
});
/**
 * Format an ISO8601 timestamp to display shortened name of the month and 2 digit year.
 * e.g. '2022-01-21' => "Jan'22"
 */
export function formatShortMonth2DigitYearFormatter(isoDate) {
    const date = new Date(isoDate);
    return shortMonth2DigitYearFormatter.format(date).replace(' ', "'");
}

/**
 * Convert an EPOCH timestamp to a date object
 *
 * @param {number} date
 * @returns {Date}
 */
export function epochToDate(date) {
    return new Date(date * 1000);
}
