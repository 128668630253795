import { PasswordPill } from '@perpay-web/components/base/PasswordPill/PasswordPill';
import {
    hasCapital,
    hasLower,
    hasNumber,
} from '@perpay-web/fintech/utils/passwordCheckUtils';

import styles from './PasswordIndicatorV2.scss';

export const PasswordIndicatorV2 = ({ password }) => (
    <div className={styles['pills-container']}>
        <PasswordPill label='1 lowercase' checked={hasLower(password)} />
        <PasswordPill label='1 capital' checked={hasCapital(password)} />
        <PasswordPill label='1 number' checked={hasNumber(password)} />
    </div>
);
