import { useLayoutEffect } from 'react';

import { useTitleRender } from '@perpay-web/hooks/useTitleContext';

const initialDocumentTitle = document.title || 'Perpay';

export const useAppTitle = () => {
    const renderedTitle = useTitleRender();
    useLayoutEffect(() => {
        document.title = renderedTitle || initialDocumentTitle;
    }, [renderedTitle]);
};
