import { API_URL_BASE, MAGENTO_HOST } from '@perpay-web/constants/urls';

export const AUTH_ENDPOINT = `${API_URL_BASE}/auth/`;
export const AUTH_MFA_ENDPOINT = `${API_URL_BASE}/auth-mfa/`;
export const AUTH_MFA_RESEND_ENDPOINT = `${API_URL_BASE}/auth-mfa/resend`;
export const AUTH_PASSKEY_ENDPOINT = `${API_URL_BASE}/auth/passkey/authenticate/complete`;
export const REFRESH_ENDPOINT = `${API_URL_BASE}/auth-refresh/`;
export const LOGOUT_ENDPOINT = `${API_URL_BASE}/auth-logout/`;
export const STOREFRONT_LOGOUT_URL = `https://${MAGENTO_HOST}/customer/account/logout`;
export const USERS_ENDPOINT = `${API_URL_BASE}/users/`;
export const PARTNER_ONBOARDING_ENDPOINT = `${API_URL_BASE}/users/partner-onboarding/signup/`;

export const PASSKEY_REGISTER_BEGIN = `${API_URL_BASE}/auth/passkey/register/begin`;
export const PASSKEY_REGISTER_COMPLETE = `${API_URL_BASE}/auth/passkey/register/complete`;
export const PASSKEY_AUTHENTICATE_BEGIN = `${API_URL_BASE}/auth/passkey/authenticate/begin`;
