// https://docs.expo.dev/versions/latest/sdk/local-authentication/#securitylevel
export const ENROLLED_AUTHENTICATION_LEVEL_NONE = 0;
// export const ENROLLED_AUTHENTICATION_LEVEL_SECRET = 1; // currently unused
// export const ENROLLED_AUTHENTICATION_LEVEL_BIOMETRIC_WEAK = 2; // currently unused
// export const ENROLLED_AUTHENTICATION_LEVEL_BIOMETRIC_STRONG = 3; // currently unused

// https://docs.expo.dev/versions/latest/sdk/local-authentication/#authenticationtype
export const AUTHENTICATION_TYPE_FINGERPRINT = 1;
export const AUTHENTICATION_TYPE_FACIAL_RECOGNITION = 2;
// export const AUTHENTICATION_TYPE_IRIS = 2; // currently unused

export const BIOMETRIC_TYPE_NONE = 'none';
export const BIOMETRIC_TYPE_FACE_ID = 'face-id';
export const BIOMETRIC_TYPE_TOUCH_ID = 'touch-id';
export const BIOMETRIC_TYPE_ANDROID_BIOMETRICS_FINGERPRINT =
    'android-biometrics-fingerprint';
export const BIOMETRIC_TYPE_ANDROID_BIOMETRICS_FACE = 'android-biometrics-face';
export const BIOMETRIC_TYPE_ANDROID_BIOMETRICS_OTHER =
    'android-biometrics-other';
