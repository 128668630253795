import { createAction } from '@reduxjs/toolkit';

import {
    STORE_PAYMENT_PORTAL_OPEN_ADD_EXTERNAL_CARD_MODAL,
    STORE_PAYMENT_PORTAL_CLOSE_ADD_EXTERNAL_CARD_MODAL,
    STORE_PAYMENT_PORTAL_RESET,
    STORE_PAYMENT_PORTAL_CLEAR_ERRORS,
    PAYMENT_PORTAL_CREATE_EXTERNAL_CARD,
} from '@perpay-web/fintech/constants/actionTypes';

export function openAddExternalCardModal() {
    return {
        type: STORE_PAYMENT_PORTAL_OPEN_ADD_EXTERNAL_CARD_MODAL,
    };
}

export function closeAddExternalCardModal() {
    return {
        type: STORE_PAYMENT_PORTAL_CLOSE_ADD_EXTERNAL_CARD_MODAL,
    };
}

export const closePaymentPortal = createAction('store/PAYMENT_PORTAL::CLOSE');

export function resetPaymentPortal() {
    return {
        type: STORE_PAYMENT_PORTAL_RESET,
    };
}

export function clearPaymentPortalErrors() {
    return {
        type: STORE_PAYMENT_PORTAL_CLEAR_ERRORS,
    };
}

export function paymentPortalCreateExternalCard(cardNumber, expiryDate, cvc) {
    return {
        type: PAYMENT_PORTAL_CREATE_EXTERNAL_CARD,
        payload: {
            cardNumber,
            expiryDate,
            cvc,
        },
    };
}
