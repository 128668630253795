export const OBJECT_ID = 'objectID';
export const DEDUPLICATION_SKU = 'deduplication_sku';
export const SKU = 'sku';
export const HIDE_SEARCH = 'hide_search';
export const PUBLISHED = 'published';
export const PRICE = 'price';
export const TOTAL_PRICE = 'total_price';
export const COLLECTIONS = 'collections';
export const BRAND = 'brand';
export const BRAND_SLUG = 'brand_slug';
export const IN_STOCK = 'in_stock';
export const VENDOR_SLUG = 'vendor_slug';
export const CATEGORY_SLUGS = 'category_slugs';
export const CATEGORY = 'category';
export const NAV_AGE = 'nav_age_timestamp';
