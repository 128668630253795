import { ofType } from 'redux-observable';
import { mergeMap } from 'rxjs/operators';

import { fetchDeductions } from '@perpay-web/fintech/actions/entities/deductions';
import { fetchUserStatus } from '@perpay-web/fintech/actions/entities/userStatus';
import { fetchABTest } from '@perpay-web/fintech/actions/shared/abTests';
import { BACKEND_FETCH_PINWHEEL_ELIGIBILITY } from '@perpay-web/fintech/constants/actionTypes';

export function fetchPinwheelEligibility(action$) {
    return action$.pipe(
        ofType(BACKEND_FETCH_PINWHEEL_ELIGIBILITY),
        mergeMap(() => [
            fetchABTest('pinwheel'),
            fetchUserStatus(),
            fetchDeductions(),
        ]),
    );
}
