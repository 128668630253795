import { ofType } from 'redux-observable';
import { mergeMap } from 'rxjs/operators';

import {
    fetchPlatformDashboardNotifications as fetchPlatformDashboardNotificationsAction,
    fetchPlatformDashboardNotificationsSuccess,
    fetchPlatformDashboardNotificationsError,
} from '@perpay-web/fintech/actions/entities/platformDashboardNotifications';
import { DASHBOARD_PLATFORM_SUMMARY_ENDPOINT } from '@perpay-web/fintech/constants/urls';
import { handleError } from '@perpay-web/observable/operators/handleError';

export const fetchPlatformDashboardNotifications = (action$, state$, { get }) =>
    action$.pipe(
        ofType(fetchPlatformDashboardNotificationsAction().type),
        mergeMap(() => get(DASHBOARD_PLATFORM_SUMMARY_ENDPOINT)),
        mergeMap((results) => [
            fetchPlatformDashboardNotificationsSuccess(
                results.response.platform,
            ),
        ]),
        handleError((error) => [
            fetchPlatformDashboardNotificationsError(error),
        ]),
    );
