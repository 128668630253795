import {
    BACKEND_CREATE_PRIMARY_JOB,
    BACKEND_UPDATE_SALARY_INFO,
    BACKEND_CREATE_PHONE,
    BACKEND_SIGNUP,
    BACKEND_VERIFY_PHONE,
    STORE_CREATE_PRIMARY_JOB_ERROR,
    STORE_REPLACE_PRIMARY_JOB,
    STORE_UPDATE_SALARY_INFO_ERROR,
    STORE_REPLACE_SALARY_INFO,
    STORE_CREATE_PHONE_ERROR,
    STORE_REPLACE_PHONE,
    STORE_RESET_SIGNUP_BACKEND_ERRORS,
    STORE_SIGNUP_ERROR,
    STORE_SIGNUP_SUCCESS,
    STORE_VERIFY_PHONE_ERROR,
    STORE_VERIFY_PHONE_SUCCESS,
    STORE_PHONE_BACK_STEP,
    ANALYTICS_LANDING_PAGE_CLICK_PROFILE,
    ANALYTICS_LANDING_PAGE_CLICK_STOREFRONT,
    ANALYTICS_ONBOARDING_ADD_COMPANY,
    ANALYTICS_ONBOARDING_ADD_PHONE_NUMBER,
    ANALYTICS_ONBOARDING_VERIFY_PHONE_NUMBER,
    ANALYTICS_ONBOARDING_ADD_NET_PAY,
    ANALYTICS_SIGNUP_SUCCESS,
} from '@perpay-web/fintech/constants/actionTypes';
import { dollarToCents } from '@perpay-web/utils/numberUtils';

// salaryInfo \\
export function salaryInfo({
    uuid,
    company,
    selfOnboardingData,
    payCycle,
    estimatedNetPay,
}) {
    return {
        type: BACKEND_UPDATE_SALARY_INFO,
        payload: {
            company:
                company && uuid
                    ? {
                          name: company,
                          uuid,
                      }
                    : undefined,
            selfOnboardingData,
            // We ask for dollars in the UI but the serializer expects cents
            estimatedNetPay: estimatedNetPay
                ? dollarToCents(estimatedNetPay)
                : undefined,
            payCycle,
        },
    };
}

export function salaryInfoError(error) {
    return {
        type: STORE_UPDATE_SALARY_INFO_ERROR,
        payload: error,
    };
}

export function salaryInfoSuccess({
    company,
    eid,
    estimatedNetPay,
    payCycle,
    payCycleDescription,
    uuid,
}) {
    return {
        type: STORE_REPLACE_SALARY_INFO,
        payload: {
            company: { uuid: company.uuid, name: company.name },
            eid,
            // We ask for dollars in the UI but the serializer expects cents
            estimatedNetPay: estimatedNetPay
                ? dollarToCents(estimatedNetPay)
                : undefined,
            payCycle,
            payCycleDescription,
            uuid,
        },
    };
}

// primaryJob \\
export function primaryJob({
    uuid,
    company,
    selfOnboardingData,
    payCycle,
    estimatedNetPay,
}) {
    return {
        type: BACKEND_CREATE_PRIMARY_JOB,
        payload: {
            company: {
                name: company,
                uuid,
            },
            selfOnboardingData,
            // We ask for dollars in the UI but the serializer expects cents
            estimatedNetPay: estimatedNetPay
                ? dollarToCents(estimatedNetPay)
                : undefined,
            payCycle,
        },
    };
}

export function primaryJobError(error) {
    return {
        type: STORE_CREATE_PRIMARY_JOB_ERROR,
        payload: error,
    };
}

export function primaryJobSuccess(jobData) {
    return {
        type: STORE_REPLACE_PRIMARY_JOB,
        payload: jobData,
    };
}

// setPhone \\
export function setPhone(values) {
    return {
        type: BACKEND_CREATE_PHONE,
        payload: values,
    };
}

export function setPhoneError(error) {
    return {
        type: STORE_CREATE_PHONE_ERROR,
        payload: error,
    };
}

export function setPhoneSuccess() {
    return {
        type: STORE_REPLACE_PHONE,
    };
}

// verifyPhone \\
export function verifyPhone(values) {
    return {
        type: BACKEND_VERIFY_PHONE,
        payload: values,
    };
}

export function verifyPhoneError(error) {
    return {
        type: STORE_VERIFY_PHONE_ERROR,
        payload: error,
    };
}

export function verifyPhoneSuccess() {
    return {
        type: STORE_VERIFY_PHONE_SUCCESS,
    };
}

export function resetBackendSignupErrors() {
    return {
        type: STORE_RESET_SIGNUP_BACKEND_ERRORS,
    };
}

export function signup(values) {
    return {
        type: BACKEND_SIGNUP,
        payload: values,
    };
}

export function signupError(error) {
    return {
        type: STORE_SIGNUP_ERROR,
        payload: error,
    };
}

export function signupSuccess(userData) {
    return {
        type: STORE_SIGNUP_SUCCESS,
        payload: userData,
    };
}

export function phoneBackStep() {
    return {
        type: STORE_PHONE_BACK_STEP,
    };
}

export function analyticsLandingClickStorefront() {
    return {
        type: ANALYTICS_LANDING_PAGE_CLICK_STOREFRONT,
    };
}

export function analyticsLandingClickProfile() {
    return {
        type: ANALYTICS_LANDING_PAGE_CLICK_PROFILE,
    };
}

export function analyticsOnboardingAddCompany(onboardingInfo) {
    return {
        type: ANALYTICS_ONBOARDING_ADD_COMPANY,
        payload: onboardingInfo,
    };
}

export function analyticsOnboardingAddPhoneNumber(onboardingInfo) {
    return {
        type: ANALYTICS_ONBOARDING_ADD_PHONE_NUMBER,
        payload: onboardingInfo,
    };
}

export function analyticsOnboardingVerifyPhoneNumber(onboardingInfo) {
    return {
        type: ANALYTICS_ONBOARDING_VERIFY_PHONE_NUMBER,
        payload: onboardingInfo,
    };
}

export function analyticsOnboardingAddNetPay(onboardingInfo) {
    return {
        type: ANALYTICS_ONBOARDING_ADD_NET_PAY,
        payload: onboardingInfo,
    };
}

export function analyticsSignupSuccess(userData) {
    return {
        type: ANALYTICS_SIGNUP_SUCCESS,
        payload: userData,
    };
}
