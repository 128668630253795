import { composeDataModules } from '@perpay-web/data-module/composeDataModules';
import { fetchFeatureEnrollmentsForPerpayPlusDataModule } from '@perpay-web/fintech/dataModules/fetchFeatureEnrollmentsForPerpayPlus';
import { fetchUserInfoDataModule } from '@perpay-web/fintech/dataModules/fetchUserInfo';

const PerpayPlusDataModule = composeDataModules([
    fetchUserInfoDataModule,
    fetchFeatureEnrollmentsForPerpayPlusDataModule,
]);

const getRoot = (state) => state.dataModules.perpayPlus;

export const perpayPlusDataModule = PerpayPlusDataModule({
    getRoot,
    key: 'PERPAY_PLUS',
});
