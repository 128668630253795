import ComponentStackProviders from '@perpay-web/fintech/components/base/ComponentStackProviders/ComponentStackProviders';
import DataModuleProviders from '@perpay-web/fintech/components/base/DataModuleProviders/DataModuleProviders';
import ModalProviders from '@perpay-web/fintech/components/base/ModalProviders/ModalProviders';
import StepsProviders from '@perpay-web/fintech/components/base/StepsProviders/StepsProviders';
import { CardApplicationProvider } from '@perpay-web/fintech/components/screens/CardApplication/useCardApplicationContext';
import { CardMigrationProvider } from '@perpay-web/fintech/hooks/useCardMigrationContext';
import { createCombinedProvider } from '@perpay-web/utils/componentUtils';

const FintechGlobalProviders = createCombinedProvider(
    ComponentStackProviders,
    StepsProviders,
    ModalProviders,
    CardApplicationProvider,
    DataModuleProviders,
    CardMigrationProvider,
);

export default FintechGlobalProviders;
