import {
    AlertIcons,
    AlertSeverity,
} from '@perpay-web/components/base/Alert/Alert';
import {
    ACTIVATE_CARD as ACTIVATE_CARD_CTA_TYPE,
    CLOSED_CARD_HELP_CENTER,
    INCOMPLETE_APPLICATION,
} from '@perpay-web/fintech/constants/alertCtaTypes';
import { FAQ_CLOSED_CARD_ACCOUNT } from '@perpay-web/fintech/constants/urls';
import { paths } from '@perpay-web/fintech/props/appPaths';

export const NO_MARKETING = 'card_no_marketing';
export const NOT_ENABLED = 'card_not_enabled';
export const NO_APPLICATION = 'card_no_application';
export const PRE_APPROVED = 'card_pre_approved';
export const APPLICATION_INCOMPLETE = 'card_application_incomplete';
export const APPLICATION_UPLOAD_DOCUMENTS = 'card_application_upload_documents';
export const APPLICATION_IN_REVIEW = 'card_application_in_review';
export const APPLICATION_IN_ERROR = 'card_application_in_error';
export const APPLICATION_REJECTED = 'card_application_rejected';
export const APPLICATION_ABANDONED = 'card_application_abandoned';
export const APPROVED_NOT_ACCEPTED = 'card_approved_not_accepted';
export const ACCEPTED_NO_DEDUCTION = 'card_accepted_no_deduction';
export const ACCEPTED_WITH_DEDUCTION = 'card_accepted_with_deduction';
export const ACTIVATE_CARD = 'card_activate_card';
export const SUSPENDED = 'card_suspended';
export const GOOD = 'card_good';
export const CLOSED = 'card_closed';

export const CARD_NOTIFICATIONS_MAP = {
    [NO_APPLICATION]: {
        pillContent: '',
        pillStyle: '',
        callToActionLabel: '',
        callToActionRoute: '',
        content: '',
        icon: '',
        severity: '',
        callToActionType: '',
    },
    [PRE_APPROVED]: {
        pillContent: '',
        pillStyle: '',
        callToActionLabel: '',
        callToActionRoute: '',
        content: '',
        icon: '',
        severity: '',
        callToActionType: '',
    },
    [APPLICATION_ABANDONED]: {
        pillContent: '',
        pillStyle: '',
        callToActionLabel: '',
        callToActionRoute: '',
        content: '',
        icon: '',
        severity: '',
        callToActionType: '',
    },
    [APPLICATION_INCOMPLETE]: {
        pillContent: 'Application incomplete',
        pillStyle: 'warning',
        callToActionLabel: 'Complete application',
        callToActionRoute: paths.cardOnboarding.path,
        content: 'We need more information to complete your application.',
        icon: AlertIcons.CREDIT_CARD_GREEN,
        severity: AlertSeverity.WARNING,
        callToActionType: INCOMPLETE_APPLICATION,
    },
    [APPLICATION_UPLOAD_DOCUMENTS]: {
        pillContent: 'Application incomplete',
        pillStyle: 'info',
        callToActionLabel: 'Upload documents',
        callToActionRoute: paths.cardOnboarding.path,
        content: 'We need more information to complete your application.',
        icon: AlertIcons.PAGE_FACING_UP,
        severity: AlertSeverity.INFO,
        callToActionType: INCOMPLETE_APPLICATION,
    },
    [APPLICATION_IN_REVIEW]: {
        pillContent: 'In review',
        pillStyle: 'info',
        callToActionLabel: '',
        callToActionRoute: '',
        content:
            "We're reviewing your application. This usually takes 1-2 business days.",
        icon: AlertIcons.MAGNIFYING_GLASS_TILTED_RIGHT,
        severity: AlertSeverity.INFO,
        callToActionType: '',
    },
    [APPLICATION_IN_ERROR]: {
        pillContent: 'Application Error',
        pillStyle: 'danger',
        callToActionLabel: '',
        callToActionRoute: '',
        content:
            'We are working to fix an error with your application. We expect to resolve it within 1-2 business days.',
        icon: AlertIcons.CREDIT_CARD_GREEN,
        severity: AlertSeverity.DANGER,
        callToActionType: '',
    },
    [APPROVED_NOT_ACCEPTED]: {
        pillContent: 'Application incomplete',
        pillStyle: 'info',
        callToActionLabel: 'Accept offer',
        callToActionRoute: paths.cardOnboarding.path,
        content: 'Your application is approved. Tap here to accept your offer.',
        icon: AlertIcons.CREDIT_CARD_GREEN,
        severity: AlertSeverity.INFO,
        callToActionType: INCOMPLETE_APPLICATION,
    },
    [ACCEPTED_NO_DEDUCTION]: {
        pillContent: 'Pending',
        pillStyle: 'info',
        callToActionLabel: 'Set up direct deposit',
        callToActionRoute: paths.cardOnboarding.path,
        content: 'Set up your direct deposit to receive your credit card.',
        icon: AlertIcons.CREDIT_CARD_GREEN,
        severity: AlertSeverity.INFO,
        callToActionType: INCOMPLETE_APPLICATION,
    },
    [ACCEPTED_WITH_DEDUCTION]: {
        pillContent: 'Pending',
        pillStyle: 'info',
        callToActionLabel: '',
        callToActionRoute: '',
        content:
            'Your direct deposit is set! We’ll notify you as soon as your card ships.',
        icon: AlertIcons.SMILING_FACE_WITH_SUNGLASSES,
        severity: AlertSeverity.INFO,
        callToActionType: '',
    },
    [ACTIVATE_CARD]: {
        pillContent: 'Pending activation',
        pillStyle: 'info',
        callToActionLabel: 'Activate my card',
        callToActionRoute: paths.cardActivate.path,
        content: (cardArrivalFromDate, cardArrivalToDate) =>
            `Your card is on its way and should arrive between ${cardArrivalFromDate} and ${cardArrivalToDate}. Activate your card as soon as it arrives!`,
        icon: AlertIcons.ENVELOPE,
        severity: AlertSeverity.INFO,
        callToActionType: ACTIVATE_CARD_CTA_TYPE,
    },
    [SUSPENDED]: {
        pillContent: 'Suspended',
        pillStyle: 'danger',
        callToActionLabel: '',
        callToActionRoute: '',
        content: '',
        icon: '',
        severity: AlertSeverity.DANGER,
        callToActionType: '',
    },
    [GOOD]: {
        pillContent: '',
        pillStyle: '',
        callToActionLabel: '',
        callToActionRoute: '',
        content: '',
        icon: '',
        severity: '',
        callToActionType: '',
    },
    [NOT_ENABLED]: {
        pillContent: '',
        pillStyle: '',
        callToActionLabel: 'See how to qualify',
        callToActionRoute: '',
        content: '',
        icon: '',
        severity: '',
        callToActionType: '',
    },
    [CLOSED]: {
        pillContent: 'Closed',
        pillStyle: 'closed',
        callToActionLabel: '',
        callToActionRoute: FAQ_CLOSED_CARD_ACCOUNT,
        content: (name) =>
            `Hey ${name}, your card account is closed. Tap here to learn more.`,
        icon: AlertIcons.MAGNIFYING_GLASS_TILTED_RIGHT,
        severity: AlertSeverity.CLOSED,
        callToActionType: CLOSED_CARD_HELP_CENTER,
    },
};

export const DEFAULT_NOTIFICATION_OBJECT = {
    pillContent: '',
    pillStyle: '',
    callToActionLabel: '',
    callToActionRoute: '',
    content: '',
    icon: '',
    severity: '',
    callToActionType: '',
};
