import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { SHOP_LAST_PAGE_COOKIE } from '@perpay-web/constants/cookies';
import { postAuthRedirect } from '@perpay-web/fintech/actions/router';
import {
    getIsSignupComplete,
    getIsSignupInitiallyComplete,
} from '@perpay-web/fintech/selectors/ui/signup';
import { authentication } from '@perpay-web/fintech/settings/singletons';
import { useMount } from '@perpay-web/hooks/useMount';
import { getCookie } from '@perpay-web/utils/cookieUtils';

export const useSignupRedirectCheck = () => {
    const [isLoadingSignupRedirectCheck, setIsLoadingSignupRedirectCheck] =
        useState(true);
    const isSignupInitiallyComplete = useSelector(getIsSignupInitiallyComplete);
    const isSignupComplete = useSelector(getIsSignupComplete);
    const dispatch = useDispatch();

    useMount(() => {
        const shopLastPageCookie = getCookie(SHOP_LAST_PAGE_COOKIE);
        // In some cases, the signupRequirements and initialRequirements can get out of sync
        // which if unchecked for here can cause an infinite redirect.
        const shouldRedirect =
            authentication.getIsAuthenticated() &&
            (isSignupInitiallyComplete || shopLastPageCookie) &&
            isSignupComplete;

        if (shouldRedirect) {
            dispatch(postAuthRedirect());
            return;
        }

        setIsLoadingSignupRedirectCheck(false);
    });

    return { isLoadingSignupRedirectCheck };
};
