import { ofType } from 'redux-observable';
import { BehaviorSubject } from 'rxjs';
import { mergeMap, withLatestFrom, map } from 'rxjs/operators';

import { validateAddressSuccessForCardMigration } from '@perpay-web/fintech/actions/ui/addressValidation';
import {
    submitCardMigration,
    submitCardMigrationError,
} from '@perpay-web/fintech/actions/ui/cardMigration';
import {
    cardMigrationAddressConfirmationModalSetIsModalOpen,
    cardMigrationSetMaterial,
    cardMigrationModalSetStep,
    SUCCESS_STEP,
} from '@perpay-web/fintech/hooks/useCardMigrationContext';
import { PLASTIC_MATERIAL } from '@perpay-web/fintech/utils/cardAccountApplicationMetalUtils';
import { recommendedAddressSameAsOriginal } from '@perpay-web/utils/addressUtils';

const getMaterialStream = (action$) => {
    const subject$ = new BehaviorSubject(PLASTIC_MATERIAL);
    const stream$ = action$.pipe(
        ofType(cardMigrationSetMaterial().type),
        map((action) => action.payload),
    );
    stream$.subscribe(subject$);
    return subject$.asObservable();
};

export const validateAddressForCardMigration = (action$) =>
    action$.pipe(
        ofType(validateAddressSuccessForCardMigration().type),
        withLatestFrom(getMaterialStream(action$)),
        mergeMap(([results, material]) => {
            const addressValidation = results.payload;

            const {
                addressChangeRecommended,
                originalAddress,
                uspsAddressNotFound,
                validatedAddress,
            } = addressValidation;

            if (
                (addressChangeRecommended &&
                    !recommendedAddressSameAsOriginal(
                        validatedAddress,
                        originalAddress,
                    )) ||
                uspsAddressNotFound
            ) {
                return [
                    cardMigrationAddressConfirmationModalSetIsModalOpen(true),
                ];
            }

            return [
                submitCardMigration({
                    material,
                    validatedAddress,
                }),
            ];
        }),
    );

export const handleCardMigrationSuccess = (action$) =>
    action$.pipe(
        ofType(submitCardMigrationError().type),
        mergeMap(() => cardMigrationModalSetStep(SUCCESS_STEP)),
    );
