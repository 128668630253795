import { ofType } from 'redux-observable';
import { switchMap, mergeMap } from 'rxjs/operators';

import {
    fetchCardDashboardSummary as fetchCardDashboardSummaryAction,
    cardDashboardSummarySuccess,
    cardDashboardSummaryError,
} from '@perpay-web/fintech/actions/entities/cardDashboardSummary';
import { CARD_DASHBOARD_SUMMARY_ENDPOINT } from '@perpay-web/fintech/constants/urls';
import { handleError } from '@perpay-web/observable/operators/handleError';
import { standardizeError } from '@perpay-web/utils/errorHandlers';

export const fetchCardDashboardSummary = (action$, state$, { get }) =>
    action$.pipe(
        ofType(fetchCardDashboardSummaryAction().type),
        switchMap(() => get(CARD_DASHBOARD_SUMMARY_ENDPOINT)),
        mergeMap((results) => [
            cardDashboardSummarySuccess(results.response.card),
        ]),
        handleError((error) => {
            const standardizedError = standardizeError(error);
            return [cardDashboardSummaryError(standardizedError)];
        }),
    );
