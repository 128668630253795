import { ofType } from 'redux-observable';
import { mergeMap, exhaustMap } from 'rxjs/operators';

import {
    fetchInitiatedAutoPayPayments as fetchInitiatedAutoPayPaymentsAction,
    fetchInitiatedAutoPayPaymentsSuccess,
    fetchInitiatedAutoPayPaymentsError,
} from '@perpay-web/fintech/actions/entities/autoPayPayments';
import { AUTO_PAY_PAYMENTS_ENDPOINT } from '@perpay-web/fintech/constants/urls';
import { handleErrorMessageWithFallback } from '@perpay-web/observable/operators/handleErrorMessageWithFallback';
import { emitOrTimeout } from '@perpay-web/utils/observable';

export const fetchInitiatedAutoPayPayments = (action$, state$, { get }) =>
    action$.pipe(
        ofType(fetchInitiatedAutoPayPaymentsAction().type),
        exhaustMap(() =>
            emitOrTimeout(
                get(`${AUTO_PAY_PAYMENTS_ENDPOINT}?status=initiated`),
            ),
        ),
        mergeMap((results) => [
            fetchInitiatedAutoPayPaymentsSuccess({ all: results.response }),
        ]),
        handleErrorMessageWithFallback((error) => [
            fetchInitiatedAutoPayPaymentsError(error),
        ]),
    );
