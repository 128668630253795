import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { acknowledgeNotification } from '@perpay-web/fintech/actions/entities/notifications';
import Notifications from '@perpay-web/fintech/components/base/Notifications/Notifications';
import { REACT_NATIVE_UPGRADE_NOTIFICATION_IS_LAUNCHED } from '@perpay-web/fintech/constants/flags';
import { useNativeUpgradeNotificationModalContext } from '@perpay-web/fintech/hooks/useNativeUpgradeNotificationModalContext';
import { getUnacknowledgedNotifications } from '@perpay-web/fintech/selectors/entities/notifications';

import NativeUpgradeNotificationContainer from './NativeUpgradeNotificationContainer';

// We do not want to render the Notifications component unless there are notifications to render.
// This allows us to use its onMount to acknowledge the notification.
// This is done here so that the App component doesn't need any knowledge
// about this implementation details.
const NotificationsWrapper = () => {
    const dispatch = useDispatch();

    const {
        isAnimatedModalOpen: isNativeUpgradeNotificationModalOpen,
        setIsAnimatedModalOpen: setIsNativeUpgradeNotificationModalOpen,
        animatedModalClassName,
    } = useNativeUpgradeNotificationModalContext();

    const notifications = useSelector(getUnacknowledgedNotifications);

    const onAcknowledge = useCallback(
        (uuid) => dispatch(acknowledgeNotification(uuid)),
        [dispatch],
    );

    // If the user is on a Cordova-based client, attempt to render the app upgrade prompt
    if (
        REACT_NATIVE_UPGRADE_NOTIFICATION_IS_LAUNCHED &&
        isNativeUpgradeNotificationModalOpen
    ) {
        return (
            <NativeUpgradeNotificationContainer
                className={animatedModalClassName}
                onCloseModal={() =>
                    setIsNativeUpgradeNotificationModalOpen(false)
                }
            />
        );
    }

    if (!notifications.length) {
        return null;
    }

    return (
        <Notifications
            notifications={notifications}
            onAcknowledge={onAcknowledge}
        />
    );
};

export default NotificationsWrapper;
