import { ofType } from 'redux-observable';
import { mergeMap, exhaustMap } from 'rxjs/operators';

import {
    createDeposit as createDepositAction,
    createDepositErrors,
    createDepositSuccess,
} from '@perpay-web/fintech/actions/entities/deposits';
import { DEPOSITS_ENDPOINT } from '@perpay-web/fintech/constants/urls';
import { handleErrorMessageWithFallback } from '@perpay-web/observable/operators/handleErrorMessageWithFallback';

export function createDeposit(action$, state$, { post }) {
    return action$.pipe(
        ofType(createDepositAction().type),
        exhaustMap((action) => post(DEPOSITS_ENDPOINT, action.payload)),
        mergeMap((results) => [createDepositSuccess(results.response)]),
        handleErrorMessageWithFallback((error) => [createDepositErrors(error)]),
    );
}
