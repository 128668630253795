import {
    CARD,
    PERPAY_PLUS,
    LEGACY_PERPAY_PLUS,
} from '@perpay-web/fintech/constants/featureTypes';
import { FEATURES } from '@perpay-web/fintech/constants/tableNames';

export const getIsPerpayPlusEligibleAndNotOptedIn = (state) =>
    Object.keys(state.entities[FEATURES]).filter(
        (uuid) =>
            (state.entities[FEATURES][uuid].type === PERPAY_PLUS ||
                state.entities[FEATURES][uuid].type === LEGACY_PERPAY_PLUS) &&
            state.entities[FEATURES][uuid].enrollmentStatus === null,
    ).length > 0;

export const getCardFeatureDetails = (state) => {
    const cardFeatureUuid = Object.keys(state.entities[FEATURES]).filter(
        (uuid) => state.entities[FEATURES][uuid].type === CARD,
    )[0];
    const cardFeature = state.entities[FEATURES][cardFeatureUuid];

    if (!cardFeature) {
        return [];
    }

    return cardFeature.featureDetails;
};
