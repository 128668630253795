import { getClassName } from '@perpay-web/utils/domUtils';

import styles from '@perpay-web/design/styles/inputs.scss';

export const InputLabel = ({ label }) =>
    label ? (
        <span className={getClassName(styles.label, styles['label--floating'])}>
            {label}
        </span>
    ) : null;
