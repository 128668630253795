import { combineReducers } from '@reduxjs/toolkit';

import {
    BANKS,
    EXTERNAL_CARDS,
    JOBS,
    ORDERS,
    USERINFO,
    USERSTATUS,
    PAYSTUBS,
    INSTRUCTIONS,
    ORDER_CANCELLATION_REASONS,
    ADS,
    ACCOUNT_DEACTIVATION_REASONS,
    ACCOUNT_DEACTIVATION_ELIGIBILITY,
    SURVEYS,
    DEDUCTIONS,
    SURVEY_QUESTIONS,
    SURVEY_OPTIONS,
    FEATURES,
    FEATURE_ENROLLMENTS,
    CREDIT_SCORES_SUMMARY,
    NOTIFICATIONS,
} from '@perpay-web/fintech/constants/tableNames';

import accountDeactivationEligibilityReducer from './accountDeactivationEligibility';
import accountDeactivationReasonReducer from './accountDeactivationReasons';
import adReducer from './ads';
import bankReducer from './banks';
import creditScoresSummaryReducer from './creditScoresSummary';
import deductionReducer from './deductions';
import externalCardReducer from './externalCards';
import featureEnrollmentsReducer from './featureEnrollments';
import featuresReducer from './features';
import instructionsReducer from './instructions';
import jobReducer from './jobs';
import notificationsReducer from './notifications';
import orderCancellationReasonReducer from './orderCancellationReasons';
import orderReducer from './orders';
import paystubReducer from './paystubs';
import SurveyOptionsReducer from './surveyOptions';
import surveyQuestionsReducer from './surveyQuestions';
import surveyReducer from './surveys';
import userInfoReducer from './userInfo';
import userStatusReducer from './userStatus';

// A reducer for model-like data that is keyed on some id
const entitiesReducer = combineReducers({
    [BANKS]: bankReducer,
    [EXTERNAL_CARDS]: externalCardReducer,
    [JOBS]: jobReducer,
    [ORDERS]: orderReducer,
    [USERINFO]: userInfoReducer,
    [USERSTATUS]: userStatusReducer,
    [PAYSTUBS]: paystubReducer,
    [INSTRUCTIONS]: instructionsReducer,
    [ORDER_CANCELLATION_REASONS]: orderCancellationReasonReducer,
    [ADS]: adReducer,
    [ACCOUNT_DEACTIVATION_REASONS]: accountDeactivationReasonReducer,
    [ACCOUNT_DEACTIVATION_ELIGIBILITY]: accountDeactivationEligibilityReducer,
    [SURVEYS]: surveyReducer,
    [DEDUCTIONS]: deductionReducer,
    [SURVEY_QUESTIONS]: surveyQuestionsReducer,
    [SURVEY_OPTIONS]: SurveyOptionsReducer,
    [FEATURES]: featuresReducer,
    [FEATURE_ENROLLMENTS]: featureEnrollmentsReducer,
    [CREDIT_SCORES_SUMMARY]: creditScoresSummaryReducer,
    [NOTIFICATIONS]: notificationsReducer,
});

export default entitiesReducer;
