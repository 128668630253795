import { normalize } from 'normalizr';
import { ofType } from 'redux-observable';
import { exhaustMap, mergeMap, switchMap } from 'rxjs/operators';

import {
    enrollFeatureError,
    enrollFeatureSuccess,
    replaceEnrolledFeatures,
    fetchEnrolledFeaturesError,
    fetchFeatureEnrollments as fetchFeatureEnrollmentsAction,
    fetchFeatureEnrollmentsSuccess,
    fetchFeatureEnrollmentsError,
    createFeatureEnrollment as createFeatureEnrollmentAction,
    createFeatureEnrollmentSuccess,
    createFeatureEnrollmentError,
} from '@perpay-web/fintech/actions/entities/featureEnrollments';
import {
    BACKEND_FEATURES_ENROLL,
    BACKEND_FETCH_FEATURE_ENROLLMENTS,
    BACKEND_FEATURES_REENABLE,
    BACKEND_FEATURES_DISABLE,
} from '@perpay-web/fintech/constants/actionTypes';
import {
    DISABLED,
    ENABLED,
} from '@perpay-web/fintech/constants/featureEnrollmentStatuses';
import { FEATURE_ENROLLMENTS } from '@perpay-web/fintech/constants/tableNames';
import { FEATURE_ENROLLMENTS_ENDPOINT } from '@perpay-web/fintech/constants/urls';
import { featureEnrollment } from '@perpay-web/fintech/normalizers/schemas';
import { handleErrorMessageWithFallback } from '@perpay-web/observable/operators/handleErrorMessageWithFallback';

export function enrollFeature(action$, state$, { post }) {
    return action$.pipe(
        ofType(BACKEND_FEATURES_ENROLL),
        exhaustMap((action) =>
            post(FEATURE_ENROLLMENTS_ENDPOINT, {
                feature: action.payload.feature,
            }),
        ),

        mergeMap((results) => {
            const normalized = normalize(results.response, featureEnrollment);

            return [
                enrollFeatureSuccess(normalized.entities[FEATURE_ENROLLMENTS]),
            ];
        }),
        handleErrorMessageWithFallback((error) => [enrollFeatureError(error)]),
    );
}

export const createFeatureEnrollment = (action$, state$, { post }) =>
    action$.pipe(
        ofType(createFeatureEnrollmentAction().type),
        exhaustMap((action) =>
            post(FEATURE_ENROLLMENTS_ENDPOINT, {
                feature: action.payload.feature,
            }),
        ),
        mergeMap((results) => [
            createFeatureEnrollmentSuccess(results.response),
        ]),
        handleErrorMessageWithFallback((error) => [
            createFeatureEnrollmentError(error),
        ]),
    );

export function disableFeature(action$, state$, { patch }) {
    return action$.pipe(
        ofType(BACKEND_FEATURES_DISABLE),
        exhaustMap((action) =>
            patch(FEATURE_ENROLLMENTS_ENDPOINT, {
                feature: action.payload.feature,
                status: DISABLED,
            }),
        ),
        mergeMap((results) => {
            const normalized = normalize(results.response, featureEnrollment);

            return [
                enrollFeatureSuccess(normalized.entities[FEATURE_ENROLLMENTS]),
            ];
        }),
        handleErrorMessageWithFallback((error) => [enrollFeatureError(error)]),
    );
}

export function reEnableFeature(action$, state$, { patch }) {
    return action$.pipe(
        ofType(BACKEND_FEATURES_REENABLE),
        exhaustMap((action) =>
            patch(FEATURE_ENROLLMENTS_ENDPOINT, {
                feature: action.payload.feature,
                status: ENABLED,
            }),
        ),
        mergeMap((results) => {
            const normalized = normalize(results.response, featureEnrollment);

            return [
                enrollFeatureSuccess(normalized.entities[FEATURE_ENROLLMENTS]),
            ];
        }),
        handleErrorMessageWithFallback((error) => [enrollFeatureError(error)]),
    );
}

export const fetchFeatureEnrollments = (action$, state$, { get }) =>
    action$.pipe(
        ofType(fetchFeatureEnrollmentsAction().type),
        switchMap(() => get(FEATURE_ENROLLMENTS_ENDPOINT)),
        mergeMap((results) => [
            fetchFeatureEnrollmentsSuccess({
                featureEnrollments: results.response,
            }),
        ]),
        handleErrorMessageWithFallback((error) => [
            fetchFeatureEnrollmentsError(error),
        ]),
    );

export function fetchEnrolledFeatures(action$, state$, { get }) {
    return action$.pipe(
        ofType(BACKEND_FETCH_FEATURE_ENROLLMENTS),
        switchMap(() => get(FEATURE_ENROLLMENTS_ENDPOINT)),
        mergeMap((results) => {
            const normalized = normalize(results.response, [featureEnrollment]);
            const featureEnrollments = normalized.entities[FEATURE_ENROLLMENTS];
            return [replaceEnrolledFeatures(featureEnrollments)];
        }),
        handleErrorMessageWithFallback((error) => [
            fetchEnrolledFeaturesError(error),
        ]),
    );
}
