import { ofType } from 'redux-observable';
import { exhaustMap } from 'rxjs/operators';

import { refreshAccessToken } from '@perpay-web/fintech/actions/authentication';
import {
    gateCardApply as gateCardApplyAction,
    gateCardApplyError,
    gateCardApplySuccess,
} from '@perpay-web/fintech/actions/entities/gateCardApply';
import { routeToLocation } from '@perpay-web/fintech/actions/router';
import {
    STORE_REPLACE_AUTH,
    BACKEND_FETCH_AUTH_WITH_CREDENTIALS_ERROR,
} from '@perpay-web/fintech/constants/actionTypes';
import { paths } from '@perpay-web/fintech/props/appPaths';
import { getIsSignupComplete } from '@perpay-web/fintech/selectors/ui/signup';
import { dispatchObservable } from '@perpay-web/observable/dispatchObservable';

export const gateCardApply = (action$, state$) =>
    action$.pipe(
        ofType(gateCardApplyAction().type),
        exhaustMap(() =>
            dispatchObservable({
                action$,
                state$,
                initialDispatch: () => [refreshAccessToken()],
                waitFor: [STORE_REPLACE_AUTH],
                waitForDispatch: (state) => {
                    const maybeRedirectAction = !getIsSignupComplete(state)
                        ? [
                              routeToLocation({
                                  path: `${paths.signUp.path}?next=${paths.cardOnboarding.path}`,
                                  replace: true,
                              }),
                          ]
                        : [];

                    return [...maybeRedirectAction, gateCardApplySuccess()];
                },
                errors: [BACKEND_FETCH_AUTH_WITH_CREDENTIALS_ERROR],
                errorDispatch: () => [
                    routeToLocation({
                        path: paths.dashboard.path,
                        replace: true,
                    }),
                    gateCardApplyError(),
                ],
            }),
        ),
    );
