import { createAction } from '@reduxjs/toolkit';

import { useModal } from '@perpay-web/components/base/Modal/useModal';
import { useActionObservableCallback } from '@perpay-web/hooks/useActionObservableCallback';
import { hookAction$ } from '@perpay-web/services/singletons';
import { createContextProvider } from '@perpay-web/utils/componentUtils';

export const partnerOnboardingSignupIneligibleStateModalSetIsModalOpen =
    createAction(
        'PARTNER_ONBOARDING_SIGNUP_INELIGIBLE_STATE_MODAL::SET_IS_MODAL_OPEN',
    );

const usePartnerOnboardingSignupIneligibleStateModal = () => {
    const { isModalOpen, setIsModalOpen } = useModal();

    return {
        isModalOpen,
        setIsModalOpen,
    };
};

const providerFactory = (Context) => {
    const Provider = ({ children }) => {
        const hook = usePartnerOnboardingSignupIneligibleStateModal();

        useActionObservableCallback(
            hookAction$,
            partnerOnboardingSignupIneligibleStateModalSetIsModalOpen().type,
            (value) => hook.setIsModalOpen(value),
        );

        return <Context.Provider value={hook}>{children}</Context.Provider>;
    };

    return Provider;
};

export const [
    PartnerOnboardingSignupIneligibleStateModalProvider,
    usePartnerOnboardingSignupIneligibleStateModalContext,
] = createContextProvider(providerFactory);
