import { ofType } from 'redux-observable';
import { switchMap } from 'rxjs/operators';

import { HTTPMethod } from '@perpay-web/constants/httpMethods';
import {
    fetchAddressSuggestionsForDataModule as fetchAddressSuggestionsForDataModuleAction,
    replaceAddressSuggestions,
    addressSuggestionsError,
    resetAddressSuggestions,
} from '@perpay-web/fintech/actions/ui/addressSuggestions';
import { GOOGLE_PLACES_KEY } from '@perpay-web/fintech/constants/serviceCredentials';
import { GOOGLE_AUTOCOMPLETE_URL } from '@perpay-web/fintech/constants/urls';
import { handleError } from '@perpay-web/observable/operators/handleError';

export const fetchAddressSuggestionsForDataModule = (
    action$,
    state$,
    { jsonFetch },
) =>
    action$.pipe(
        ofType(fetchAddressSuggestionsForDataModuleAction().type),
        switchMap((action) => {
            const typeahead = action.payload;
            if (!typeahead) {
                return [resetAddressSuggestions()];
            }

            const config = { headers: { 'X-Goog-Api-Key': GOOGLE_PLACES_KEY } };
            const body = {
                input: typeahead,
                includedRegionCodes: ['us'],
                languageCode: 'en',
                includedPrimaryTypes: ['street_address', 'premise'],
            };

            return jsonFetch(
                GOOGLE_AUTOCOMPLETE_URL,
                body,
                HTTPMethod.post,
                config,
            );
        }),
        switchMap((data) => {
            const suggestions = data.suggestions || [];
            const filteredSuggestions = suggestions.slice(0, 4).map(
                ({
                    placePrediction: {
                        placeId,
                        structuredFormat: {
                            mainText: { text: line1 },
                            secondaryText: { text: line2 },
                        },
                    },
                }) => ({
                    placeId,
                    line1,
                    line2,
                }),
            );
            return [
                replaceAddressSuggestions({
                    addressSuggestions: filteredSuggestions,
                }),
            ];
        }),
        handleError((e) => [addressSuggestionsError(e)]),
    );
