import ImageWithFallback from '@perpay-web/components/base/ImageWithFallback/ImageWithFallback';
import { useScrollHorizontal } from '@perpay-web/storefront/hooks/useScrollHorizontal';
import { getProductImagePath } from '@perpay-web/storefront/utils/algoliaUtils';

import styles from './AutocompleteRecentlyViewedList.scss';

export const AutocompleteRecentlyViewedList = ({
    items,
    getItemProps,
    source,
    listProps,
    isMobile,
}) => {
    const itemsNumberToScroll = 3;

    const { containerRef, showLeftArrow, showRightArrow, onArrowClick } =
        useScrollHorizontal();

    return (
        <div className={styles.products}>
            {!isMobile && showLeftArrow ? (
                <button
                    type='button'
                    className={styles['arrow-left']}
                    onClick={() =>
                        onArrowClick({
                            shouldScrollRight: false,
                            itemsNumberToScroll,
                        })
                    }
                    disabled={false}
                    aria-label='Scroll left'
                />
            ) : null}
            <ul
                ref={containerRef}
                className={styles.products__cards}
                id={listProps.id}
                aria-labelledby={listProps['aria-labelledby']}
                role={listProps.role}
            >
                {items.map((item) => {
                    const { onClick, onMouseDown, onMouseMove } = getItemProps({
                        source,
                        item,
                    });
                    return (
                        <li
                            className={styles.products__card}
                            key={item.objectID}
                        >
                            <button
                                type='button'
                                onClick={onClick}
                                onMouseDown={onMouseDown}
                                onMouseMove={onMouseMove}
                            >
                                <ImageWithFallback
                                    alt={item.name}
                                    className={styles.products__card__image}
                                    src={
                                        item.image
                                            ? getProductImagePath(item.image)
                                            : ''
                                    }
                                />
                            </button>
                        </li>
                    );
                })}
            </ul>
            {!isMobile && showRightArrow ? (
                <button
                    type='button'
                    className={styles['arrow-right']}
                    onClick={() =>
                        onArrowClick({
                            shouldScrollRight: true,
                            itemsNumberToScroll,
                        })
                    }
                    disabled={false}
                    aria-label='Scroll right'
                />
            ) : null}
        </div>
    );
};
