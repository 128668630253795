import { AutocompleteHighlight } from '@perpay-web/storefront/components/base/AutocompleteHighlight/AutocompleteHighlight';
import { AutocompleteListItem } from '@perpay-web/storefront/components/composite/AutocompleteListItem/AutocompleteListItem';
import { getClassName } from '@perpay-web/utils/domUtils';

import styles from './AutocompleteRecentSearchItem.scss';

export const AutocompleteRecentSearchItem = ({
    className,
    isSearching,
    item,
    onClick,
    onMouseDown,
    onClear,
}) => (
    <AutocompleteListItem
        className={getClassName(
            styles['recent-search-item'],
            !isSearching && styles['recent-search-item--section'],
            className,
        )}
        onClick={onClick}
        onMouseDown={onMouseDown}
        onClear={onClear}
    >
        <AutocompleteHighlight item={item} attribute='label' />
    </AutocompleteListItem>
);
