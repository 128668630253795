import {
    Intercom,
    boot,
    show,
    update,
    shutdown,
    hide,
} from '@intercom/messenger-js-sdk';
import { ofType } from 'redux-observable';
import { EMPTY } from 'rxjs';
import { switchMap, withLatestFrom } from 'rxjs/operators';

import { storeLogout } from '@perpay-web/authentication/actions';
import { authentication } from '@perpay-web/authentication/singleton';
import { STORE_REPLACE_AUTH } from '@perpay-web/fintech/constants/actionTypes';
import { INTERCOM_APP_ID } from '@perpay-web/fintech/constants/serviceCredentials';
import { getJWTData } from '@perpay-web/fintech/selectors/authentication';
import {
    updateSupportTool as updateSupportToolAction,
    launchSupportTool as launchSupportToolAction,
    loadSupportTool as loadSupportToolAction,
    shutDownSupportTool as shutDownSupportToolAction,
} from '@perpay-web/services/supportTool';
import { isSemiLargeScreen } from '@perpay-web/utils/responsiveUtils';

export const loadSupportTool = (action$, state$) =>
    action$.pipe(
        ofType(STORE_REPLACE_AUTH),
        withLatestFrom(state$),
        switchMap(([, state]) => {
            const { intercomHash, fullName, email } = getJWTData(state);
            Intercom({
                app_id: INTERCOM_APP_ID,
                alignment: 'left',
                hide_default_launcher: !isSemiLargeScreen(window.innerWidth),
            });
            boot({
                user_hash: intercomHash,
                name: fullName,
                email,
            });
            hide(); // show() called by default after initialization
            return [loadSupportToolAction()];
        }),
    );

export const launchSupportTool = (action$, state$) =>
    action$.pipe(
        ofType(launchSupportToolAction.type),
        withLatestFrom(state$),
        switchMap(([, state]) => {
            const { intercomHash } = getJWTData(state);
            show({
                app_id: INTERCOM_APP_ID,
                user_hash: intercomHash,
                hide_default_launcher: true,
            });

            return EMPTY;
        }),
    );

export const updateSupportTool = (action$, state$) =>
    action$.pipe(
        ofType(updateSupportToolAction.type),
        withLatestFrom(state$),
        switchMap(([action]) => {
            if (authentication.getIsAuthenticated()) {
                Intercom({
                    app_id: INTERCOM_APP_ID,
                    alignment: 'left',
                });
                update({ app_id: INTERCOM_APP_ID, ...action.payload });
            }
            return EMPTY;
        }),
    );

export const shutDownSupportTool = (action$, state$) =>
    action$.pipe(
        ofType(storeLogout().type),
        withLatestFrom(state$),
        switchMap(() => {
            shutdown();

            return [shutDownSupportToolAction()];
        }),
    );
