import { createAction } from '@reduxjs/toolkit';

import { firstItemOf } from '@perpay-web/utils/arrayUtils';

export const analyticsMarkAuthenticatedAbTestVariant = createAction(
    'ANALYTICS::AB_TEST_AUTHENTICATED::MARK_VARIANT',
);

export const analyticsMarkUnauthenticatedAbTestVariant = createAction(
    'ANALYTICS::AB_TEST_UNAUTHENTICATED::MARK_VARIANT',
);

export const getTestNameFromResponse = (response) =>
    firstItemOf(
        Object.keys(response).filter((key) => key !== 'userExistedInGroup'),
    );
