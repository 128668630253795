import {
    COMPLETE_REGISTRATION,
    PURCHASE,
    INITIATE_PURCHASE,
    VERIFY_PHONE,
} from '@perpay-web/fintech/beacon/eventDefinitions/Branch';
import { getCordovaApp } from '@perpay-web/fintech/utils/cordovaUtils';
import { noop } from '@perpay-web/utils/noop';

const BRANCH_STANDARD_EVENT_PURCHASE = 'STANDARD_EVENT_PURCHASE';
const BRANCH_STANDARD_EVENT_INITIATE_PURCHASE =
    'STANDARD_EVENT_INITIATE_PURCHASE';
const BRANCH_STANDARD_EVENT_COMPLETE_REGISTRATION =
    'STANDARD_EVENT_COMPLETE_REGISTRATION';
const BRANCH_CUSTOM_EVENT_VERIFY_PHONE = 'VERIFY_PHONE';

/** @deprecated should be removed once enough users are no longer on the cordova app */
const sendBranchEvent = (branchEventName, payload) =>
    getCordovaApp().then((CordovaApp) => {
        if (CordovaApp.requirePlugin) {
            CordovaApp.requirePlugin('branch').then(({ sendEvent }) => {
                sendEvent(branchEventName, payload);
            });
            return;
        }

        // NOTE: This check is temporary and will not be needed after
        // the new cordova providers are deployed.
        if (!CordovaApp.getBranch) {
            return;
        }

        const Branch = CordovaApp.getBranch();

        Branch.getStandardEvents().then((standardEvents) => {
            const EVENT =
                branchEventName in standardEvents
                    ? standardEvents[branchEventName]
                    : branchEventName;
            Branch.sendBranchEvent(EVENT, payload);
        });
    });

/** @deprecated should be removed once enough users are no longer on the cordova app */
const sendCustomBranchEvent = (branchEventName, payload) =>
    getCordovaApp().then((CordovaApp) => {
        if (CordovaApp.requirePlugin) {
            CordovaApp.requirePlugin('branch').then((branchPlugin) => {
                if (branchPlugin.sendCustomEvent) {
                    branchPlugin.sendCustomEvent(branchEventName, payload);
                }
            });
            return;
        }

        // NOTE: This check is temporary and will not be needed after
        // the new cordova providers are deployed.
        if (!CordovaApp.getBranch) {
            return;
        }

        const Branch = CordovaApp.getBranch();

        Branch.getStandardEvents().then((standardEvents) => {
            const EVENT =
                branchEventName in standardEvents
                    ? standardEvents[branchEventName]
                    : branchEventName;
            Branch.sendBranchEvent(EVENT, payload);
        });
    });

/** @deprecated should be removed once enough users are no longer on the cordova app */
const BranchTargetFactory = () => {
    if (typeof window === 'undefined') {
        return noop;
    }

    return async (events) => {
        events.forEach((event) => {
            const { type, payload } = event;
            switch (type) {
                case COMPLETE_REGISTRATION:
                    sendBranchEvent(
                        BRANCH_STANDARD_EVENT_COMPLETE_REGISTRATION,
                        payload,
                    );
                    break;
                case PURCHASE:
                    sendBranchEvent(BRANCH_STANDARD_EVENT_PURCHASE, payload);
                    break;
                case INITIATE_PURCHASE:
                    sendBranchEvent(
                        BRANCH_STANDARD_EVENT_INITIATE_PURCHASE,
                        payload,
                    );
                    break;
                case VERIFY_PHONE:
                    // TODO: change to sendBranchEvent once we move away from Cordova
                    sendCustomBranchEvent(
                        BRANCH_CUSTOM_EVENT_VERIFY_PHONE,
                        payload,
                    );
                    break;
                default:
            }
        });
    };
};

export default BranchTargetFactory;
