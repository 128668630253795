import {
    NEW,
    APPROVED,
    REJECTED,
    CLARITY_FROZEN,
    AWAITING_DEDUCTION_SETUP,
    COMPLETE,
    UPLOAD_DOCUMENTS,
    IN_REVIEW,
} from '@perpay-web/fintech/constants/cardAccountApplicationStatuses';
import * as metalSteps from '@perpay-web/fintech/constants/steps/cardOnboardingMetalSteps';

export const getMetalStepFromStatus = (status) => {
    switch (status) {
        case NEW:
            return metalSteps.SUBMITTING_STEP;
        case APPROVED:
            return metalSteps.APPROVED_STEP;
        case CLARITY_FROZEN:
            return metalSteps.CLARITY_UNFREEZE_STEP;
        case REJECTED:
            return metalSteps.DENIED_STEP;
        case AWAITING_DEDUCTION_SETUP:
            return metalSteps.REVIEW_DIRECT_DEPOSIT_STEP;
        case COMPLETE:
            return metalSteps.SUCCESS_STEP;
        case UPLOAD_DOCUMENTS:
            return metalSteps.UPLOAD_DOCUMENTS_STEP;
        case IN_REVIEW:
            return metalSteps.IN_REVIEW_STEP;
        default:
            return metalSteps.APPLICATION_ERROR_STEP;
    }
};

export const METAL_MATERIAL = 'PERPAY_METAL';
export const PLASTIC_MATERIAL = 'PERPAY_PLASTIC';
