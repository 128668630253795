import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { appUpdatePollStart } from '@perpay-web/fintech/actions/appUpdate';
import { initializeIdentity } from '@perpay-web/fintech/actions/authentication';
import App from '@perpay-web/fintech/components/screens/App/App';

const AppContainer = () => {
    const dispatch = useDispatch();

    const onMount = useCallback(() => {
        if (window.WEBPACK_DEV_SERVER) {
            return;
        }
        dispatch(appUpdatePollStart());
        dispatch(initializeIdentity());
    }, [dispatch]);

    return <App onMount={onMount} />;
};

export default AppContainer;
