import { createCrudDataModule } from '@perpay-web/data-module/createCrudDataModule';
import { createBankAccountForCardAutoPayDataModule } from '@perpay-web/fintech/dataModules/createBankAccountForCardAutoPay';
import { fetchBankAccountsForCardAutoPayDataModule } from '@perpay-web/fintech/dataModules/fetchBankAccountsForCardAutoPay';

const BankAccountsForCardAutoPayDataModule = createCrudDataModule({
    read: fetchBankAccountsForCardAutoPayDataModule,
    create: createBankAccountForCardAutoPayDataModule,
});

const getRoot = (state) => state.dataModules.bankAccountsForCardAutoPay;

export const bankAccountsForCardAutoPayDataModule =
    BankAccountsForCardAutoPayDataModule({ getRoot });

export const getBankAccounts = (state) => {
    const bankAccountEntities =
        bankAccountsForCardAutoPayDataModule.getData(state);
    const bankAccounts = Object.values(bankAccountEntities);
    return bankAccounts;
};
