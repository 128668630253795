import { Outlet } from 'react-router-dom';

import BorrowerHeaderContainer from '@perpay-web/fintech/components/containers/BorrowerHeaderContainer';
import NotificationsContainer from '@perpay-web/fintech/components/containers/NotificationsContainer';
import { REACT_NATIVE_UPGRADE_NOTIFICATION_IS_LAUNCHED } from '@perpay-web/fintech/constants/flags';
import { nativeUpgradeNotificationSetIsModalOpen } from '@perpay-web/fintech/hooks/useNativeUpgradeNotificationModalContext';
import { paths } from '@perpay-web/fintech/props/appPaths';
import store from '@perpay-web/fintech/settings/configureStore';
import { shouldShowNativeUpgradeNotification } from '@perpay-web/fintech/utils/nativeUpgradeNotificationUtils';
import { useAppTitle } from '@perpay-web/hooks/useAppTitle';
import { useFullBottomNavRender } from '@perpay-web/hooks/useFullBottomNavContext';
import { useMount } from '@perpay-web/hooks/useMount';
import { useSupportTool } from '@perpay-web/hooks/useSupportTool';
import { useTrackLocationChange } from '@perpay-web/hooks/useTrackLocationChange';
import { getClassName } from '@perpay-web/utils/domUtils';
import { pathIsInReactStorefront } from '@perpay-web/utils/routeUtils';

import styles from './App.scss';

const App = ({ onMount }) => {
    useMount(onMount);
    useAppTitle();
    const renderedBottomNav = useFullBottomNavRender();

    useTrackLocationChange(({ location }) => {
        if (
            pathIsInReactStorefront(location.pathname) ||
            [paths.login.path, paths.logout.path].includes(location.pathname)
        )
            return null;

        if (
            REACT_NATIVE_UPGRADE_NOTIFICATION_IS_LAUNCHED &&
            shouldShowNativeUpgradeNotification()
        ) {
            return store.dispatch(
                nativeUpgradeNotificationSetIsModalOpen(true),
            );
        }
        return null;
    });
    useSupportTool();

    return (
        <div
            className={getClassName(
                styles.app,
                renderedBottomNav ? styles['has-footer'] : '',
            )}
        >
            <NotificationsContainer />
            <BorrowerHeaderContainer />
            <div className={styles.app__container}>
                <Outlet />
            </div>
            {renderedBottomNav}
        </div>
    );
};

export default App;
