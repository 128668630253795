import { ofType } from 'redux-observable';
import { debounceTime, switchMap, mergeMap } from 'rxjs/operators';

import {
    replaceCompanies,
    replaceCompaniesSelfOnboarding,
    errorCompanies,
    errorSelfOnboardingCompanies,
} from '@perpay-web/fintech/actions/ui/companies';
import {
    FETCH_COMPANIES,
    FETCH_COMPANIES_SELF_ONBOARDING,
} from '@perpay-web/fintech/constants/actionTypes';
import {
    COMPANIES_ENDPOINT,
    COMPANIES_SELF_ONBOARDING_ENDPOINT,
} from '@perpay-web/fintech/constants/urls';
import { pipeResponse } from '@perpay-web/fintech/utils/operators';
import { handleErrorMessageWithFallback } from '@perpay-web/observable/operators/handleErrorMessageWithFallback';

const COMPANY_FETCH_DEBOUNCE_MS = 300;
const SELF_ONBOARDING_COMPANY_FETCH_DEBOUNCE_MS = 1000;

export function fetchCompanies(action$, state$, { get }) {
    return action$.pipe(
        ofType(FETCH_COMPANIES),
        debounceTime(COMPANY_FETCH_DEBOUNCE_MS),
        switchMap((action) => {
            const { typeahead } = action.payload;
            if (!typeahead) {
                return [replaceCompanies({})];
            }

            return get(`${COMPANIES_ENDPOINT}?q=${action.payload.typeahead}`);
        }),
        mergeMap((results) => [replaceCompanies(results.response)]),
        handleErrorMessageWithFallback((error) => [errorCompanies(error)]),
    );
}

export function fetchCompaniesSelfOnboarding(action$, state$, { get }) {
    return action$.pipe(
        ofType(FETCH_COMPANIES_SELF_ONBOARDING),
        debounceTime(SELF_ONBOARDING_COMPANY_FETCH_DEBOUNCE_MS),
        switchMap((action) => {
            const { typeahead } = action.payload;

            if (typeahead) {
                return get(
                    `${COMPANIES_SELF_ONBOARDING_ENDPOINT}?q=${action.payload.typeahead}`,
                );
            }
            return [replaceCompaniesSelfOnboarding({})];
        }),
        pipeResponse(
            mergeMap((results) => [
                replaceCompaniesSelfOnboarding(results.response),
            ]),
        ),
        handleErrorMessageWithFallback((error) => [
            errorSelfOnboardingCompanies({ message: error.query }),
        ]),
    );
}
