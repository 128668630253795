import { HTTPMethod } from '@perpay-web/constants/httpMethods';
import { getData, useDataModule } from '@perpay-web/data-module/useDataModule';
import { useReduxDataModuleHookConnection } from '@perpay-web/data-module/useReduxDataModuleHookConnection';
import {
    fetchCardApplicationStatus,
    fetchCardApplicationStatusError,
    fetchCardApplicationStatusReset,
    fetchCardApplicationStatusSuccess,
} from '@perpay-web/fintech/actions/entities/cardAccountApplications';
import { CARD_ACCOUNT_APPLICATION_ENDPOINT } from '@perpay-web/fintech/constants/urls';
import { PLASTIC_MATERIAL } from '@perpay-web/fintech/utils/cardAccountApplicationMetalUtils';
import { authenticatedJsonFetch } from '@perpay-web/fintech/utils/requestHandlers';
import { hookAction$ } from '@perpay-web/services/singletons';
import { createContextProvider } from '@perpay-web/utils/componentUtils';

export const getEstimatedCardMinimumPayment = (state) => {
    const application = getData(state);
    return application ? application.estimatedCardMinimumPayment : 0;
};
export const getCardApplicationUuid = (state) => {
    const application = getData(state);
    return application ? application.uuid : '';
};
export const getCardApplicationCreditLimit = (state) => {
    const application = getData(state);
    return application ? application.creditLimit : 0;
};
export const getCardApplicationApr = (state) => {
    const application = getData(state);
    return application ? application.apr : 0;
};
export const getCardApplicationMaterial = (state) => {
    const application = getData(state);
    return application ? application.material : PLASTIC_MATERIAL;
};

const useFetchCardApplicationStatusDataModule = () => {
    const dataModule = useDataModule((cardAccountApplicationUUID) =>
        authenticatedJsonFetch(
            `${CARD_ACCOUNT_APPLICATION_ENDPOINT}${cardAccountApplicationUUID}/`,
            { fetch_deserve_status: true },
            HTTPMethod.patch,
        ),
    );

    useReduxDataModuleHookConnection({
        dataModule,
        hookAction$,
        request: fetchCardApplicationStatus,
        error: fetchCardApplicationStatusError,
        success: fetchCardApplicationStatusSuccess,
        reset: fetchCardApplicationStatusReset,
    });

    return dataModule;
};

const providerFactory = (Context) => {
    const Provider = ({ children }) => {
        const dataModule = useFetchCardApplicationStatusDataModule();

        return (
            <Context.Provider value={dataModule}>{children}</Context.Provider>
        );
    };

    return Provider;
};

export const [
    UseFetchCardApplicationStatusDataModuleProvider,
    useFetchCardApplicationStatusContext,
] = createContextProvider(providerFactory);
