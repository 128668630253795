import { apiClientVersion } from '@algolia/client-search';

import { getIsProductionPreview } from '@perpay-web/storefront/utils/productionPreviewUtils';

export const APPLICATION_ID = 'A60TG5S0YX';
export const SEARCH_API_KEY = '063731ec3566d2b10faf1ba1692d12d4';

// NOTE: dev index not used by default locally as it doesn't have much data
const DEV_INDEX_NAME = 'dev_products';
const STAGING_INDEX_NAME = 'staging_products';
const PRODUCTION_INDEX_NAME = 'production_products';

export const SEARCH_INDEX_NAME = (() => {
    if (window.STORYBOOK) {
        return DEV_INDEX_NAME;
    }
    if (window.PRODUCTION) {
        return PRODUCTION_INDEX_NAME;
    }
    if (window.STAGING && getIsProductionPreview()) {
        return PRODUCTION_INDEX_NAME;
    }
    return STAGING_INDEX_NAME;
})();

export const QUERY_SUGGESTIONS_INDEX_NAME = `${SEARCH_INDEX_NAME}_query_suggestions`;

export const ALGOLIA_TOKEN_KEY = 'perpay.algolia.token';
const ALGOLIA_CACHE_PREFIX = 'algoliasearch-client-js-algolia';
const ALGOLIA_CACHE_SUFFIX = `${apiClientVersion}-${APPLICATION_ID}`;
export const ALGOLIA_CACHE_KEY = `${ALGOLIA_CACHE_PREFIX}-${ALGOLIA_CACHE_SUFFIX}`;
export const ALGOLIA_CACHE_SEARCH_KEY = `${ALGOLIA_CACHE_PREFIX}-algolia-${ALGOLIA_CACHE_SUFFIX}`;
export const ALGOLIA_CACHE_REQUESTS_KEY = `${ALGOLIA_CACHE_PREFIX}-requests-${ALGOLIA_CACHE_SUFFIX}`;
export const ALGOLIA_CACHE_RESPONSES_KEY = `${ALGOLIA_CACHE_PREFIX}-responses-${ALGOLIA_CACHE_SUFFIX}`;
export const ALGOLIA_QUERY_ID_KEY = 'perpay.algolia.search.queryID';

export const SEARCH_HITS_PER_PAGE = 52;
