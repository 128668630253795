import { createDataModule } from '@perpay-web/data-module/createDataModule';
import {
    fetchTrustPilotLink,
    fetchTrustPilotLinkError,
    fetchTrustPilotLinkReset,
    fetchTrustPilotLinkSuccess,
} from '@perpay-web/fintech/actions/entities/trustPilotLink';

const FetchTrustPilotLinkDataModule = createDataModule({
    dataRequest: fetchTrustPilotLink,
    dataSuccess: fetchTrustPilotLinkSuccess,
    dataError: fetchTrustPilotLinkError,
    dataReset: fetchTrustPilotLinkReset,
});

const getRoot = (state) => state.dataModules.fetchTrustPilotLink;
const initialValue = {};

export const fetchTrustPilotLinkDataModule = FetchTrustPilotLinkDataModule({
    getRoot,
    initialValue,
});

export const getLink = (state) =>
    fetchTrustPilotLinkDataModule.getData(state).url || '';
