import { ofType } from 'redux-observable';
import { concat } from 'rxjs';
import { switchMap, mergeMap } from 'rxjs/operators';

import {
    fetchCreditLimits as fetchCreditLimitsAction,
    fetchCreditLimitsSuccess,
    fetchCreditLimitsError,
    updateCreditLimit as updateCreditLimitAction,
    updateCreditLimitSuccess,
    updateCreditLimitError,
} from '@perpay-web/fintech/actions/entities/creditLimits';
import {
    createPrimaryJob,
    createPrimaryJobSuccess,
    updateJob,
    updateJobSuccess,
} from '@perpay-web/fintech/actions/entities/jobs';
import { routeToLocation } from '@perpay-web/fintech/actions/router';
import {
    fetchGeneralAccountSummary,
    replaceGeneralAccountSummary,
} from '@perpay-web/fintech/actions/shared/generalAccountSummary';
import {
    fetchPaymentSetupSummary as fetchPaymentSetupSummaryAction,
    fetchPaymentSetupSummarySuccess,
    fetchPaymentSetupSummaryError,
} from '@perpay-web/fintech/actions/shared/paymentSetupSummary';
import {
    acceptCreditLimit as acceptCreditLimitAction,
    acceptCreditLimitSuccess,
    acceptCreditLimitError,
} from '@perpay-web/fintech/actions/ui/creditLimits';
import {
    replaceAmountToCharge,
    replaceAmountType,
    setStepsForUpdateDirectDeposit as setStepsForUpdateDirectDepositAction,
    setStepsForUpdateDirectDepositSuccess,
    setStepsForUpdateDirectDepositError,
    updateDirectDepositCreatePrimaryJob,
} from '@perpay-web/fintech/actions/ui/updateDirectDeposit';
import {
    NEW,
    ACCEPTED,
} from '@perpay-web/fintech/constants/creditLimitStatuses';
import {
    CARD_AWAITING_PAYMENT,
    CORE_AWAITING_PAYMENT,
    NONE_REQUIRED,
} from '@perpay-web/fintech/constants/directDepositStatuses';
import { MINIMUM_REQUIRED } from '@perpay-web/fintech/constants/selectAmountTypes';
import {
    UPDATE_DIRECT_DEPOSIT_STEPS,
    UPDATE_EMPLOYER_AND_AMOUNT_STEP,
    ACCEPT_CREDIT_LIMIT_STEP,
} from '@perpay-web/fintech/constants/steps/updateDirectDepositSteps';
import {
    CREDIT_LIMIT_INCREASE_CONTEXT,
    UPDATE_DIRECT_DEPOSIT_CONTEXT,
} from '@perpay-web/fintech/constants/updateDirectDepositContexts';
import { getNewCreditLimit } from '@perpay-web/fintech/dataModules/fetchCreditLimits';
import { paths } from '@perpay-web/fintech/props/appPaths';
import { getStepFromStatus } from '@perpay-web/fintech/utils/directDepositFlowUtils';
import {
    reduxStepsSetStep,
    reduxStepsSetInitialStep,
} from '@perpay-web/hooks/useReduxSteps';
import { dispatchObservable } from '@perpay-web/observable/dispatchObservable';

export function replaceAmountToChargeForUpdateDirectDeposit(action$, state$) {
    return action$.pipe(
        ofType(fetchGeneralAccountSummary().type),
        switchMap(() =>
            dispatchObservable({
                action$,
                state$,
                waitFor: [replaceGeneralAccountSummary().type],
                waitForDispatch: (state, results) => {
                    const { payload } = results[0];
                    const { total } = payload.minimumPayment;
                    const amountType = MINIMUM_REQUIRED;
                    return [
                        replaceAmountToCharge(total),
                        replaceAmountType(amountType),
                    ];
                },
            }),
        ),
    );
}

export function createPrimaryJobSetStepToUpdate(action$, state$) {
    return action$.pipe(
        ofType(updateDirectDepositCreatePrimaryJob().type),
        switchMap((action) => {
            const { payload } = action;
            return dispatchObservable({
                action$,
                state$,
                initialDispatch: [createPrimaryJob(payload)],
                waitFor: [createPrimaryJobSuccess().type],
                waitForDispatch: [
                    reduxStepsSetStep(
                        UPDATE_DIRECT_DEPOSIT_STEPS,
                        UPDATE_EMPLOYER_AND_AMOUNT_STEP,
                    ),
                ],
            });
        }),
    );
}

export function updateJobSetStepToUpdate(action$, state$) {
    return action$.pipe(
        ofType(updateJob().type),
        switchMap(() =>
            dispatchObservable({
                action$,
                state$,
                waitFor: [updateJobSuccess().type],
                waitForDispatch: [
                    reduxStepsSetStep(
                        UPDATE_DIRECT_DEPOSIT_STEPS,
                        UPDATE_EMPLOYER_AND_AMOUNT_STEP,
                    ),
                ],
            }),
        ),
    );
}

export function setStepsForUpdateDirectDeposit(action$, state$) {
    return action$.pipe(
        ofType(setStepsForUpdateDirectDepositAction().type),
        switchMap((action) => {
            const { context } = action.payload;
            const isCreditLimitIncreaseContext =
                context === CREDIT_LIMIT_INCREASE_CONTEXT;
            return dispatchObservable({
                action$,
                state$,
                initialDispatch: () => [
                    fetchPaymentSetupSummaryAction(),
                    isCreditLimitIncreaseContext
                        ? fetchCreditLimitsAction({ status: NEW })
                        : fetchCreditLimitsAction({ status: ACCEPTED }),
                ],
                waitFor: [
                    fetchPaymentSetupSummarySuccess().type,
                    fetchCreditLimitsSuccess().type,
                ],
                waitForDispatch: (state, results) => {
                    const paymentSetupSummaryResult = results[0];
                    const { directDepositStatus } =
                        paymentSetupSummaryResult.payload;
                    const newCreditLimit = getNewCreditLimit(state);

                    const successActions = [
                        setStepsForUpdateDirectDepositSuccess(),
                    ];

                    if (isCreditLimitIncreaseContext && newCreditLimit) {
                        successActions.push(
                            reduxStepsSetInitialStep(
                                UPDATE_DIRECT_DEPOSIT_STEPS,
                                ACCEPT_CREDIT_LIMIT_STEP,
                            ),
                        );
                    } else if (
                        isCreditLimitIncreaseContext &&
                        !newCreditLimit
                    ) {
                        successActions.push(
                            setStepsForUpdateDirectDepositAction({
                                context: UPDATE_DIRECT_DEPOSIT_CONTEXT,
                            }),
                        );
                        successActions.push(
                            routeToLocation({
                                path: paths.updateDirectDeposit.path,
                                replace: true,
                            }),
                        );
                    } else if (directDepositStatus === NONE_REQUIRED) {
                        successActions.push(
                            routeToLocation({
                                path: paths.dashboard.path,
                                replace: true,
                            }),
                        );
                    } else if (directDepositStatus === CORE_AWAITING_PAYMENT) {
                        successActions.push(
                            routeToLocation({
                                path: paths.paymentSetup.path,
                                replace: true,
                            }),
                        );
                    } else if (directDepositStatus === CARD_AWAITING_PAYMENT) {
                        successActions.push(
                            routeToLocation({
                                path: paths.cardOnboarding.path,
                                replace: true,
                            }),
                        );
                    } else {
                        successActions.push(
                            reduxStepsSetInitialStep(
                                UPDATE_DIRECT_DEPOSIT_STEPS,
                                getStepFromStatus(directDepositStatus),
                            ),
                        );
                    }
                    return successActions;
                },
                errors: [
                    fetchPaymentSetupSummaryError().type,
                    fetchCreditLimitsError().type,
                ],
                errorDispatch: (errorAction) => [
                    setStepsForUpdateDirectDepositError(errorAction.payload),
                ],
            });
        }),
    );
}

export function acceptCreditLimit(action$, state$) {
    return action$.pipe(
        ofType(acceptCreditLimitAction().type),
        mergeMap((action) =>
            concat(
                dispatchObservable({
                    action$,
                    state$,
                    initialDispatch: () => [
                        updateCreditLimitAction({
                            uuid: action.payload.uuid,
                            status: ACCEPTED,
                        }),
                    ],
                    waitFor: [updateCreditLimitSuccess().type],
                    waitForDispatch: () => [
                        fetchGeneralAccountSummary(),
                        fetchCreditLimitsAction({ status: ACCEPTED }),
                    ],
                    errors: [updateCreditLimitError().type],
                    errorDispatch: (errorAction) => [
                        acceptCreditLimitError(errorAction.payload),
                    ],
                }),
                dispatchObservable({
                    action$,
                    state$,
                    waitFor: [
                        replaceGeneralAccountSummary().type,
                        fetchCreditLimitsSuccess().type,
                    ],
                    waitForDispatch: () => [
                        acceptCreditLimitSuccess(),
                        // set the next step as the initial step to prevent
                        // user from going back to already accepted step
                        reduxStepsSetInitialStep(
                            UPDATE_DIRECT_DEPOSIT_STEPS,
                            UPDATE_EMPLOYER_AND_AMOUNT_STEP,
                        ),
                    ],
                }),
            ),
        ),
    );
}
