import { useEffect } from 'react';

import {
    SPENDING_LIMIT_LOYALTY_UI_API_NAME,
    SPENDING_LIMIT_LOYALTY_UI_STORE_NAME,
} from '@perpay-web/constants/src/experiments';
import { createDataModuleAdapter } from '@perpay-web/data-module/src/composeDataModules';
import { createDataModuleActions } from '@perpay-web/data-module/src/createDataModuleActions';
import { getIsLoadingOrUnrequested } from '@perpay-web/data-module/src/useDataModule';
import { useReduxDataModuleHookConnection } from '@perpay-web/data-module/src/useReduxDataModuleHookConnection';
import {
    getIsUserInTestGroup,
    useABTestDataModule,
} from '@perpay-web/hooks/abTests/useABTestDataModule';
import { hookAction$ } from '@perpay-web/services/singletons';

const dataModuleFetchActions = createDataModuleActions(
    'FETCH_SPENDING_LIMIT_LOYALTY_UI_AB_TEST',
);
const fetchSpendingLimitLoyaltyABTest = dataModuleFetchActions.dataRequest;
const fetchSpendingLimitLoyaltyABTestSuccess =
    dataModuleFetchActions.dataSuccess;
const fetchSpendingLimitLoyaltyABTestError = dataModuleFetchActions.dataError;
const fetchSpendingLimitLoyaltyABTestReset = dataModuleFetchActions.dataReset;

export const useSpendingLimitLoyaltyABTest = () => {
    const dataModule = useABTestDataModule();
    const { state, dataRequest } = dataModule;

    const isLoading = getIsLoadingOrUnrequested(state);
    const isUserInTestGroup = getIsUserInTestGroup(
        state,
        SPENDING_LIMIT_LOYALTY_UI_STORE_NAME,
    );

    useReduxDataModuleHookConnection({
        dataModule,
        hookAction$,
        request: fetchSpendingLimitLoyaltyABTest,
        success: fetchSpendingLimitLoyaltyABTestSuccess,
        error: fetchSpendingLimitLoyaltyABTestError,
        reset: fetchSpendingLimitLoyaltyABTestReset,
    });

    useEffect(() => {
        if (window.STORYBOOK) return;
        dataRequest(SPENDING_LIMIT_LOYALTY_UI_API_NAME);
    }, [dataRequest]);

    return { isLoading, isUserInTestGroup };
};

export const useSpendingLimitLoyaltyABTestAdapter = createDataModuleAdapter(
    fetchSpendingLimitLoyaltyABTest,
    fetchSpendingLimitLoyaltyABTestSuccess,
    fetchSpendingLimitLoyaltyABTestError,
);
