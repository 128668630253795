import {
    AUTHENTICATION_TYPE_FACIAL_RECOGNITION,
    AUTHENTICATION_TYPE_FINGERPRINT,
    BIOMETRIC_TYPE_ANDROID_BIOMETRICS_FACE,
    BIOMETRIC_TYPE_ANDROID_BIOMETRICS_FINGERPRINT,
    BIOMETRIC_TYPE_ANDROID_BIOMETRICS_OTHER,
    BIOMETRIC_TYPE_FACE_ID,
    BIOMETRIC_TYPE_NONE,
    BIOMETRIC_TYPE_TOUCH_ID,
    ENROLLED_AUTHENTICATION_LEVEL_NONE,
} from '@perpay-web/native-client/constants/passkey';
import { isIosAgent, isAndroidAgent } from '@perpay-web/utils/userAgentUtils';

const getPasskeyState = (state) => state.passkey || {};

export const getIsPasskeySupported = (state) =>
    getPasskeyState(state).isPasskeySupported;

const getEnrolledAuthenticationLevel = (state) =>
    getPasskeyState(state).enrolledAuthenicationLevel;

export const getIsEnrolledWithBiometrics = (state) =>
    getEnrolledAuthenticationLevel(state) !==
    ENROLLED_AUTHENTICATION_LEVEL_NONE;

const getPasskeyIds = (state) => getPasskeyState(state).passkeyIds || [];

export const getHasPasskey = (state) => getPasskeyIds(state).length > 0;

const getSupportedAuthenticationTypes = (state) =>
    getPasskeyState(state).supportedAuthenticationTypes || [];

const getIsFaceIdSupported = (state) =>
    isIosAgent() &&
    getSupportedAuthenticationTypes(state).includes(
        AUTHENTICATION_TYPE_FACIAL_RECOGNITION,
    );

const getIsTouchIdSupported = (state) =>
    isIosAgent() &&
    getSupportedAuthenticationTypes(state).includes(
        AUTHENTICATION_TYPE_FINGERPRINT,
    );

const getIsAndroidBiometricsFaceSupported = (state) =>
    isIosAgent() &&
    getSupportedAuthenticationTypes(state).includes(
        AUTHENTICATION_TYPE_FACIAL_RECOGNITION,
    );

const getIsAndroidBiometricsFingerprintSupported = (state) =>
    isAndroidAgent() &&
    getSupportedAuthenticationTypes(state).includes(
        AUTHENTICATION_TYPE_FINGERPRINT,
    );

const getIsAndroidBiometricsSupported = (state) =>
    isAndroidAgent() && getSupportedAuthenticationTypes(state).length > 0;

export const getBiometricsType = (state) => {
    if (getIsFaceIdSupported(state)) {
        return BIOMETRIC_TYPE_FACE_ID;
    }

    if (getIsTouchIdSupported(state)) {
        return BIOMETRIC_TYPE_TOUCH_ID;
    }

    if (getIsAndroidBiometricsFaceSupported(state)) {
        return BIOMETRIC_TYPE_ANDROID_BIOMETRICS_FACE;
    }

    if (getIsAndroidBiometricsFingerprintSupported(state)) {
        return BIOMETRIC_TYPE_ANDROID_BIOMETRICS_FINGERPRINT;
    }

    if (getIsAndroidBiometricsSupported(state)) {
        return BIOMETRIC_TYPE_ANDROID_BIOMETRICS_OTHER;
    }

    return BIOMETRIC_TYPE_NONE; // shouldn't happen
};

const TYPE_TO_LABEL = {
    [BIOMETRIC_TYPE_FACE_ID]: 'Face ID',
    [BIOMETRIC_TYPE_TOUCH_ID]: 'Touch ID',
    [BIOMETRIC_TYPE_ANDROID_BIOMETRICS_FACE]: 'Biometrics',
    [BIOMETRIC_TYPE_ANDROID_BIOMETRICS_FINGERPRINT]: 'Biometrics',
    [BIOMETRIC_TYPE_ANDROID_BIOMETRICS_OTHER]: 'Biometrics',
    [BIOMETRIC_TYPE_NONE]: 'Passkey',
};

export const getBiometricsLabelFromType = (type) => TYPE_TO_LABEL[type];
