import { ofType } from 'redux-observable';
import { switchMap, mergeMap } from 'rxjs/operators';

import {
    fetchCreditLimits as fetchCreditLimitsAction,
    fetchCreditLimitsSuccess,
    fetchCreditLimitsError,
    updateCreditLimit as updateCreditLimitAction,
    updateCreditLimitSuccess,
    updateCreditLimitError,
} from '@perpay-web/fintech/actions/entities/creditLimits';
import { CREDIT_LIMITS_ENDPOINT } from '@perpay-web/fintech/constants/urls';
import { handleErrorMessageWithFallback } from '@perpay-web/observable/operators/handleErrorMessageWithFallback';
import { emitOrTimeout } from '@perpay-web/utils/observable';

export const fetchCreditLimits = (action$, state$, { get }) =>
    action$.pipe(
        ofType(fetchCreditLimitsAction().type),
        switchMap(({ payload }) => {
            const status = (payload && payload.status) || null;
            const endpoint = status
                ? `${CREDIT_LIMITS_ENDPOINT}?status=${status}`
                : CREDIT_LIMITS_ENDPOINT;
            return emitOrTimeout(get(endpoint));
        }),
        mergeMap((results) => {
            const creditLimits = results.response;
            return [fetchCreditLimitsSuccess({ creditLimits })];
        }),
        handleErrorMessageWithFallback((error) => [
            fetchCreditLimitsError(error),
        ]),
    );

export const updateCreditLimit = (action$, state$, { patch }) =>
    action$.pipe(
        ofType(updateCreditLimitAction().type),
        switchMap((action) => {
            const { payload } = action;
            const { uuid, ...requestBody } = payload;

            return emitOrTimeout(
                patch(`${CREDIT_LIMITS_ENDPOINT}${uuid}/`, requestBody),
            );
        }),
        mergeMap((results) => [updateCreditLimitSuccess(results.response)]),
        handleErrorMessageWithFallback((error) => [
            updateCreditLimitError(error),
        ]),
    );
