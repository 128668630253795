import { useCallback, useEffect, useRef, useState } from 'react';

import { noop } from '@perpay-web/utils/noop';

const getShiftedWidth = ({ element, defaultShift, shiftAfterFirstClick }) => {
    const isScrolled = element.scrollLeft !== 0;
    if (!isScrolled) {
        return shiftAfterFirstClick;
    }
    return defaultShift;
};

export const useScrollHorizontal = (options = {}) => {
    const { enabled = true } = options;

    const [showLeftArrow, setShowLeftArrow] = useState(false);
    const [showRightArrow, setShowRightArrow] = useState(false);
    const containerRef = useRef(null);

    useEffect(() => {
        if (!enabled) return noop;

        const checkScrollPosition = () => {
            if (!containerRef.current) {
                return;
            }

            const { scrollLeft, scrollWidth, clientWidth } =
                containerRef.current;

            setShowLeftArrow(scrollLeft > 0);
            setShowRightArrow(scrollLeft < scrollWidth - clientWidth);
        };

        const containerEl = containerRef.current;
        const resizeObserver = new ResizeObserver(checkScrollPosition);

        if (containerEl) {
            resizeObserver.observe(containerEl);
            containerEl.addEventListener('scroll', checkScrollPosition);
        }

        return () => {
            if (containerEl) {
                containerEl.removeEventListener('scroll', checkScrollPosition);
                resizeObserver.disconnect();
            }
        };
    }, [enabled]);

    const onArrowClick = useCallback(
        ({
            shouldScrollRight,
            itemsNumberToScroll,
            defaultShift,
            shiftAfterFirstClick,
        }) => {
            const element = containerRef.current;
            if (!element) {
                return;
            }
            const itemWidth = element.children[0].offsetWidth;
            const isShiftedWidth = Boolean(
                defaultShift && shiftAfterFirstClick,
            );
            const widthToScroll = isShiftedWidth
                ? getShiftedWidth({
                      element,
                      defaultShift,
                      shiftAfterFirstClick,
                  })
                : itemWidth * itemsNumberToScroll;

            element.scrollBy({
                left: shouldScrollRight ? widthToScroll : -widthToScroll,
                behavior: 'smooth',
            });
        },
        [],
    );

    return {
        containerRef,
        showLeftArrow,
        showRightArrow,
        onArrowClick,
    };
};
