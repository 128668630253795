import { composeDataModules } from '@perpay-web/data-module/composeDataModules';
import {
    fetchCardDashboardSummaryDataModule,
    getNotification as getCardNotification,
    getCardArrivalWindow,
} from '@perpay-web/fintech/dataModules/fetchCardDashboardSummary';
import {
    fetchPlatformDashboardNotificationsDataModule,
    getNotification as getPlatformNotification,
} from '@perpay-web/fintech/dataModules/fetchPlatformDashboardNotifications';

const CardDetailsNotificationsDataModule = composeDataModules([
    fetchCardDashboardSummaryDataModule,
    fetchPlatformDashboardNotificationsDataModule,
]);

const getRoot = (state) => state.dataModules.cardDetailsNotificationsDataModule;

const cardDetailsNotificationsDataModule = CardDetailsNotificationsDataModule({
    getRoot,
    key: 'CARD_DETAILS_NOTIFICATIONS_DATA_MODULE',
});

export {
    cardDetailsNotificationsDataModule,
    getCardNotification,
    getPlatformNotification,
    getCardArrivalWindow,
};
