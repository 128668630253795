import { useEffect } from 'react';

import {
    METAL_CARD_API_NAME,
    METAL_CARD_STORE_NAME,
} from '@perpay-web/constants/experiments';
import { createDataModuleAdapter } from '@perpay-web/data-module/src/composeDataModules';
import { createDataModuleActions } from '@perpay-web/data-module/src/createDataModuleActions';
import { getIsLoadingOrUnrequested } from '@perpay-web/data-module/useDataModule';
import { useReduxDataModuleHookConnection } from '@perpay-web/data-module/useReduxDataModuleHookConnection';
import {
    getIsUserInTestGroup,
    useABTestDataModule,
} from '@perpay-web/hooks/abTests/useABTestDataModule';
import { hookAction$ } from '@perpay-web/services/singletons';

const dataModuleFetchActions = createDataModuleActions(
    'FETCH_METAL_CARD_AB_TEST',
);
const fetchMetalCardABTest = dataModuleFetchActions.dataRequest;
const fetchMetalCardABTestSuccess = dataModuleFetchActions.dataSuccess;
const fetchMetalCardABTestError = dataModuleFetchActions.dataError;
const fetchMetalCardABTestReset = dataModuleFetchActions.dataReset;

export const useMetalCardABTest = () => {
    const dataModule = useABTestDataModule();
    const { state, dataRequest } = dataModule;

    const isLoading = getIsLoadingOrUnrequested(state);
    const isUserInTestGroup = getIsUserInTestGroup(
        state,
        METAL_CARD_STORE_NAME,
    );

    useReduxDataModuleHookConnection({
        dataModule,
        hookAction$,
        request: fetchMetalCardABTest,
        success: fetchMetalCardABTestSuccess,
        error: fetchMetalCardABTestError,
        reset: fetchMetalCardABTestReset,
    });

    useEffect(() => {
        if (window.STORYBOOK) return;
        dataRequest(METAL_CARD_API_NAME);
    }, [dataRequest]);

    return { isLoading, isUserInTestGroup };
};

export const useMetalCardABTestAdapter = createDataModuleAdapter(
    fetchMetalCardABTest,
    fetchMetalCardABTestSuccess,
    fetchMetalCardABTestError,
);
