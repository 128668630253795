import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { POST_AUTH_PATH } from '@perpay-web/authentication/constants/paths';
import { authentication } from '@perpay-web/authentication/singleton';
import WarningLoaderCircle from '@perpay-web/components/base/WarningLoaderCircle/WarningLoaderCircle';
import { useMount } from '@perpay-web/hooks/useMount';

const useRedirectIfAuthenticated = () => {
    const navigate = useNavigate();
    const [isLoadingRedirect, setIsLoadingRedirect] = useState(true);

    useMount(() => {
        if (authentication.getIsAuthenticated()) {
            navigate(POST_AUTH_PATH, { replace: true });
            return;
        }
        setIsLoadingRedirect(false);
    });

    return {
        isLoadingRedirect,
    };
};

export function withRedirectIfAuthenticated(Component) {
    return (props) => {
        const { isLoadingRedirect } = useRedirectIfAuthenticated();

        if (isLoadingRedirect) {
            return <WarningLoaderCircle />;
        }

        return <Component {...props} />;
    };
}
