import { normalize } from 'normalizr';
import { ofType } from 'redux-observable';
import { exhaustMap, mergeMap } from 'rxjs/operators';

import { replaceAccountDeactivationReasons } from '@perpay-web/fintech/actions/entities/accountDeactivationReasons';
import { accountDeactivationReasonsError } from '@perpay-web/fintech/actions/ui/accountDeactivationModal';
import { BACKEND_FETCH_ACCOUNT_DEACTIVATION_REASONS } from '@perpay-web/fintech/constants/actionTypes';
import { ACCOUNT_DEACTIVATION_REASONS } from '@perpay-web/fintech/constants/tableNames';
import { ACCOUNT_DEACTIVATION_REASONS_ENDPOINT } from '@perpay-web/fintech/constants/urls';
import { accountDeactivationReason } from '@perpay-web/fintech/normalizers/schemas';
import { handleErrorMessageWithFallback } from '@perpay-web/observable/operators/handleErrorMessageWithFallback';

export function getAccountDeactivationReasons(action$, state$, { get }) {
    return action$.pipe(
        ofType(BACKEND_FETCH_ACCOUNT_DEACTIVATION_REASONS),
        exhaustMap(() => get(ACCOUNT_DEACTIVATION_REASONS_ENDPOINT)),
        mergeMap((results) => {
            const normalized = normalize(results.response, [
                accountDeactivationReason,
            ]);
            const reasons = normalized.entities[ACCOUNT_DEACTIVATION_REASONS];
            return [replaceAccountDeactivationReasons(reasons)];
        }),
        handleErrorMessageWithFallback((error) => [
            accountDeactivationReasonsError(error),
        ]),
    );
}
