import {
    BRANDS_ROOT,
    CATEGORY_ROOT,
    COLLECTIONS,
    NEW_ARRIVALS_ROOT,
} from '@perpay-web/storefront/constants/paths';

export const categoryMenuDefaultAdapter = (value) =>
    `${CATEGORY_ROOT}/${value}`;

export const categoryMenuBrandAdapter = (value) => `${BRANDS_ROOT}/${value}`;

export const categoryMenuCollectionAdapter = (value) =>
    `${COLLECTIONS.replace(':collectionSlug', encodeURIComponent(value))}`;

export const categoryMenuNewArrivalsAdapter = (value) =>
    `${NEW_ARRIVALS_ROOT}/${value || ''}`;
