import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import {
    analyticsNativeUpgradeNotificationViewed,
    analyticsNativeUpgradeNotificationClose,
    analyticsNativeUpgradeNotificationClickCta,
} from '@perpay-web/fintech/actions/analytics/nativeUpgradeNotification';
import NativeUpgradeNotification from '@perpay-web/fintech/components/composite/NativeUpgradeNotification/NativeUpgradeNotification';

const NativeUpgradeNotificationContainer = ({ className, onCloseModal }) => {
    const dispatch = useDispatch();

    const onMount = useCallback(
        () => dispatch(analyticsNativeUpgradeNotificationViewed()),
        [dispatch],
    );
    const onClickCta = useCallback(() => {
        dispatch(analyticsNativeUpgradeNotificationClickCta());
        onCloseModal();
    }, [dispatch, onCloseModal]);
    const onClose = useCallback(() => {
        dispatch(analyticsNativeUpgradeNotificationClose());
        onCloseModal();
    }, [dispatch, onCloseModal]);

    return (
        <NativeUpgradeNotification
            className={className}
            onMount={onMount}
            onClickCta={onClickCta}
            onClose={onClose}
        />
    );
};

export default NativeUpgradeNotificationContainer;
