import { ofType } from 'redux-observable';
import { switchMap, mergeMap, withLatestFrom } from 'rxjs/operators';

import { refreshAccessToken } from '@perpay-web/fintech/actions/authentication';
import {
    analyticsApplicationDecision,
    checkoutLoanDecisionError,
    checkoutReplaceLoanDecision,
} from '@perpay-web/fintech/actions/ui/orderReview';
import { BACKEND_CHECKOUT_LOAN_DECISION_GENERATE } from '@perpay-web/fintech/constants/actionTypes';
import { ORDERS_UNDERWRITING_ENDPOINT } from '@perpay-web/fintech/constants/urls';
import { getOrderFromCheckoutUuid } from '@perpay-web/fintech/selectors/entities/orders';
import { handleErrorMessageWithFallback } from '@perpay-web/observable/operators/handleErrorMessageWithFallback';

export function checkoutLoanDecision(action$, state$, { post }) {
    return action$.pipe(
        ofType(BACKEND_CHECKOUT_LOAN_DECISION_GENERATE),
        withLatestFrom(state$),
        switchMap(([action, state]) => {
            const orderUuid = getOrderFromCheckoutUuid(
                state,
                action.payload.uuid,
            );
            return post(
                ORDERS_UNDERWRITING_ENDPOINT.replace(':uuid', orderUuid.uuid),
                {},
            );
        }),
        mergeMap((results) => [
            checkoutReplaceLoanDecision(results.response),
            analyticsApplicationDecision(results.response),
            refreshAccessToken(),
        ]),
        handleErrorMessageWithFallback((error) => [
            checkoutLoanDecisionError(error),
        ]),
    );
}
