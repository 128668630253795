import { ofType } from 'redux-observable';
import { mergeMap, exhaustMap } from 'rxjs/operators';

import {
    fetchCreditScoreHistory as fetchCreditScoreHistoryAction,
    fetchCreditScoreHistorySuccess,
    fetchCreditScoreHistoryError,
} from '@perpay-web/fintech/actions/entities/creditScoreHistory';
import { CREDIT_SCORE_HISTORY_ENDPOINT } from '@perpay-web/fintech/constants/urls';
import { handleError } from '@perpay-web/observable/operators/handleError';

export function fetchCreditScoreHistory(action$, state$, { get }) {
    return action$.pipe(
        ofType(fetchCreditScoreHistoryAction().type),
        exhaustMap((action) => {
            const fromDate = action.payload ? action.payload.fromDate : null;
            const url = fromDate
                ? `${CREDIT_SCORE_HISTORY_ENDPOINT}?from_date=${fromDate}`
                : CREDIT_SCORE_HISTORY_ENDPOINT;
            return get(url);
        }),
        mergeMap((results) => [
            fetchCreditScoreHistorySuccess(results.response),
        ]),
        handleError((error) => [fetchCreditScoreHistoryError(error)]),
    );
}
