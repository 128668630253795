import {
    BACKEND_FETCH_AB_TEST,
    BACKEND_VERIFY_AB_TEST,
    STORE_AB_TEST_REPLACE,
    STORE_AB_TEST_ERROR,
    STORE_AB_TEST_RESET,
    STORE_RESET_ATTEMPTED_EMPLOYER_VERIFICATION,
    ANALYTICS_REPORT_AB_TEST,
} from '@perpay-web/fintech/constants/actionTypes';

export function reportABTest(payload) {
    return {
        type: ANALYTICS_REPORT_AB_TEST,
        payload,
    };
}

export function fetchABTest(payload) {
    return {
        type: BACKEND_FETCH_AB_TEST,
        payload,
    };
}

export function verifyABTest(payload) {
    return {
        type: BACKEND_VERIFY_AB_TEST,
        payload,
    };
}

export function replaceABTest(payload) {
    return {
        type: STORE_AB_TEST_REPLACE,
        payload,
    };
}

export function fetchABTestError(error) {
    return {
        type: STORE_AB_TEST_ERROR,
        payload: error,
    };
}

export function resetABTest() {
    return {
        type: STORE_AB_TEST_RESET,
    };
}

export function resetAttemptedEmployerVerification() {
    return {
        type: STORE_RESET_ATTEMPTED_EMPLOYER_VERIFICATION,
    };
}
