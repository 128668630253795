import { createDataModule } from '@perpay-web/data-module/createDataModule';
import {
    fetchCardAccountApplicationForOnboarding,
    fetchCardAccountApplicationForOnboardingSuccess,
    fetchCardAccountApplicationForOnboardingError,
    fetchCardAccountApplicationForOnboardingReset,
} from '@perpay-web/fintech/actions/entities/cardAccountApplications';
import { PLASTIC_MATERIAL } from '@perpay-web/fintech/utils/cardAccountApplicationMetalUtils';

const FetchCardAccountApplicationForOnboardingDataModule = createDataModule({
    dataRequest: fetchCardAccountApplicationForOnboarding,
    dataSuccess: fetchCardAccountApplicationForOnboardingSuccess,
    dataError: fetchCardAccountApplicationForOnboardingError,
    dataReset: fetchCardAccountApplicationForOnboardingReset,
});

const getRoot = (state) =>
    state.dataModules.fetchCardAccountApplicationForOnboarding;
const initialValue = {};

export const fetchCardAccountApplicationForOnboardingDataModule =
    FetchCardAccountApplicationForOnboardingDataModule({
        getRoot,
        initialValue,
    });

export const getCreditLimit = (state) =>
    fetchCardAccountApplicationForOnboardingDataModule.getData(state)
        .creditLimit || 0;

export const getMaterial = (state) =>
    fetchCardAccountApplicationForOnboardingDataModule.getData(state)
        .material || PLASTIC_MATERIAL;

export const getApplicationUuid = (state) =>
    fetchCardAccountApplicationForOnboardingDataModule.getData(state).uuid ||
    '';

export const getApplicationStatus = (state) =>
    fetchCardAccountApplicationForOnboardingDataModule.getData(state).status ||
    '';
