import { createAction } from '@reduxjs/toolkit';

import { createDataModuleActions } from '@perpay-web/data-module/createDataModuleActions';
import {
    BACKEND_FETCH_ORDERS,
    STORE_REPLACE_ORDERS,
    STORE_RESET_ORDERS,
    STORE_FETCH_ORDERS_ERROR,
    BACKEND_CANCEL_ORDER,
    STORE_REPLACE_ORDER,
    BACKEND_CHECKOUT_UPDATE_ORDER,
    BACKEND_CHECKOUT_REFRESH_ORDER,
} from '@perpay-web/fintech/constants/actionTypes';

const fetchDataModuleActions = createDataModuleActions('FETCH_ORDERS');
export const fetchOrdersForDataModule = fetchDataModuleActions.dataRequest;
export const fetchOrdersForDataModuleSuccess =
    fetchDataModuleActions.dataSuccess;
export const fetchOrdersForDataModuleError = fetchDataModuleActions.dataError;
export const fetchOrdersForDataModuleReset = fetchDataModuleActions.dataReset;

export function fetchOrders(filterType) {
    return {
        type: BACKEND_FETCH_ORDERS,
        payload: filterType,
    };
}

export function fetchOrdersError(error) {
    return {
        type: STORE_FETCH_ORDERS_ERROR,
        payload: error,
    };
}

export function replaceOrders(orders) {
    return {
        type: STORE_REPLACE_ORDERS,
        payload: orders,
    };
}

export function resetOrders() {
    return {
        type: STORE_RESET_ORDERS,
    };
}

// Claim a checkout to create an order
export const checkoutCreateOrder = createAction(
    'backend/ORDER::CHECKOUT_CREATE',
);

export function replaceOrder(payload) {
    return {
        type: STORE_REPLACE_ORDER,
        payload,
    };
}

export function checkoutUpdateOrder(requestBody) {
    return {
        type: BACKEND_CHECKOUT_UPDATE_ORDER,
        payload: requestBody,
    };
}

export function refreshOrder(payload) {
    return {
        type: BACKEND_CHECKOUT_REFRESH_ORDER,
        payload,
    };
}

export function cancelOrder(payload) {
    return {
        type: BACKEND_CANCEL_ORDER,
        payload,
    };
}

export const analyticsApplicationSelectPaymentOptions = createAction(
    'ANALYTICS::APPLICATION_SELECT_PAYMENT_OPTIONS',
);
