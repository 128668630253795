import { dollarToCents } from '@perpay-web/utils/numberUtils';
import { formatCurrency } from '@perpay-web/utils/stringUtils';

// The amount the advocate receives on an invitee's first order moving to approved
const ADVOCATE_COMPLETE_AMOUNT = dollarToCents(200);
// The amount the invitee receives
const INVITE_CREDIT_AMOUNT = dollarToCents(100);

const isCents = true;
const keepCents = false;

export const ADVOCATE_COMPLETE_AMOUNT_STRING = formatCurrency(
    ADVOCATE_COMPLETE_AMOUNT,
    isCents,
    keepCents,
);
export const INVITE_CREDIT_AMOUNT_STRING = formatCurrency(
    INVITE_CREDIT_AMOUNT,
    isCents,
    keepCents,
);
