import { useCallback, useEffect } from 'react';

import LoaderCircle from '@perpay-web/components/base/LoaderCircle/LoaderCircle';
import { useTimedEvent } from '@perpay-web/hooks/useTimedEvent';
import { reportError } from '@perpay-web/utils/errorHandlers';

const WARNING_TIMEOUT_MS = 15000; // 15 seconds

const WarningLoaderCircle = (props) => {
    const timedEventCallback = useCallback(() => {
        reportError('Infinite loader');
    }, []);

    const { startTimer, clearTimer } = useTimedEvent(
        timedEventCallback,
        WARNING_TIMEOUT_MS,
    );

    useEffect(() => {
        startTimer();
        return () => clearTimer();
    }, [clearTimer, startTimer]);

    return <LoaderCircle {...props} />;
};

export default WarningLoaderCircle;
