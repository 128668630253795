import { ofType } from 'redux-observable';
import { switchMap, mergeMap } from 'rxjs/operators';

import {
    replaceAccountSummary,
    fetchAccountSummaryError,
} from '@perpay-web/fintech/actions/shared/accountSummary';
import { BACKEND_FETCH_ACCOUNT_SUMMARY } from '@perpay-web/fintech/constants/actionTypes';
import { ACCOUNT_SUMMARY_ENDPOINT } from '@perpay-web/fintech/constants/urls';
import { handleErrorMessageWithFallback } from '@perpay-web/observable/operators/handleErrorMessageWithFallback';

export const fetchAccountSummary = (action$, state$, { get }) =>
    action$.pipe(
        ofType(BACKEND_FETCH_ACCOUNT_SUMMARY),
        switchMap(() => get(ACCOUNT_SUMMARY_ENDPOINT)),
        mergeMap((results) => [replaceAccountSummary(results.response)]),
        handleErrorMessageWithFallback((error) => [
            fetchAccountSummaryError(error),
        ]),
    );
