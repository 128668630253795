import { normalize } from 'normalizr';
import { ofType } from 'redux-observable';
import { exhaustMap, mergeMap } from 'rxjs/operators';

import {
    fetchFeaturesError,
    replaceFeatures,
} from '@perpay-web/fintech/actions/entities/features';
import { BACKEND_FETCH_FEATURES } from '@perpay-web/fintech/constants/actionTypes';
import { FEATURES } from '@perpay-web/fintech/constants/tableNames';
import { FEATURE_ENDPOINT } from '@perpay-web/fintech/constants/urls';
import { feature } from '@perpay-web/fintech/normalizers/schemas';
import { handleErrorMessageWithFallback } from '@perpay-web/observable/operators/handleErrorMessageWithFallback';

export function fetchFeatures(action$, state$, { get }) {
    return action$.pipe(
        ofType(BACKEND_FETCH_FEATURES),
        exhaustMap(() => get(FEATURE_ENDPOINT)),
        mergeMap((results) => {
            const normalized = normalize(results.response, [feature]);
            const features = normalized.entities[FEATURES];
            return [replaceFeatures(features)];
        }),
        handleErrorMessageWithFallback((error) => [fetchFeaturesError(error)]),
    );
}
