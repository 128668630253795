import { normalize } from 'normalizr';
import { ofType } from 'redux-observable';
import { of, timer, merge, concat, EMPTY } from 'rxjs';
import {
    mergeMap,
    switchMap,
    exhaustMap,
    withLatestFrom,
    takeUntil,
    delay,
} from 'rxjs/operators';

import {
    replacePaystub,
    paystubStartPolling,
    paystubPollingComplete,
    paystubCancelPolling,
    paystubPollingError,
    analyticsApplicationUploadPaystub,
} from '@perpay-web/fintech/actions/entities/paystubs';
import {
    BACKEND_CREATE_PAYSTUB,
    BACKEND_PAYSTUB_START_POLLING,
    BACKEND_PAYSTUB_CANCEL_POLLING,
} from '@perpay-web/fintech/constants/actionTypes';
import { PAYSTUBS } from '@perpay-web/fintech/constants/tableNames';
import { PAYSTUBS_ENDPOINT } from '@perpay-web/fintech/constants/urls';
import { paystub } from '@perpay-web/fintech/normalizers/schemas';
import { getCheckoutOrderUUID } from '@perpay-web/fintech/selectors/ui/checkout';

export function createPaystub(action$, state$, { post }) {
    return action$.pipe(
        ofType(BACKEND_CREATE_PAYSTUB),
        switchMap((action) => {
            const { file, checkoutUuid } = action.payload;
            const formData = new window.FormData();
            formData.append('paystub_file', file);
            formData.append('source_page', 'checkout');
            if (checkoutUuid) {
                formData.append('checkout_uuid', checkoutUuid);
            }
            return post(PAYSTUBS_ENDPOINT, formData);
        }),

        mergeMap((results) => {
            const paystubUUID = results.response.uuid;
            const normalized = normalize(results.response, paystub);
            return [
                replacePaystub(normalized.entities.paystubs),
                paystubStartPolling(paystubUUID),
            ];
        }),
    );
}

export function pollPaystub(action$, state$, { get }) {
    return action$.pipe(
        ofType(BACKEND_PAYSTUB_START_POLLING),
        withLatestFrom(state$),
        switchMap(([action, state]) => {
            const pollingTimeout$ = of(paystubPollingComplete()).pipe(
                delay(10 * 1000),
            );
            const cancelActions$ = action$.pipe(
                ofType(BACKEND_PAYSTUB_CANCEL_POLLING),
            );
            const stopPolling$ = merge(pollingTimeout$, cancelActions$);
            const { paystubUUID } = action.payload;
            const poll$ = timer(0, 1000).pipe(
                takeUntil(stopPolling$),
                exhaustMap(() => get(`${PAYSTUBS_ENDPOINT}${paystubUUID}/`)),

                mergeMap((results) => {
                    if (results.status === 200 || results.status === 201) {
                        const { reviewStatus } = results.response;
                        const reviewStatusResult = reviewStatus.result;
                        const normalized = normalize(results.response, paystub);
                        if (reviewStatusResult !== 'processing') {
                            const returnActions = [
                                replacePaystub(normalized.entities[PAYSTUBS]),
                                paystubCancelPolling(),
                                analyticsApplicationUploadPaystub(
                                    getCheckoutOrderUUID(state),
                                ),
                            ];
                            if (reviewStatus.errorDegree) {
                                returnActions.push(
                                    paystubPollingError(
                                        reviewStatus.errorDegree,
                                        reviewStatus.userMessage,
                                    ),
                                );
                            }
                            return returnActions;
                        }
                        return EMPTY;
                    }
                    // If we hit here, it's because we need to reauth.
                    // We need to cancel the current polling so that everything
                    // will work peachy when the action to start polling is dequeued post-reauth.
                    return [paystubCancelPolling(), ...results];
                }),
            );
            return concat(poll$, [paystubPollingComplete()]);
        }),
    );
}
