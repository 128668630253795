import {
    fetchAuthTokensSuccess,
    storeMFAData,
    storeMFAAttemptError,
    storeMFAResendError,
    storeMFAThrottleError,
    resendMFA,
    verifyMFA,
    resetAuthLogout,
} from '@perpay-web/fintech/actions/authentication';
import {
    BACKEND_FETCH_AUTH_WITH_CREDENTIALS,
    BACKEND_FETCH_AUTH_WITH_CREDENTIALS_ERROR,
    BACKEND_FETCH_AUTH_WITH_REFRESH_TOKEN,
    BACKEND_POST_PASSWORD_RESET,
    STORE_REPLACE_AUTH,
    STORE_REPLACE_PASSWORD_RESET_SUCCESS,
    STORE_REPLACE_PASSWORD_RESET_ERROR,
    STORE_CLEAR_PASSWORD_RESET,
    STORE_REPLACE_RECAPTCHA_ACTIVE,
    STORE_PARTNER_HOSTED_ONBOARDING_REDIRECT_EXISTING_USER,
} from '@perpay-web/fintech/constants/actionTypes';
import { authentication } from '@perpay-web/fintech/settings/singletons';

export const UNAUTHENTICATED = 'unauthenticated';
export const REFRESHING = 'refreshing';
export const AUTHENTICATED = 'authenticated';

const passwordState = {
    resetError: null,
    resetRedirectPath: null,
};

const cleanState = {
    access: null,
    refresh: null,
    tokenState: UNAUTHENTICATED,
    loginLoading: false,
    resetLoading: false,
    recaptchaActive: false,
    partnerCompleteSignup: null,
};

const mfaInitialState = {
    error: {
        attempt: null,
        resend: null,
        throttle: null,
    },
    isLoading: false,
    data: {
        otpUuid: '',
        validUntil: '',
        contactInfo: {
            email: '',
            phone: '',
        },
    },
};

const getInitialState = () => {
    const storedToken = authentication.getDecodedTokens();
    const isRefreshTokenActive = !authentication.getIsRefreshTokenExpired();
    const tokenState = isRefreshTokenActive ? AUTHENTICATED : UNAUTHENTICATED;
    const cleanPassword = { ...passwordState };

    return {
        ...cleanState,
        password: cleanPassword,
        ...storedToken,
        tokenState,
        isExistingUser: false,
        mfa: mfaInitialState,
    };
};

// Use a getter for the initial state so that when we reset the store
// on logout, the authentication state gets updated at that time.
const authenticationReducer = (state = getInitialState(), action = {}) => {
    const { type, payload } = action;

    switch (type) {
        case BACKEND_POST_PASSWORD_RESET:
            return { ...state, resetLoading: true };
        case STORE_CLEAR_PASSWORD_RESET:
            return {
                ...state,
                resetLoading: false,
                password: { ...passwordState },
            };
        case STORE_REPLACE_PASSWORD_RESET_SUCCESS:
            return {
                ...state,
                password: { resetRedirectPath: payload.redirectPath },
                resetLoading: false,
            };
        case STORE_REPLACE_PASSWORD_RESET_ERROR:
            return {
                ...state,
                password: { resetError: payload.message },
                resetLoading: false,
            };
        case BACKEND_FETCH_AUTH_WITH_CREDENTIALS:
            return { ...state, errors: {}, loginLoading: true };
        case BACKEND_FETCH_AUTH_WITH_CREDENTIALS_ERROR:
            return { ...state, errors: payload, loginLoading: false };
        case fetchAuthTokensSuccess().type:
            return { ...state, loginLoading: true };
        case BACKEND_FETCH_AUTH_WITH_REFRESH_TOKEN:
            return { ...state, tokenState: REFRESHING };
        case resetAuthLogout().type:
            return getInitialState();
        case verifyMFA().type:
            return {
                ...state,
                mfa: {
                    ...state.mfa,
                    isLoading: true,
                    throttleLoginTTL: 0,
                    error: { attempt: null, resend: null },
                },
            };
        case resendMFA().type:
            return { ...state };
        case storeMFAData().type:
            return {
                ...state,
                loginLoading: false,
                mfa: {
                    ...mfaInitialState,
                    data: payload,
                },
            };
        case storeMFAAttemptError().type:
            return {
                ...state,
                mfa: {
                    ...state.mfa,
                    isLoading: false,
                    throttleLoginTTL: 0,
                    error: {
                        resend: null,
                        throttle: null,
                        attempt: payload,
                    },
                },
            };
        case storeMFAResendError().type:
            return {
                ...state,
                mfa: {
                    ...state.mfa,
                    error: {
                        attempt: null,
                        throttle: null,
                        resend: payload,
                    },
                },
            };
        case storeMFAThrottleError().type:
            return {
                ...state,
                mfa: {
                    ...state.mfa,
                    isLoading: false,
                    error: {
                        resend: null,
                        attempt: null,
                        throttle: payload,
                    },
                },
            };
        case STORE_REPLACE_AUTH: {
            const decodedTokens = {
                access: state.access,
                refresh: state.refresh,
                ...payload,
            };

            return {
                ...state,
                ...decodedTokens,
                tokenState: AUTHENTICATED,
                errors: null,
            };
        }
        case STORE_REPLACE_RECAPTCHA_ACTIVE:
            return { ...state, recaptchaActive: payload.active };
        case STORE_PARTNER_HOSTED_ONBOARDING_REDIRECT_EXISTING_USER:
            return { ...state, isExistingUser: true };

        default:
            return state;
    }
};

export default authenticationReducer;
