import { composeDataModules } from '@perpay-web/data-module/composeDataModules';
import {
    fetchJobsDataModule,
    getIsPerpaySplitEligible,
} from '@perpay-web/fintech/dataModules/fetchJobs';
import {
    fetchTransactionDetailsDataModule,
    getResults,
    getPageCount,
} from '@perpay-web/fintech/dataModules/fetchTransactionDetails';
import { fetchWithdrawalBalanceV2DataModule } from '@perpay-web/fintech/dataModules/fetchWithdrawalBalance';
import {
    generalAccountSummaryDataModule,
    getCardMinimumPayment,
    getCoreMinimumPayment,
} from '@perpay-web/fintech/dataModules/generalAccountSummary';

const DirectDepositDetailsDataModule = composeDataModules([
    fetchTransactionDetailsDataModule,
    fetchJobsDataModule,
    fetchWithdrawalBalanceV2DataModule,
    generalAccountSummaryDataModule,
]);

const getRoot = (state) => state.dataModules.directDepositDetails;

export const directDepositDetailsDataModule = DirectDepositDetailsDataModule({
    getRoot,
    key: 'DIRECT_DEPOSIT',
});

export {
    getIsPerpaySplitEligible,
    getResults,
    getPageCount,
    getCardMinimumPayment,
    getCoreMinimumPayment,
};
