import { CategoryButton } from '@perpay-web/storefront/components/base/CategoryButton/CategoryButton';
import { getClassName } from '@perpay-web/utils/domUtils';

import styles from './AutocompleteCategoryList.scss';

// Make it available for the source
export { styles };

export const AutocompleteCategoryList = ({
    items,
    getItemProps,
    source,
    listProps,
}) => (
    <ul
        className={styles.list}
        id={listProps.id}
        aria-labelledby={listProps['aria-labelledby']}
        role={listProps.role}
    >
        {items.map((item) => {
            const listItemProps = getItemProps({ source, item });
            const isActive = listItemProps['aria-selected'];
            return (
                <li
                    key={listItemProps.id}
                    id={listItemProps.id}
                    className={styles.item}
                    role={listItemProps.role}
                    aria-selected={listItemProps['aria-selected']}
                    onMouseMove={listItemProps.onMouseMove}
                >
                    <CategoryButton
                        className={getClassName(
                            item.className,
                            isActive && styles.active,
                        )}
                        onClick={(e) => {
                            listItemProps.onClick(e);
                        }}
                        onMouseDown={(e) => {
                            listItemProps.onMouseDown(e);
                        }}
                    >
                        {item.name}
                    </CategoryButton>
                </li>
            );
        })}
    </ul>
);
