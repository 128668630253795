import { createAction } from '@reduxjs/toolkit';
import { useMemo } from 'react';

import {
    LOADING_STEP,
    NON_PINWHEEL_STEP,
    PERPAY_SPLIT_STEP,
    PINWHEEL_SPLIT_STEP,
} from '@perpay-web/fintech/components/composite/PaymentSetup/PaymentSetupSteps';
import { PINWHEEL_STEP } from '@perpay-web/fintech/constants/steps/cardOnboardingSteps';
import { useActionObservableCallback } from '@perpay-web/hooks/useActionObservableCallback';
import { useSteps } from '@perpay-web/hooks/useSteps';
import { hookAction$ } from '@perpay-web/services/singletons';
import { createContextProvider } from '@perpay-web/utils/componentUtils';

export const paymentSetupStepsSetStep = createAction(
    'PAYMENT_SETUP_STEPS::SET_STEP',
);

const paymentSetupStepsGoBack = createAction('PAYMENT_SETUP_STEPS::GO_BACK');

const PAYMENT_SETUP_STEPS = [
    LOADING_STEP,
    PINWHEEL_STEP,
    NON_PINWHEEL_STEP,
    PERPAY_SPLIT_STEP,
    PINWHEEL_SPLIT_STEP,
];

const providerFactory = (Context) => {
    const Provider = ({ children }) => {
        const hook = useSteps(PAYMENT_SETUP_STEPS, LOADING_STEP);

        useActionObservableCallback(
            hookAction$,
            paymentSetupStepsSetStep().type,
            (step) => hook.setStep(step),
        );

        useActionObservableCallback(
            hookAction$,
            paymentSetupStepsGoBack().type,
            () => hook.goBack(),
        );

        const contextValue = useMemo(
            () => ({
                step: hook.step,
            }),
            [hook.step],
        );
        return (
            <Context.Provider value={contextValue}>{children}</Context.Provider>
        );
    };

    return Provider;
};

export const [PaymentSetupStepsProvider, usePaymentSetupStepsContext] =
    createContextProvider(providerFactory);
