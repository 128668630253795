import { createDataModule } from '@perpay-web/data-module/createDataModule';
import {
    fetchJobsErrorForDataModule,
    fetchJobsForDataModule,
    fetchJobsReset,
    fetchJobsSuccess,
} from '@perpay-web/fintech/actions/entities/jobs';

const FetchJobsDataModule = createDataModule({
    dataRequest: fetchJobsForDataModule,
    dataSuccess: fetchJobsSuccess,
    dataError: fetchJobsErrorForDataModule,
    dataReset: fetchJobsReset,
});

const getRoot = (state) => state.dataModules.fetchJobs;
const initialValue = {
    primary: {},
};

export const fetchJobsDataModule = FetchJobsDataModule({
    getRoot,
    initialValue,
});

const getPrimaryJob = (state) => fetchJobsDataModule.getData(state).primary;

export const getIsCompanyUnverified = (state) => {
    const primaryJob = getPrimaryJob(state);
    return primaryJob && primaryJob.company
        ? !primaryJob.company.verified
        : false;
};

export const getIsPerpaySplitEligible = (state) => {
    const primaryJob = getPrimaryJob(state);
    return primaryJob && primaryJob.payrollProviderType === 'full_paycheck';
};

export const getIsGigEconomyCompany = (state) => {
    const primaryJob = getPrimaryJob(state);
    return (primaryJob && primaryJob.isGigEconomyCompany) || false;
};

export const getCompanyName = (state) => {
    const primaryJob = getPrimaryJob(state);
    return primaryJob && primaryJob.company ? primaryJob.company.name : '';
};

export const getCompanyUuid = (state) => {
    const primaryJob = getPrimaryJob(state);
    return primaryJob && primaryJob.company ? primaryJob.company.uuid : '';
};

export const getPayCycle = (state) => {
    const primaryJob = getPrimaryJob(state);
    return primaryJob ? primaryJob.payCycle : '';
};

export const getSupportsDecimalDirectDeposits = (state) => {
    const primaryJob = getPrimaryJob(state);
    return primaryJob && primaryJob.company
        ? !primaryJob.company.roundToDollar
        : true;
};

export const getEstimatedNetPay = (state) => {
    const primaryJob = getPrimaryJob(state);
    return primaryJob ? primaryJob.estimatedNetPay : 0;
};

export const getJobUuid = (state) => {
    const primaryJob = getPrimaryJob(state);
    return primaryJob.uuid || '';
};
