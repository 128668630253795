import {
    FETCH_USER_INFO,
    REPLACE_USER_INFO,
    BACKEND_CARD_UPDATE_USER_INFO,
    BACKEND_CARD_UPDATE_USER_INFO_ERROR,
    BACKEND_CREATE_DEDUCTION,
    STORE_REPLACE_DEDUCTION,
    STORE_REPLACE_DEDUCTION_ERROR,
} from '@perpay-web/fintech/constants/actionTypes';
import { CARD_ONBOARDING_STEPS } from '@perpay-web/fintech/constants/steps/cardOnboardingSteps';
import { withReduxStepsReducer } from '@perpay-web/hooks/useReduxSteps';

const initialState = {
    submitting: false,
    loading: true,
    fetchingPinwheelToken: false,
    errors: {},
};

const cardOnboardingReducer = (state = initialState, action = {}) => {
    const { type, payload } = action;

    switch (type) {
        case FETCH_USER_INFO:
            return {
                ...state,
                loading: true,
            };
        case REPLACE_USER_INFO:
            return {
                ...state,
                loading: false,
                submitting: false,
            };
        case BACKEND_CARD_UPDATE_USER_INFO:
            return { ...state, submitting: true };
        case BACKEND_CARD_UPDATE_USER_INFO_ERROR:
            return { ...state, submitting: false, errors: payload };
        case BACKEND_CREATE_DEDUCTION:
            return { ...state, fetchingPinwheelToken: true };
        case STORE_REPLACE_DEDUCTION:
            return { ...state, fetchingPinwheelToken: false };
        case STORE_REPLACE_DEDUCTION_ERROR:
            return { ...state, fetchingPinwheelToken: false, errors: payload };
        default:
            return state;
    }
};

export default withReduxStepsReducer(
    CARD_ONBOARDING_STEPS,
    cardOnboardingReducer,
);
