import { BehaviorSubject } from 'rxjs';

const stateFromNative$ = new BehaviorSubject({});
window.perpayStateFromNative$ = stateFromNative$;

// Generate a string to pass to injectJavaScript that will pass the state to the
// perpayStateFromNative$ Observable to transmit actions from native to fintech.
export function getStateInjectionString(state) {
    return `window.perpayStateFromNative$.next(${JSON.stringify(state)});`;
}

export function getStateFromNative() {
    return window.perpayStateFromNative$;
}
