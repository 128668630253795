import {
    BACKEND_FETCH_ACCOUNT_SUMMARY,
    STORE_ACCOUNT_SUMMARY_REPLACE,
    STORE_ACCOUNT_SUMMARY_ERROR,
    BACKEND_FETCH_JOBS,
    STORE_REPLACE_JOBS,
    STORE_REPLACE_JOBS_ERROR,
    BACKEND_FETCH_DEDUCTIONS,
    STORE_REPLACE_DEDUCTION,
    BACKEND_FETCH_USER_STATUS,
    STORE_REPLACE_USER_STATUS,
    STORE_REPLACE_USER_STATUS_ERROR,
    BACKEND_CREATE_DEDUCTION,
    STORE_REPLACE_DEDUCTION_ERROR,
    STORE_DEDUCTION_CLEAR_ERRORS,
    BACKEND_UPDATE_SALARY_INFO,
    BACKEND_CREATE_PRIMARY_JOB,
    STORE_REPLACE_SALARY_INFO,
    STORE_REPLACE_PRIMARY_JOB,
    STORE_CREATE_PRIMARY_JOB_ERROR,
    STORE_UPDATE_SALARY_INFO_ERROR,
    STORE_PINWHEEL_RESET,
    BACKEND_FETCH_PINWHEEL_ELIGIBILITY,
    BACKEND_UPDATE_SUCCESS_DEDUCTION,
    PINWHEEL_SET_AMOUNT_TO_CHARGE,
} from '@perpay-web/fintech/constants/actionTypes';
import { PINWHEEL_STEPS } from '@perpay-web/fintech/constants/pinwheelConstants';
import { withReduxStepsReducer } from '@perpay-web/hooks/useReduxSteps';
import { resetState } from '@perpay-web/utils/reducerUtils';

const initialState = {
    fetchingAccountSummary: true,
    fetchingJobs: true,
    fetchingDeductions: true,
    fetchingUserStatus: true,
    fetchingPinwheelToken: false,
    patchingDeductions: false,
    isSubmittingJob: false,
    amountToCharge: 0,
    errors: {},
};

const pinwheelReducer = (state = initialState, action = {}) => {
    const { type, payload } = action;

    switch (type) {
        case BACKEND_FETCH_PINWHEEL_ELIGIBILITY:
            return {
                ...state,
                fetchingUserStatus: true,
                fetchingDeductions: true,
            };
        case BACKEND_FETCH_ACCOUNT_SUMMARY:
            return { ...state, fetchingAccountSummary: true };
        case STORE_ACCOUNT_SUMMARY_REPLACE:
            return {
                ...state,
                amountToCharge: payload.minimumPayment,
                fetchingAccountSummary: false,
            };
        case STORE_ACCOUNT_SUMMARY_ERROR:
            return { ...state, fetchingAccountSummary: false, errors: payload };
        case BACKEND_UPDATE_SALARY_INFO:
        case BACKEND_CREATE_PRIMARY_JOB:
            return { ...state, isSubmittingJob: true };
        case STORE_UPDATE_SALARY_INFO_ERROR:
        case STORE_CREATE_PRIMARY_JOB_ERROR:
        case STORE_REPLACE_SALARY_INFO:
        case STORE_REPLACE_PRIMARY_JOB:
            return { ...state, isSubmittingJob: false };
        case BACKEND_FETCH_JOBS:
            return { ...state, fetchingJobs: true };
        case STORE_REPLACE_JOBS_ERROR:
        case STORE_REPLACE_JOBS:
            return { ...state, fetchingJobs: false };
        case BACKEND_FETCH_USER_STATUS:
            return { ...state, fetchingUserStatus: true };
        case STORE_REPLACE_USER_STATUS:
        case STORE_REPLACE_USER_STATUS_ERROR:
            return { ...state, fetchingUserStatus: false };
        case BACKEND_CREATE_DEDUCTION:
            return { ...state, fetchingPinwheelToken: true };
        case BACKEND_UPDATE_SUCCESS_DEDUCTION:
            return { ...state, patchingDeductions: true };
        case BACKEND_FETCH_DEDUCTIONS:
            return { ...state, fetchingDeductions: true };
        case STORE_REPLACE_DEDUCTION_ERROR:
            return {
                ...state,
                fetchingDeductions: false,
                fetchingPinwheelToken: false,
                patchingDeductions: false,
                errors: payload,
            };
        case STORE_REPLACE_DEDUCTION:
            return {
                ...state,
                fetchingPinwheelToken: false,
                fetchingDeductions: false,
                patchingDeductions: false,
            };
        case STORE_DEDUCTION_CLEAR_ERRORS:
            return { ...state, errors: {} };
        case STORE_PINWHEEL_RESET:
            return resetState(state, initialState);
        case PINWHEEL_SET_AMOUNT_TO_CHARGE:
            return { ...state, amountToCharge: payload.amount };
        default:
            return state;
    }
};

export default withReduxStepsReducer(PINWHEEL_STEPS, pinwheelReducer);
