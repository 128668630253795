import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import DefaultHeader from '@perpay-web/fintech/components/base/Header/DefaultHeader';
import { useFullHeaderRender } from '@perpay-web/fintech/hooks/useFullHeaderContext';
import { useMount } from '@perpay-web/hooks/useMount';
import { usePrevious } from '@perpay-web/hooks/usePrevious';

const Header = ({
    className = '',
    userData = { fullName: '' },
    fetchDataDependencies,
    isAuthenticated = false,
    isPerpayPlusEligible,
    hasPerpayPlus,
    onClickPerpayPlus,
    launchSupportToolHandler,
    hasClosedCardAccount,
    analyticsHeaderOnClickDashboard,
    analyticsHeaderOnClickShop,
    analyticsHeaderOnClickHamburger,
}) => {
    const location = useLocation();
    const previousIsAuthenticated = usePrevious(isAuthenticated);
    useMount(() => {
        fetchDataDependencies();
    });

    useEffect(() => {
        if (previousIsAuthenticated === null) {
            return;
        }
        if (!previousIsAuthenticated && isAuthenticated) {
            fetchDataDependencies();
        }
    }, [fetchDataDependencies, isAuthenticated, previousIsAuthenticated]);

    const rendered = useFullHeaderRender({
        // All props
        className,
        path: location.pathname,
        userData,
        fetchDataDependencies,
        isAuthenticated,
        isPerpayPlusEligible,
        hasPerpayPlus,
        onClickPerpayPlus,
        launchSupportToolHandler,
        hasClosedCardAccount,
        analyticsHeaderOnClickDashboard,
        analyticsHeaderOnClickShop,
        analyticsHeaderOnClickHamburger,
    });
    if (typeof rendered !== 'undefined') {
        return rendered;
    }

    return (
        <DefaultHeader
            className={className}
            path={location.pathname}
            userData={userData}
            fetchDataDependencies={fetchDataDependencies}
            isAuthenticated={isAuthenticated}
            isPerpayPlusEligible={isPerpayPlusEligible}
            hasPerpayPlus={hasPerpayPlus}
            onClickPerpayPlus={onClickPerpayPlus}
            launchSupportToolHandler={launchSupportToolHandler}
            hasClosedCardAccount={hasClosedCardAccount}
            analyticsHeaderOnClickDashboard={analyticsHeaderOnClickDashboard}
            analyticsHeaderOnClickShop={analyticsHeaderOnClickShop}
            analyticsHeaderOnClickHamburger={analyticsHeaderOnClickHamburger}
        />
    );
};

export default Header;
