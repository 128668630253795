export const getUserInfo = (state) => {
    const userInfo = Object.values(state.entities.userInfo)[0] || {};
    let fullName = '';
    let address2 = '';

    if (userInfo.middle_name) {
        fullName = `${userInfo.firstName} ${userInfo.middleName} ${userInfo.lastName}`;
    } else {
        fullName = `${userInfo.firstName} ${userInfo.lastName}`;
    }

    if (userInfo.city && userInfo.state && userInfo.zipcode) {
        address2 = `${userInfo.city}, ${userInfo.state} ${userInfo.zipcode}`;
    }

    return {
        fullName,
        firstName: userInfo.firstName,
        middleName: userInfo.middleName,
        lastName: userInfo.lastName,
        address: [userInfo.streetAddr, address2],
        streetAddr: userInfo.streetAddr,
        city: userInfo.city,
        state: userInfo.state,
        zipcode: userInfo.zipcode,
        email: userInfo.email,
        emailVerified: userInfo.emailVerified || false,
        mobilePhone: userInfo.mobilePhone,
        mobileVerified: userInfo.phoneVerified || false,
        ssn: userInfo.ssn,
        visibleSsn: userInfo.visibleSsn,
        ssnIsVerified: userInfo.ssnIsVerified || false,
        ssnVerificationAttempts: userInfo.ssnVerificationAttempts,
        dateOfBirth: userInfo.dateOfBirth,
        referralUrl: userInfo.referralUrl,
        editableIdentity: userInfo.editableIdentity,
        uuid: userInfo.uuid,
    };
};

export const getFirstName = (state) => getUserInfo(state).firstName;

export const getUserInfoErrors = (state) =>
    state.entities.userInfo && state.entities.userInfo.errors;

export const getIsLoading = (state) =>
    !Object.values(state.entities.userInfo)[0] && !getUserInfoErrors(state);

export const getSsnIsVerified = (state) => getUserInfo(state).ssnIsVerified;
