import {
    NEW,
    REJECTED,
    APPROVED,
    IN_REVIEW,
    UPLOAD_DOCUMENTS,
    CLARITY_FROZEN,
    AWAITING_DEDUCTION_SETUP,
    COMPLETE,
    ACTIVE_FRAUD_FLAG,
    EXTENDED_ACTIVE_FRAUD_FLAG,
} from '@perpay-web/fintech/constants/cardAccountApplicationStatuses';

export const PERSONAL_DETAILS_STEP = 'personalDetails';
export const APPLICATION_STEP = 'application';
export const SUBMITTING_STEP = 'submitting-acceptance';
export const APPLICATION_ERROR_STEP = 'application-error';

export const OFFER_STEP = 'offer';
export const COMPANY_REJECTED_STEP = 'company-rejected';
export const DENIED_STEP = 'denied';
export const REVIEW_DIRECT_DEPOSIT_STEP = 'review-direct-deposit';
export const PINWHEEL_STEP = 'pinwheel';
export const PINWHEEL_ERROR_STEP = 'pinwheel-error';
export const UPLOAD_DOCUMENTS_STEP = 'upload-documents';
export const CLARITY_UNFREEZE_STEP = 'clarity-unfreeze';
export const SUCCESS_STEP = 'success';
export const IN_REVIEW_STEP = 'in-review';
export const MANUAL_PAYMENT_SETUP_STEP = 'manual-payment';
export const SELECT_MINIMUM_PAYMENT_STEP = 'select-minimum-payment';
export const IDENTITY_STEP = 'identity-step';
export const EXTENDED_IDENTITY_STEP = 'extended-identity-step';

export const CARD_APPLICATION_STEPS = [
    PERSONAL_DETAILS_STEP,
    APPLICATION_STEP,
    SUBMITTING_STEP,
    OFFER_STEP,
    REVIEW_DIRECT_DEPOSIT_STEP,
    PINWHEEL_STEP,
    SUCCESS_STEP,
    PINWHEEL_ERROR_STEP,
    MANUAL_PAYMENT_SETUP_STEP,
    SUBMITTING_STEP,
    UPLOAD_DOCUMENTS_STEP,
    IN_REVIEW_STEP,
    CLARITY_UNFREEZE_STEP,
    COMPANY_REJECTED_STEP,
    DENIED_STEP,
    APPLICATION_ERROR_STEP,
    IDENTITY_STEP,
    EXTENDED_IDENTITY_STEP,
    SELECT_MINIMUM_PAYMENT_STEP,
];

export const getCardApplicationStepFromStatus = (status) => {
    switch (status) {
        case NEW:
            return SUBMITTING_STEP;
        case REJECTED:
            return DENIED_STEP;
        case CLARITY_FROZEN:
            return CLARITY_UNFREEZE_STEP;
        case UPLOAD_DOCUMENTS:
            return UPLOAD_DOCUMENTS_STEP;
        case IN_REVIEW:
            return IN_REVIEW_STEP;
        case APPROVED:
            return OFFER_STEP;
        case AWAITING_DEDUCTION_SETUP:
            return REVIEW_DIRECT_DEPOSIT_STEP;
        case COMPLETE:
            return SUCCESS_STEP;
        case ACTIVE_FRAUD_FLAG:
            return IDENTITY_STEP;
        case EXTENDED_ACTIVE_FRAUD_FLAG:
            return EXTENDED_IDENTITY_STEP;
        default:
            return APPLICATION_ERROR_STEP;
    }
};

export const cardApplicationStepperNames = [
    { name: '1. About me', step: PERSONAL_DETAILS_STEP },
    { name: '2. Application', step: APPLICATION_STEP },
    { name: '3. Offer', step: OFFER_STEP },
    { name: '4. Direct Deposit', step: REVIEW_DIRECT_DEPOSIT_STEP },
];
