import { composeDataModules } from '@perpay-web/data-module/composeDataModules';
import { usePayrollPrimerScreenABTestAdapter } from '@perpay-web/hooks/abTests/usePayrollPrimerScreenABTest';

const PayrollPrimerSwitcherDataModule = composeDataModules([
    usePayrollPrimerScreenABTestAdapter,
]);

const getRoot = (state) => state.dataModules.payrollPrimerSwitcher;

export const payrollPrimerSwitcherDataModule = PayrollPrimerSwitcherDataModule({
    getRoot,
    key: 'PAYROLL_PRIMER_SWITCHER',
});
