import { ofType } from 'redux-observable';
import { mergeMap } from 'rxjs';

import { routeToLocation } from '@perpay-web/fintech/actions/router';
import { nativeDeepLinkReceived } from '@perpay-web/native-client/actions/linking';

export const nativeLinkReceived = (action$) =>
    action$.pipe(
        ofType(nativeDeepLinkReceived().type),
        mergeMap((action) => [routeToLocation(action.payload)]),
    );
