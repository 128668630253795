import { ofType } from 'redux-observable';
import { exhaustMap } from 'rxjs/operators';

import {
    createWithdrawalRequest,
    createWithdrawalRequestUI as createWithdrawalRequestUIAction,
    createWithdrawalRequestErrors,
    createWithdrawalRequestSuccess,
} from '@perpay-web/fintech/actions/ui/withdrawal';
import {
    STEPS,
    WITHDRAWAL_SUCCESS,
} from '@perpay-web/fintech/constants/steps/withdrawalSteps';
import { reduxStepsSetStep } from '@perpay-web/hooks/useReduxSteps';
import { dispatchObservable } from '@perpay-web/observable/dispatchObservable';

export const createWithdrawalRequestUI = (action$, state$) =>
    action$.pipe(
        ofType(createWithdrawalRequestUIAction().type),
        exhaustMap((action) =>
            dispatchObservable({
                action$,
                state$,
                initialDispatch: () => [
                    createWithdrawalRequest(action.payload),
                ],
                waitFor: [createWithdrawalRequestSuccess().type],
                waitForDispatch: () => [
                    reduxStepsSetStep(STEPS, WITHDRAWAL_SUCCESS),
                ],
                // The UI consumes the errors that are stored by the createBank epic.
                errors: [createWithdrawalRequestErrors().type],
                errorDispatch: () => [],
                // Timeouts are handled within the bank entity epic
            }),
        ),
    );
