import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getIsLoading } from '@perpay-web/data-module/useDataModule';
import { fetchAuthTokens } from '@perpay-web/fintech/actions/authentication';
import { passkeyAuthenticateBegin } from '@perpay-web/fintech/actions/passkey';
import LoginForm from '@perpay-web/fintech/components/composite/LoginForm/LoginForm';
import { REACT_NATIVE_PASSKEY_AUTHENTICATION_IS_LAUNCHED } from '@perpay-web/fintech/constants/flags';
import { usePasskeyAuthenticateBeginContext } from '@perpay-web/fintech/hooks/dataModule/usePasskeyAuthenticateBeginContext';
import { usePasskeyAuthenticateCompleteContext } from '@perpay-web/fintech/hooks/dataModule/usePasskeyAuthenticateCompleteContext';
import {
    getLoginLoading,
    getErrors,
    getIsExistingUser,
    getIsRecaptchaActive,
    getMFA,
} from '@perpay-web/fintech/selectors/authentication';
import { clearPasskeyIds } from '@perpay-web/native-client/actions/passkey';
import { useNativeSelector } from '@perpay-web/native-client/hooks/useNativeSelector';
import {
    getHasPasskey,
    getIsEnrolledWithBiometrics,
    getIsPasskeySupported,
    getBiometricsType,
} from '@perpay-web/native-client/selectors/passkey';

const LoginFormContainer = () => {
    const dispatch = useDispatch();
    const errors = useSelector(getErrors);
    const loginLoading = useSelector(getLoginLoading);
    const recaptchaActive = useSelector(getIsRecaptchaActive);
    const mfa = useSelector(getMFA);
    const isExistingUser = useSelector(getIsExistingUser);

    const onSubmit = useCallback(
        (credentials) => dispatch(fetchAuthTokens(credentials)),
        [dispatch],
    );

    const onClickFaceId = useCallback(
        () => dispatch(passkeyAuthenticateBegin()),
        [dispatch],
    );
    const { state: authenticateBeginState } =
        usePasskeyAuthenticateBeginContext();
    const { state: authenticateCompleteState } =
        usePasskeyAuthenticateCompleteContext();

    const isLoadingFaceId =
        REACT_NATIVE_PASSKEY_AUTHENTICATION_IS_LAUNCHED &&
        (getIsLoading(authenticateBeginState) ||
            getIsLoading(authenticateCompleteState));

    const hasPasskey = useNativeSelector(getHasPasskey);
    const onMount = useCallback(() => {
        if (!hasPasskey) {
            return;
        }

        dispatch(passkeyAuthenticateBegin());
    }, [dispatch, hasPasskey]);

    const isPasskeySupported = useNativeSelector(getIsPasskeySupported);
    const isEnrolledWithBiometrics = useNativeSelector(
        getIsEnrolledWithBiometrics,
    );

    const shouldShowBiometricLogin =
        REACT_NATIVE_PASSKEY_AUTHENTICATION_IS_LAUNCHED &&
        isPasskeySupported &&
        isEnrolledWithBiometrics;

    const biometricsType = useNativeSelector(getBiometricsType);

    const onToggleBiometrics = useCallback(
        (value) => {
            // If the user unchecks the box, even if they recheck it again,
            // it will stop prompting them to use a passkey to log in when they
            // land on the login page.
            // This is desirable because if the user deletes their passkey from the OS,
            // the app doesn't know and will continue to automatically prompt them with
            // the system passkey auth modal. Clearing the passkey IDs will allow
            // the app to know to stop prompting them.
            if (hasPasskey && !value) {
                dispatch(clearPasskeyIds());
            }
        },
        [dispatch, hasPasskey],
    );

    return (
        <LoginForm
            onMount={onMount}
            onSubmit={onSubmit}
            isLoading={loginLoading}
            errors={errors}
            recaptchaActive={recaptchaActive}
            mfa={mfa}
            isExistingUser={isExistingUser}
            onClickFaceId={onClickFaceId}
            isLoadingFaceId={isLoadingFaceId}
            shouldShowBiometricLogin={shouldShowBiometricLogin}
            biometricsType={biometricsType}
            onToggleBiometrics={onToggleBiometrics}
            isBiometricsRegistered={hasPasskey}
        />
    );
};

export default LoginFormContainer;
