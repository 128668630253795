import { createMiddleware } from 'redux-beacon';

import djangoTrackingEventsMap from '@perpay-web/fintech/beacon/eventMaps/DjangoTracking';
import DjangoTrackingTargetFactory from '@perpay-web/fintech/beacon/targets/DjangoTracking';

export function getDjangoTrackingMiddleware() {
    return createMiddleware(
        djangoTrackingEventsMap,
        DjangoTrackingTargetFactory(),
    );
}
