import { useEffect } from 'react';

import {
    CARD_CONSOLIDATION_API_NAME,
    CARD_CONSOLIDATION_STORE_NAME,
} from '@perpay-web/constants/src/experiments';
import { createDataModuleAdapter } from '@perpay-web/data-module/src/composeDataModules';
import { createDataModuleActions } from '@perpay-web/data-module/src/createDataModuleActions';
import { getIsLoadingOrUnrequested } from '@perpay-web/data-module/src/useDataModule';
import { useReduxDataModuleHookConnection } from '@perpay-web/data-module/src/useReduxDataModuleHookConnection';
import {
    getIsUserInTestGroup,
    useABTestDataModule,
} from '@perpay-web/hooks/abTests/useABTestDataModule';
import { hookAction$ } from '@perpay-web/services/singletons';

const dataModuleFetchActions = createDataModuleActions(
    'FETCH_CARD_CONSOLIDATION_AB_TEST',
);
const fetchCardConsolidationABTest = dataModuleFetchActions.dataRequest;
const fetchCardConsolidationABTestSuccess = dataModuleFetchActions.dataSuccess;
const fetchCardConsolidationABTestError = dataModuleFetchActions.dataError;
const fetchCardConsolidationABTestReset = dataModuleFetchActions.dataReset;

export const useCardConsolidationABTest = () => {
    const dataModule = useABTestDataModule();
    const { state, dataRequest } = dataModule;

    const isLoading = getIsLoadingOrUnrequested(state);
    const isUserInTestGroup = getIsUserInTestGroup(
        state,
        CARD_CONSOLIDATION_STORE_NAME,
    );

    useReduxDataModuleHookConnection({
        dataModule,
        hookAction$,
        request: fetchCardConsolidationABTest,
        success: fetchCardConsolidationABTestSuccess,
        error: fetchCardConsolidationABTestError,
        reset: fetchCardConsolidationABTestReset,
    });

    useEffect(() => {
        if (window.STORYBOOK) return;
        dataRequest(CARD_CONSOLIDATION_API_NAME);
    }, [dataRequest]);

    return { isLoading, isUserInTestGroup };
};

export const useCardConsolidationABTestAdapter = createDataModuleAdapter(
    fetchCardConsolidationABTest,
    fetchCardConsolidationABTestSuccess,
    fetchCardConsolidationABTestError,
);
