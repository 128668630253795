import { authenticatedJsonFetch } from '@perpay-web/authentication/authenticatedJsonFetch';
import { AB_TESTS_ENDPOINT } from '@perpay-web/constants/urls';
import {
    useDataModule,
    getData,
} from '@perpay-web/data-module/src/useDataModule';

export const getIsUserInTestGroup = (state, testName) =>
    Boolean((getData(state) || {})[testName]);

export const useABTestDataModule = () => {
    const dataModule = useDataModule((payload) =>
        authenticatedJsonFetch(`${AB_TESTS_ENDPOINT}${payload}/`),
    );

    return dataModule;
};
