import { useNavigate } from 'react-router-dom';

import { paths } from '@perpay-web/fintech/props/appPaths';
import { useMount } from '@perpay-web/hooks/useMount';

const PerpayPlusNotification = () => {
    const navigate = useNavigate();

    useMount(() => {
        navigate(paths.cardOnboarding.path);
    });
};

export default PerpayPlusNotification;
