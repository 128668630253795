import { useEffect, useRef, useState } from 'react';

import { RECAPTCHA_SCRIPT } from '@perpay-web/fintech/constants/urls';
import { useMountAndUnmount } from '@perpay-web/hooks/useMountAndUnmount';
import { usePrevious } from '@perpay-web/hooks/usePrevious';
import { getClassName } from '@perpay-web/utils/domUtils';
import { noop } from '@perpay-web/utils/noop';
import { loadThirdPartyScript } from '@perpay-web/utils/scriptUtils';

import styles from './Recaptcha.scss';

const RECAPTCHA_CLASSNAME = 'g-recaptcha';

function installRecaptchaScript() {
    return loadThirdPartyScript(RECAPTCHA_SCRIPT);
}

function initializeRecaptcha(element, sitekey) {
    if (window.STORYBOOK || process.env.JEST_WORKER_ID !== undefined) {
        return;
    }

    installRecaptchaScript();

    window.recaptchaPromise.then((grecaptcha) => {
        // https://developers.google.com/recaptcha/docs/display#render_param
        grecaptcha.render(element, {
            sitekey,
            'error-callback': 'onRecaptchaError',
            callback: 'onRecaptchaSuccess',
        });
    });
}

const Recaptcha = ({ onMount, isActive, className = '', sitekey }) => {
    const containerRef = useRef();
    const previousIsActive = usePrevious(isActive);
    const [error, setError] = useState('');

    useMountAndUnmount(() => {
        if (isActive) {
            initializeRecaptcha(containerRef.current, sitekey);
        }

        window.onRecaptchaError = () => {
            window.grecaptcha.reset();
            setError('Something went wrong, please try again.');
        };
        window.onRecaptchaSuccess = () => {
            setError('');
        };

        onMount();

        return () => {
            window.onRecaptchaError = () => window.grecaptcha.reset();
            window.onRecaptchaSuccess = noop;
        };
    });

    useEffect(() => {
        if (!containerRef.current) {
            return;
        }

        const hasChanged =
            typeof previousIsActive === 'boolean' &&
            previousIsActive !== isActive;
        if (hasChanged && isActive) {
            initializeRecaptcha(containerRef.current, sitekey);
        }
    }, [isActive, previousIsActive, sitekey]);

    return isActive ? (
        <>
            <div
                className={getClassName(RECAPTCHA_CLASSNAME, className)}
                ref={containerRef}
            />
            <span className={styles.error}>{error}</span>
        </>
    ) : null;
};

export default Recaptcha;
