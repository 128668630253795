import {
    PRODUCT_DETAILS,
    PRODUCT_DETAILS_AD,
    PRODUCT_DETAILS_ROOT,
} from '@perpay-web/constants/paths';
import * as PATHS from '@perpay-web/storefront/constants/paths';
import {
    MAGENTO_NEW_ARRIVALS_CATEGORIES,
    SHOP_BY_PRICE_AMOUNTS,
} from '@perpay-web/storefront/constants/prefilteredPages';

export const paths = {
    home: {
        path: PATHS.HOME,
        text: 'Home',
    },
    productDetails: {
        path: PRODUCT_DETAILS,
        text: 'Product Detail',
    },
    productDetailsAd: {
        path: PRODUCT_DETAILS_AD,
        text: 'Product Detail AD',
    },
    productDetailsRoot: {
        path: PRODUCT_DETAILS_ROOT,
        text: 'Product Detail',
    },
    search: {
        path: PATHS.SEARCH,
        text: 'Search',
        magentoPath: '/catalogsearch/result',
    },
    collections: {
        path: PATHS.COLLECTIONS,
        text: 'Collection',
    },
    giftsCollections: {
        path: PATHS.GIFTS_COLLECTIONS,
        text: 'Gifts For Everyone',
    },
    shopByPrice: {
        path: PATHS.SHOP_BY_PRICE,
        text: 'Shop By Price',
        magentoPath: '/new/shop-by-price/:underAmountSlug',
        magentoRewrite: (params) => {
            const { underAmountSlug } = params;
            if (
                underAmountSlug &&
                underAmountSlug.substring(0, 6) === 'under-' &&
                SHOP_BY_PRICE_AMOUNTS.includes(underAmountSlug.split('-')[1])
            ) {
                return PATHS.SHOP_BY_PRICE.replace(
                    ':underAmount',
                    underAmountSlug.split('-')[1],
                );
            }
            return PATHS.HOME;
        },
    },
    category: {
        path: PATHS.CATEGORY,
        text: 'Category',
    },
    categoryRoot: {
        path: PATHS.CATEGORY_ROOT,
        text: 'All Categories',
    },
    brands: {
        path: PATHS.BRANDS,
        text: 'Brands',
        magentoPath: '/new/featured-brands/:brandSlug',
    },
    shippingInformation: {
        path: PATHS.SHIPPING_INFORMATION,
        text: 'Shipping Information',
        magentoPath: '/checkout',
    },
    cart: {
        path: PATHS.CART_ROOT,
        text: 'Cart',
        magentoPath: '/checkout/cart',
    },
    dashboard: {
        path: PATHS.DASHBOARD,
        text: 'Dashboard',
    },
    orders: {
        path: PATHS.ORDERS,
        text: 'Orders',
    },
    login: {
        path: PATHS.LOGIN,
        text: 'Login',
    },
    signUp: {
        path: PATHS.SIGN_UP,
        text: 'Sign Up',
    },
    logout: {
        path: PATHS.LOGOUT,
        text: 'Logout',
    },
    unpublishedProducts: {
        path: PATHS.UNPUBLISHED_PRODUCTS,
        text: 'Unpublished Products',
    },
    newArrival: {
        path: PATHS.NEW_ARRIVALS,
        text: 'New Arrivals',
        magentoPath: '/new/just-in/:categorySlug',
        magentoRewrite: (params) => {
            const { categorySlug } = params;
            if (
                categorySlug &&
                MAGENTO_NEW_ARRIVALS_CATEGORIES.includes(categorySlug)
            ) {
                return PATHS.NEW_ARRIVALS.replace(
                    '*',
                    categorySlug.replace('new-', '').replace('-and', ''),
                );
            }
            return PATHS.NEW_ARRIVALS_ROOT;
        },
    },
    newArrivalRoot: {
        path: PATHS.NEW_ARRIVALS_ROOT,
        text: 'New Arrivals Root',
        magentoPath: '/new/just-in',
    },
};
