import { composeDataModules } from '@perpay-web/data-module/composeDataModules';
import { fetchUserInfoDataModule } from '@perpay-web/fintech/dataModules/fetchUserInfo';
import { partnerHostedCardApplicationDataModule } from '@perpay-web/fintech/dataModules/partnerHostedCardApplication';
import { useCardConsolidationABTestAdapter } from '@perpay-web/hooks/abTests/useCardConsolidationABTest';

const PartnerOnboardingDataModule = composeDataModules([
    partnerHostedCardApplicationDataModule,
    fetchUserInfoDataModule,
    useCardConsolidationABTestAdapter,
]);

const getRoot = (state) => state.dataModules.partnerOnboarding;

export const partnerOnboardingDataModule = PartnerOnboardingDataModule({
    getRoot,
    key: 'PARTNER_ONBOARDING',
});
