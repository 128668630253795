import ProgressBar from '@perpay-web/components/base/ProgressBar/ProgressBar';
import Inline from '@perpay-web/components/design-system/Inline/Inline';
import { getClassName } from '@perpay-web/utils/domUtils';

import styles from './FocusHeader.scss';

const FocusHeader = ({
    steps = null,
    currentStep = 0,
    title = '',
    leftButtonElement,
    rightButtonElement,
    className = '',
}) => (
    <Inline className={getClassName(styles.header__public, className)}>
        {leftButtonElement}
        {title ? (
            <span className={getClassName(styles.title)}>{title}</span>
        ) : null}
        {steps ? (
            <div className={styles['progress-bar']}>
                <ProgressBar steps={steps} currentStep={currentStep} />
            </div>
        ) : null}
        {rightButtonElement}
    </Inline>
);

export default FocusHeader;
