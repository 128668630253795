import { ofType } from 'redux-observable';
import { switchMap, mergeMap } from 'rxjs/operators';

import {
    fetchCardStatements as fetchCardStatementsAction,
    replaceCardStatements,
    setCardStatementsError,
    downloadCardStatement as downloadCardStatementAction,
    cardStatementsDownloadSuccess,
    cardStatementsDownloadError,
} from '@perpay-web/fintech/actions/entities/cardStatements';
import { routeToJwtEnabledLocation } from '@perpay-web/fintech/actions/router';
import {
    CARD_STATEMENTS_ENDPOINT,
    CARD_STATEMENTS_DOWNLOAD_ENDPOINT,
    CARD_STATEMENTS_APP_URL,
} from '@perpay-web/fintech/constants/urls';
import { pipeResponse } from '@perpay-web/fintech/utils/operators';
import { handleErrorMessageWithFallback } from '@perpay-web/observable/operators/handleErrorMessageWithFallback';
import { arrayBufferToJson } from '@perpay-web/utils/objectUtils';
import { isAppBrowserAgent } from '@perpay-web/utils/userAgentUtils';

export const fetchCardStatements = (action$, state$, { get }) =>
    action$.pipe(
        ofType(fetchCardStatementsAction().type),
        switchMap(() => get(CARD_STATEMENTS_ENDPOINT)),

        mergeMap((results) => [
            replaceCardStatements({ all: results.response }),
        ]),
        handleErrorMessageWithFallback((error) => [
            setCardStatementsError(error),
        ]),
    );

export const downloadCardStatement = (action$, state$, { get }) =>
    action$.pipe(
        ofType(downloadCardStatementAction().type),
        switchMap((action) => {
            const { uuid } = action.payload;

            const url = CARD_STATEMENTS_DOWNLOAD_ENDPOINT.replace(
                ':uuid',
                uuid,
            );

            if (isAppBrowserAgent()) {
                const viewUrl = CARD_STATEMENTS_APP_URL.replace(':uuid', uuid);
                return [
                    routeToJwtEnabledLocation({
                        url: viewUrl,
                        tokenParamKey: 'token',
                    }),
                    cardStatementsDownloadSuccess(), // allow the request lifecycle to complete
                ];
            }

            return get(url, { responseType: 'arraybuffer' });
        }),
        pipeResponse(
            mergeMap((res) => {
                const buffer = res.response;
                const blob = new window.Blob([buffer], {
                    type: 'application/pdf',
                });
                const url = window.URL.createObjectURL(blob);
                const contentDisposition =
                    res.responseHeaders['content-disposition'];
                // content-disposition has multiple parameters - we just want to extract file name without any carriage returns
                const filename = contentDisposition
                    .split('filename=')[1]
                    .replace('\r', '');

                // Trigger the download side-effect
                // NOTE: This mayyy not work on iOS, since iOS has some tricky undocumented behaviors
                // around async navigating/downloading if the initiating click was too long ago or
                // too far away in the call stack.
                const a = document.createElement('a');
                a.setAttribute('target', '_blank');
                a.setAttribute('download', '');
                a.href = url;
                a.download = filename;
                a.click();

                return [cardStatementsDownloadSuccess()];
            }),
        ),
        handleErrorMessageWithFallback((errorArrayBuffer) => {
            const errorJson = arrayBufferToJson(errorArrayBuffer);
            return [cardStatementsDownloadError(errorJson)];
        }),
    );
