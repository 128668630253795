import { useState } from 'react';
import { useCallback } from 'react';

import ToggleSwitch from '@perpay-web/components/base/ToggleSwitch/ToggleSwitch';
import Inline from '@perpay-web/components/design-system/Inline/Inline';
import {
    BIOMETRIC_TYPE_NONE,
    BIOMETRIC_TYPE_FACE_ID,
    BIOMETRIC_TYPE_TOUCH_ID,
    BIOMETRIC_TYPE_ANDROID_BIOMETRICS_FINGERPRINT,
    BIOMETRIC_TYPE_ANDROID_BIOMETRICS_FACE,
    BIOMETRIC_TYPE_ANDROID_BIOMETRICS_OTHER,
} from '@perpay-web/native-client/constants/passkey';
import { getBiometricsLabelFromType } from '@perpay-web/native-client/selectors/passkey';
import { getClassName } from '@perpay-web/utils/domUtils';
import { noop } from '@perpay-web/utils/noop';

import styles from './LoginWithBiometrics.scss';

const typeToIconClassNameMapping = {
    [BIOMETRIC_TYPE_NONE]: 'icon--none',
    [BIOMETRIC_TYPE_FACE_ID]: 'icon--face-id',
    [BIOMETRIC_TYPE_TOUCH_ID]: 'icon--touch-id',
    [BIOMETRIC_TYPE_ANDROID_BIOMETRICS_FINGERPRINT]:
        'icon--android-biometrics-fingerprint',
    [BIOMETRIC_TYPE_ANDROID_BIOMETRICS_FACE]: 'icon--android-biometrics-face',
    [BIOMETRIC_TYPE_ANDROID_BIOMETRICS_OTHER]:
        'icon--android-biometrics-fingerprint',
};

const LoginWithBiometrics = ({
    isLoading = false,
    biometricsType = BIOMETRIC_TYPE_NONE,
    className = '',
    isBiometricsRegistered = false,
    onToggle = noop,
}) => {
    const [checked, setChecked] = useState(isBiometricsRegistered);

    const onChange = useCallback(
        (e) => {
            const { checked: value } = e.target;
            setChecked(value);
            onToggle(value);
        },
        [onToggle],
    );

    return (
        <Inline
            className={getClassName(styles['login-with-biometrics'], className)}
        >
            <span className={styles.label}>
                {biometricsType !== BIOMETRIC_TYPE_NONE ? (
                    <span
                        className={getClassName(
                            styles.icon,
                            styles[typeToIconClassNameMapping[biometricsType]],
                        )}
                    />
                ) : null}
                Enable {getBiometricsLabelFromType(biometricsType)} for login
            </span>{' '}
            <ToggleSwitch
                id='face_id'
                disabled={isLoading}
                onChange={onChange}
                checked={checked}
            />
        </Inline>
    );
};

export default LoginWithBiometrics;
