import { createAction } from '@reduxjs/toolkit';
import { useState } from 'react';

import { useAnimatedModal } from '@perpay-web/components/base/Modal/useAnimatedModal';
import { useModal } from '@perpay-web/components/base/Modal/useModal';
import { useActionObservableCallback } from '@perpay-web/hooks/useActionObservableCallback';
import { hookAction$ } from '@perpay-web/services/singletons';
import { createContextProvider } from '@perpay-web/utils/componentUtils';

export const partnerOnboardingExistingUserModalSetIsModalOpen = createAction(
    'PARTNER_ONBOARDING_EXISTING_USER_MODAL::SET_IS_MODAL_OPEN',
);

export const partnerOnboardingExistingUserModalSetExistingUserFirstName =
    createAction(
        'PARTNER_ONBOARDING_EXISTING_USER_MODAL::SET_EXISTING_USER_FIRST_NAME',
    );

export const partnerOnboardingExistingUserModalSetMatchMethod = createAction(
    'PARTNER_ONBOARDING_EXISTING_USER_MODAL::SET_MATCH_METHOD',
);

const usePartnerOnboardingExistingUserModal = () => {
    const { isModalOpen, setIsModalOpen } = useModal();
    const {
        isAnimatedModalOpen,
        setIsAnimatedModalOpen,
        animatedModalClassName,
    } = useAnimatedModal({ isModalOpen, setIsModalOpen });

    const [existingUserFirstName, setExistingUserFirstName] = useState('');
    const [matchMethod, setMatchMethod] = useState('');

    return {
        isAnimatedModalOpen,
        setIsAnimatedModalOpen,
        animatedModalClassName,
        existingUserFirstName,
        setExistingUserFirstName,
        matchMethod,
        setMatchMethod,
    };
};

const providerFactory = (Context) => {
    const Provider = ({ children, storyOverrideValue }) => {
        const hook = usePartnerOnboardingExistingUserModal();

        useActionObservableCallback(
            hookAction$,
            partnerOnboardingExistingUserModalSetIsModalOpen().type,
            (value) => hook.setIsAnimatedModalOpen(value),
        );

        useActionObservableCallback(
            hookAction$,
            partnerOnboardingExistingUserModalSetExistingUserFirstName().type,
            (value) => hook.setExistingUserFirstName(value),
        );

        useActionObservableCallback(
            hookAction$,
            partnerOnboardingExistingUserModalSetMatchMethod().type,
            (value) => hook.setMatchMethod(value),
        );

        return (
            <Context.Provider value={storyOverrideValue || hook}>
                {children}
            </Context.Provider>
        );
    };

    return Provider;
};

export const [
    PartnerOnboardingExistingUserModalProvider,
    usePartnerOnboardingExistingUserModalContext,
] = createContextProvider(providerFactory);
