import { HTTPMethod } from '@perpay-web/constants/httpMethods';
import { useDataModule } from '@perpay-web/data-module/useDataModule';
import { useReduxDataModuleHookConnection } from '@perpay-web/data-module/useReduxDataModuleHookConnection';
import {
    updateUserInfoContext,
    updateUserInfoContextError,
    updateUserInfoContextReset,
    updateUserInfoContextSuccess,
} from '@perpay-web/fintech/actions/entities/userInfo';
import { USERS_ENDPOINT } from '@perpay-web/fintech/constants/urls';
import { authenticatedJsonFetch } from '@perpay-web/fintech/utils/requestHandlers';
import { hookAction$ } from '@perpay-web/services/singletons';
import { createContextProvider } from '@perpay-web/utils/componentUtils';

const useUpdateUserInfoDataModule = () => {
    const dataModule = useDataModule((payload) =>
        authenticatedJsonFetch(
            `${USERS_ENDPOINT}me/`,
            payload,
            HTTPMethod.patch,
        ),
    );

    useReduxDataModuleHookConnection({
        dataModule,
        hookAction$,
        request: updateUserInfoContext,
        error: updateUserInfoContextError,
        success: updateUserInfoContextSuccess,
        reset: updateUserInfoContextReset,
    });

    return dataModule;
};

const providerFactory = (Context) => {
    const Provider = ({ children }) => {
        const dataModule = useUpdateUserInfoDataModule();

        return (
            <Context.Provider value={dataModule}>{children}</Context.Provider>
        );
    };

    return Provider;
};

export const [UseUpdateUserInfoDataModuleProvider, useUpdateUserInfoContext] =
    createContextProvider(providerFactory);
