import BottomSheet from '@perpay-web/components/base/BottomSheet/BottomSheet';
import CustomButton from '@perpay-web/components/base/CustomButton/CustomButton';
import { useAnimatedModal } from '@perpay-web/components/base/Modal/useAnimatedModal';
import { useModal } from '@perpay-web/components/base/Modal/useModal';
import cardWithConfettiAnimation from '@perpay-web/fintech/assets/images/cardWithConfettiAnimation.json';
import CardCta from '@perpay-web/fintech/components/base/CardCta/CardCta';
import Lottie from '@perpay-web/fintech/components/base/LottieWrapper/LottieWrapper';
import { useMount } from '@perpay-web/hooks/useMount';
import { getClassName } from '@perpay-web/utils/domUtils';
import { formatCurrency } from '@perpay-web/utils/stringUtils';

import styles from './CardCreditLimitIncreaseNotification.scss';

const CardCreditLimitIncreaseNotification = ({
    onMount,
    newCreditLimitAmount,
}) => {
    useMount(() => onMount());

    const { isModalOpen, setIsModalOpen } = useModal(true);
    const {
        isAnimatedModalOpen,
        setIsAnimatedModalOpen,
        animatedModalClassName,
    } = useAnimatedModal({ isModalOpen, setIsModalOpen });

    const onModalClose = () => setIsAnimatedModalOpen(false);

    return (
        <div>
            {isAnimatedModalOpen ? (
                <BottomSheet
                    onClose={onModalClose}
                    className={getClassName(
                        styles.modal,
                        animatedModalClassName,
                    )}
                >
                    <div className={styles['img-container']}>
                        <Lottie
                            options={{
                                animationData: cardWithConfettiAnimation,
                                loop: true,
                                autoplay: true,
                            }}
                            ariaRole='presentation'
                            alt='Animated illustration of a Perpay Card and confetti'
                        />
                    </div>
                    <div className={styles['copy-container']}>
                        <div className={styles.header}>
                            Your credit limit increased!
                        </div>
                        <div className={styles.copy}>
                            We received your recent direct deposit. Your credit
                            limit is now
                            <strong>
                                {` ${formatCurrency(newCreditLimitAmount, false, false)}! `}
                            </strong>
                        </div>
                    </div>
                    <div className={styles['done-button-container']}>
                        <CardCta
                            Component={CustomButton}
                            className={styles['done-button']}
                            onClick={onModalClose}
                        >
                            Done
                        </CardCta>
                    </div>
                </BottomSheet>
            ) : null}
        </div>
    );
};

export default CardCreditLimitIncreaseNotification;
