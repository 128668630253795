import { useDispatch, useSelector } from 'react-redux';

import { analyticsHeaderOnClick } from '@perpay-web/fintech/actions/analytics/header';
import { analyticsPerpayPlusClickHamburger } from '@perpay-web/fintech/actions/analytics/perpayPlus';
import { fetchEnrolledFeatures } from '@perpay-web/fintech/actions/entities/featureEnrollments';
import { fetchFeatures } from '@perpay-web/fintech/actions/entities/features';
import Header from '@perpay-web/fintech/components/base/Header/Header';
import { CLOSED } from '@perpay-web/fintech/constants/cardAccountStatuses';
import { getHasPerpayPlus as getHasPerpayPlusFromDataModule } from '@perpay-web/fintech/dataModules/crud/featureEnrollmentsForPerpayPlus';
import {
    fetchCardAccountsDataModule,
    getCardAccountStatus,
} from '@perpay-web/fintech/dataModules/fetchCardAccounts';
import {
    getIsAuthenticated,
    getJWTData,
} from '@perpay-web/fintech/selectors/authentication';
import { getHasPerpayPlus } from '@perpay-web/fintech/selectors/entities/featureEnrollments';
import { getIsPerpayPlusEligibleAndNotOptedIn } from '@perpay-web/fintech/selectors/entities/features';
import { launchSupportTool } from '@perpay-web/services/supportTool';

const BorrowerHeaderContainer = () => {
    const dispatch = useDispatch();
    const isAuthenticated = useSelector(getIsAuthenticated);
    const userData = useSelector(getJWTData);
    const isPerpayPlusEligible = useSelector(
        getIsPerpayPlusEligibleAndNotOptedIn,
    );
    const hasPerpayPlus = useSelector(getHasPerpayPlus);
    const hasPerpayPlusFromDataModule = useSelector(
        getHasPerpayPlusFromDataModule,
    );
    const cardAccountStatus = useSelector(getCardAccountStatus);

    const hasClosedCardAccount = cardAccountStatus === CLOSED;

    const fetchDataDependencies = () => {
        dispatch(fetchFeatures());
        dispatch(fetchEnrolledFeatures());
        dispatch(fetchCardAccountsDataModule.dataRequest());
    };

    const onClickPerpayPlus = () =>
        dispatch(analyticsPerpayPlusClickHamburger());

    const launchSupportToolHandler = () => dispatch(launchSupportTool());

    const analyticsHeaderOnClickDashboard = () =>
        dispatch(analyticsHeaderOnClick({ type: 'dashboard' }));

    const analyticsHeaderOnClickShop = () =>
        dispatch(analyticsHeaderOnClick({ type: 'shop' }));

    const analyticsHeaderOnClickHamburger = () =>
        dispatch(analyticsHeaderOnClick({ type: 'hamburger' }));

    return (
        <Header
            analyticsHeaderOnClickDashboard={analyticsHeaderOnClickDashboard}
            analyticsHeaderOnClickHamburger={analyticsHeaderOnClickHamburger}
            analyticsHeaderOnClickShop={analyticsHeaderOnClickShop}
            fetchDataDependencies={fetchDataDependencies}
            hasClosedCardAccount={hasClosedCardAccount}
            hasPerpayPlus={hasPerpayPlus || hasPerpayPlusFromDataModule}
            isPerpayPlusEligible={isPerpayPlusEligible}
            launchSupportToolHandler={launchSupportToolHandler}
            onClickPerpayPlus={onClickPerpayPlus}
            isAuthenticated={isAuthenticated}
            userData={userData}
        />
    );
};

export default BorrowerHeaderContainer;
