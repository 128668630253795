export const getRoot = (state) => state.ui.partnerOnboarding;

export const isInPartnerOnboardingFlow = (state) =>
    getRoot(state).inPartnerOnboardingFlow;

export const isMagicLinkRedirect = (state) =>
    getRoot(state).isMagicLinkRedirect;

export const getPartnerCode = (state) => getRoot(state).partnerCode;

export const getOfferAmount = (state) => getRoot(state).offerAmount;

export const getPartnerSignupRequirements = (state) =>
    getRoot(state).signupRequirements ? getRoot(state).signupRequirements : [];

export const isPremiumCardApplicant = (state) =>
    getRoot(state).premiumCardApplicant;
