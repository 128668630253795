import { HTTPMethod } from '@perpay-web/constants/httpMethods';
import {
    iterableTrackPushOpen,
    pageView,
} from '@perpay-web/fintech/beacon/eventDefinitions/DjangoTracking';
import { ANALYTICS_ITERABLE_PUSH_NOTIFICATION_OPEN } from '@perpay-web/fintech/constants/actionTypes';
import { analyticsLocationChange } from '@perpay-web/hooks/useTrackLocationChange';

const djangoTrackingEventsMap = {
    [analyticsLocationChange().type]: pageView((action) => {
        const searchString = action.payload.location.search;
        // connected-react-router sets the search param as `?foo=bar`
        // but our api expects it without the leading `?`
        const queryString = searchString.startsWith('?')
            ? searchString.slice(1)
            : searchString;
        // method here is the method made to request the particular page
        const method = HTTPMethod.get;
        return {
            path: action.payload.location.pathname,
            method,
            queryString,
        };
    }),
    [ANALYTICS_ITERABLE_PUSH_NOTIFICATION_OPEN]: iterableTrackPushOpen(
        (action) => ({
            email: action.payload.email,
            campaignId: action.payload.campaignId,
            messageId: action.payload.messageId,
            templateId: action.payload.templateId,
        }),
    ),
};

export default djangoTrackingEventsMap;
