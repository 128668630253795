import { createDataModule } from '@perpay-web/data-module/createDataModule';
import { createDataModuleActions } from '@perpay-web/data-module/createDataModuleActions';

const dataModuleFetchActions = createDataModuleActions('FETCH_AB_TESTS');
export const fetchABTests = dataModuleFetchActions.dataRequest;
export const fetchABTestsSuccess = dataModuleFetchActions.dataSuccess;
export const fetchABTestsError = dataModuleFetchActions.dataError;
const fetchABTestsReset = dataModuleFetchActions.dataReset;

const FetchABTestsDataModule = createDataModule({
    dataRequest: fetchABTests,
    dataSuccess: fetchABTestsSuccess,
    dataError: fetchABTestsError,
    dataReset: fetchABTestsReset,
});

const getRoot = (state) => state.dataModules.fetchABTests;
const initialValue = {};

export const fetchABTestsDataModule = FetchABTestsDataModule({
    getRoot,
    initialValue,
});

export const getABTests = (state) => fetchABTestsDataModule.getData(state);

const getABTestByName = (state, testName) => getABTests(state)[testName] || {};

export const getABTestEnabled = (state, testName) => {
    const abTest = getABTestByName(state, testName);
    return abTest.enabled || false;
};
