import { authentication } from '@perpay-web/authentication/singleton';
import { useDataModule } from '@perpay-web/data-module/useDataModule';
import { useReduxDataModuleHookConnection } from '@perpay-web/data-module/useReduxDataModuleHookConnection';
import {
    passkeyRegisterComplete,
    passkeyRegisterCompleteSuccess,
    passkeyRegisterCompleteError,
    passkeyRegisterCompleteReset,
} from '@perpay-web/fintech/actions/passkey';
import { REACT_NATIVE_PASSKEY_AUTHENTICATION_IS_LAUNCHED } from '@perpay-web/fintech/constants/flags';
import { hookAction$ } from '@perpay-web/services/singletons';
import { createContextProvider } from '@perpay-web/utils/componentUtils';

const usePasskeyRegisterCompleteDataModule =
    REACT_NATIVE_PASSKEY_AUTHENTICATION_IS_LAUNCHED
        ? () => {
              const dataModule = useDataModule((payload) =>
                  authentication.passkeyRegisterComplete(payload),
              );

              useReduxDataModuleHookConnection({
                  dataModule,
                  hookAction$,
                  request: passkeyRegisterComplete,
                  success: passkeyRegisterCompleteSuccess,
                  error: passkeyRegisterCompleteError,
                  reset: passkeyRegisterCompleteReset,
              });

              return dataModule;
          }
        : () => ({}); // stub that allows destructuring the value

const providerFactory = (Context) => {
    const Provider = ({ children }) => {
        const dataModule = usePasskeyRegisterCompleteDataModule();

        return (
            <Context.Provider value={dataModule}>{children}</Context.Provider>
        );
    };

    return Provider;
};

export const [UsePasskeyRegisterCompleteDataModuleProvider] =
    createContextProvider(providerFactory);
