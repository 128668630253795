import { useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';

import navBarCurve from '@perpay-web/fintech/assets/images/Subtract.svg';
import {
    HOME_BUTTON,
    SHOP_BUTTON,
    SUPPORT_BUTTON,
    CARD_BUTTON,
    MARKETPLACE_BUTTON,
} from '@perpay-web/fintech/components/base/BottomNav/BottomNavButtons';
import { useShopPath } from '@perpay-web/fintech/hooks/useShopPath';
import { paths } from '@perpay-web/fintech/props/appPaths';
import { useMount } from '@perpay-web/hooks/useMount';
import { getClassName } from '@perpay-web/utils/domUtils';

import styles from './BottomNav.scss';

const ButtonSet = [
    HOME_BUTTON,
    CARD_BUTTON,
    SHOP_BUTTON,
    MARKETPLACE_BUTTON,
    SUPPORT_BUTTON,
];

const BottomNav = ({
    cardButtonRoute,
    launchSupportToolHandler,
    analyticsBottomNavOnClickHome,
    analyticsBottomNavOnClickCard,
    analyticsBottomNavOnClickShop,
    analyticsBottomNavOnClickMarketplace,
    analyticsBottomNavOnClickSupport,
    onMount,
}) => {
    const { shopPath, lastShopPath } = useShopPath();
    const location = useLocation();

    const isActiveButton = (name) =>
        location.pathname.startsWith(`/${name}/`) ||
        (name === SHOP_BUTTON && location.pathname.startsWith(`/${name}`)) ||
        (name === HOME_BUTTON && location.pathname.startsWith('/dashboard'));

    const activeButton = ButtonSet.find(isActiveButton) || '';

    const shopHref = useMemo(() => {
        if (location.pathname.startsWith('/shop')) {
            return shopPath;
        }
        return lastShopPath;
    }, [shopPath, lastShopPath, location.pathname]);

    useMount(() => onMount());

    return (
        <div className={styles.footer}>
            <Link
                className={getClassName(
                    styles.nav__block,
                    activeButton === HOME_BUTTON &&
                        styles['nav__block--active'],
                )}
                to={paths.dashboard.path}
                onClick={analyticsBottomNavOnClickHome}
            >
                <div
                    className={getClassName(
                        styles.nav__icon,
                        styles.nav__home__icon,
                    )}
                />
                <div className={styles.nav__label}>Home</div>
            </Link>

            <Link
                className={getClassName(
                    styles.nav__block,
                    activeButton === CARD_BUTTON &&
                        styles['nav__block--active'],
                )}
                to={cardButtonRoute}
                onClick={analyticsBottomNavOnClickCard}
            >
                <div
                    className={getClassName(
                        styles.nav__icon,
                        styles.nav__card__icon,
                    )}
                />
                <div className={styles.nav__label}>Card</div>
            </Link>

            <div className={styles.floating__btn__wrapper}>
                <img
                    alt='nav bar curve'
                    src={navBarCurve}
                    className={styles.nav__subtract}
                />

                <Link
                    className={getClassName(
                        styles.nav__block,
                        activeButton === SHOP_BUTTON &&
                            styles['nav__block--active'],
                        styles.nav__floating__button,
                    )}
                    to={shopHref}
                    onClick={analyticsBottomNavOnClickShop}
                >
                    <div
                        className={getClassName(
                            styles.nav__icon,
                            styles.nav__shop__icon,
                        )}
                    />
                    <div
                        className={getClassName(
                            styles.nav__label,
                            styles.nav__shop__label,
                        )}
                    >
                        Shop
                    </div>
                </Link>
            </div>

            <Link
                className={getClassName(
                    styles.nav__block,
                    activeButton === MARKETPLACE_BUTTON &&
                        styles['nav__block--active'],
                )}
                to={paths.marketplaceDetails.path}
                onClick={analyticsBottomNavOnClickMarketplace}
            >
                <div
                    className={getClassName(
                        styles.nav__icon,
                        styles.nav__marketplace__icon,
                    )}
                />
                <div className={styles.nav__label}>Marketplace</div>
            </Link>

            <button
                type='button'
                className={getClassName(
                    styles.nav__block,
                    activeButton === SUPPORT_BUTTON &&
                        styles['nav__block--active'],
                )}
                onClick={() => {
                    launchSupportToolHandler();
                    analyticsBottomNavOnClickSupport();
                }}
            >
                <div
                    className={getClassName(
                        styles.nav__icon,
                        styles.nav__support__icon,
                    )}
                />
                <div className={styles.nav__label}>Support</div>
            </button>
        </div>
    );
};

export default BottomNav;
