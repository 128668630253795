import { lazy } from 'react';

import { withBottomNav } from '@perpay-web/components/higher-order/WithBottomNav/WithBottomNav';
import { withLoader } from '@perpay-web/components/higher-order/WithLoader/WithLoader';
import { withRedirectIfAuthenticated } from '@perpay-web/components/higher-order/WithRedirectIfAuthenticated/WithRedirectIfAuthenticated';
import { paths } from '@perpay-web/fintech/props/appPaths';
import { importReferralLandingContainer } from '@perpay-web/fintech/utils/imports/importReferralLandingContainer';
import { compose } from '@perpay-web/utils/functionalUtils';

const EmptyBottomNav = () => null;
const ReferralLandingRoute = compose(
    withBottomNav(EmptyBottomNav),
    withRedirectIfAuthenticated,
)(withLoader(lazy(importReferralLandingContainer)));

/**
 * @type {import('react-router-dom').DataRouteObject[]}
 */
export const unauthenticatedRoutes = [
    { path: paths.referral.path, element: <ReferralLandingRoute /> },
];
