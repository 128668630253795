import { combineReducers } from '@reduxjs/toolkit';

import accountDeactivationModalReducer from './accountDeactivationModal';
import accountSummaryReducer from './accountSummary';
import adsReducer from './ads';
import banksReducer from './banks';
import cancelOrderModalReducer from './cancelOrderModal';
import cardAccountApplicationsReducer from './cardAccountApplications';
import cardActivationReducer from './cardActivation';
import cardCreateAutoPayReducer from './cardCreateAutoPay';
import cardOnboardingReducer from './cardOnboarding';
import cardOnboardingMVP2Reducer from './cardOnboardingMVP2';
import cardOnboardingMetalReducer from './cardOnboardingMetal';
import cardSettingsReducer from './cardSettings';
import cardStatementsReducer from './cardStatements';
import checkoutReducer from './checkout';
import companiesReducer from './companies';
import creditMonitoringReducer from './creditMonitoring';
import dashboardReducer from './dashboard';
import editUserInfoReducer from './editUserInfo';
import employmentReducer from './employment';
import externalCardsReducer from './externalCards';
import identityVerificationReducer from './identityVerification';
import instructionsReducer from './instructions';
import jobReducer from './jobs';
import orderReviewReducer from './orderReview';
import ordersReducer from './orders';
import partnerOnboardingReducer from './partnerOnboarding';
import paymentPortalReducer from './paymentPortal';
import paystubReducer from './paystub';
import perpayPlusReducer from './perpayPlus';
import perpaySplitSetUpPaymentsReducer from './perpaySplitSetUpPayments';
import pinwheelReducer from './pinwheel';
import pinwheelSplitReducer from './pinwheelSplitSetUpPayments';
import referralsReducer from './referrals';
import signupReducer from './signup';
import surveyReducer from './survey';
import surveyResponseReducer from './surveyResponses';
import updateDirectDepositReducer from './updateDirectDeposit';
import verifyBankFormSelectReducer from './verifyBankFormSelect';
import withdrawalReducer from './withdrawal';
import withdrawalBalanceReducer from './withdrawalBalance';

const uiReducer = combineReducers({
    ads: adsReducer,
    banks: banksReducer,
    externalCards: externalCardsReducer,
    checkout: checkoutReducer,
    companies: companiesReducer,
    dashboard: dashboardReducer,
    instructions: instructionsReducer,
    orders: ordersReducer,
    signup: signupReducer,
    cancelOrderModal: cancelOrderModalReducer,
    accountDeactivationModal: accountDeactivationModalReducer,
    accountSummary: accountSummaryReducer,
    paymentPortal: paymentPortalReducer,
    survey: surveyReducer,
    surveyResponse: surveyResponseReducer,
    pinwheel: pinwheelReducer,
    pinwheelSplit: pinwheelSplitReducer,
    referrals: referralsReducer,
    identityVerification: identityVerificationReducer,
    paystub: paystubReducer,
    orderReview: orderReviewReducer,
    perpayPlus: perpayPlusReducer,
    creditMonitoring: creditMonitoringReducer,
    perpaySplitSetUpPayments: perpaySplitSetUpPaymentsReducer,
    withdrawalBalance: withdrawalBalanceReducer,
    verifyBankFormSelect: verifyBankFormSelectReducer,
    withdrawal: withdrawalReducer,
    employment: employmentReducer,
    jobs: jobReducer,
    cardOnboarding: cardOnboardingReducer,
    cardActivation: cardActivationReducer,
    cardAccountApplication: cardAccountApplicationsReducer,
    cardSettings: cardSettingsReducer,
    cardStatements: cardStatementsReducer,
    editUserInfo: editUserInfoReducer,
    cardCreateAutoPay: cardCreateAutoPayReducer,
    updateDirectDeposit: updateDirectDepositReducer,
    cardOnboardingMVP2: cardOnboardingMVP2Reducer,
    cardOnboardingMetal: cardOnboardingMetalReducer,
    partnerOnboarding: partnerOnboardingReducer,
});

export default uiReducer;
