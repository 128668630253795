import { SEGMENT_FACEBOOK_PIXEL_ID } from '@perpay-web/fintech/constants/serviceCredentials';
import { dynamicMiddleware } from '@perpay-web/fintech/settings/singletons';

import { importFacebook } from './imports/importFacebook';
import { importSegment } from './imports/importSegment';

// TODO create a service to make sure it's installed only once
let isSegmentInstalled = false;

export function installSegmentMiddleware() {
    if (isSegmentInstalled) {
        return Promise.resolve();
    }

    isSegmentInstalled = true;
    // Asynchronously load the Segment middleware and its dependencies.
    return importSegment().then(({ getSegmentMiddleware }) => {
        dynamicMiddleware.addMiddleware(getSegmentMiddleware());

        // Create a wrapper around the fbq callMethod so that Facebook Pixel events from Segment
        // are ONLY sent to the Pixel ID created by Segment. Without the wrapper, events are sent
        // to all pixels on the page.
        if (!window.fbq) {
            return;
        }
        const oldCallMethod = window.fbq.callMethod;
        window.fbq.callMethod = function callMethod(eventName, ...rest) {
            if (!oldCallMethod) {
                return null;
            }

            if (eventName === 'track' || eventName === 'trackCustom') {
                const name =
                    eventName === 'track' ? 'trackSingle' : 'trackSingleCustom';
                return oldCallMethod.call(
                    this,
                    name,
                    SEGMENT_FACEBOOK_PIXEL_ID,
                    ...rest,
                );
            }
            return oldCallMethod.call(this, eventName, ...rest);
        };
    });
}

let isFacebookInstalled = false;

export function installFacebookMiddleware() {
    if (isFacebookInstalled) {
        return Promise.resolve();
    }
    isFacebookInstalled = true;

    /* eslint-disable */
    !(function (f, b, e, v, n, t, s) {
        if (f.fbq) return;
        n = f.fbq = function () {
            n.callMethod
                ? n.callMethod.apply(n, arguments)
                : n.queue.push(arguments);
        };
        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = '2.0';
        n.queue = [];
        t = b.createElement(e);
        t.async = !0;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s);
    })(
        window,
        document,
        'script',
        'https://connect.facebook.net/en_US/fbevents.js',
    );
    /* eslint-enable */

    // Asynchronously load the Facebook middleware and its dependencies.
    return importFacebook().then(({ getFacebookPixelMiddleware }) => {
        window.fbq.disablePushState = true;
        dynamicMiddleware.addMiddleware(getFacebookPixelMiddleware());
    });
}
