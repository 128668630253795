import { createCrudDataModule } from '@perpay-web/data-module/createCrudDataModule';
import {
    OPTED_IN,
    CANCELED,
} from '@perpay-web/fintech/constants/featureEnrollmentStatuses';
import {
    LEGACY_PERPAY_PLUS,
    PERPAY_PLUS,
} from '@perpay-web/fintech/constants/featureTypes';
import { createFeatureEnrollmentForPerpayPlusDataModule } from '@perpay-web/fintech/dataModules/createFeatureEnrollmentForPerpayPlus';
import { fetchFeatureEnrollmentsForPerpayPlusDataModule } from '@perpay-web/fintech/dataModules/fetchFeatureEnrollmentsForPerpayPlus';

const FeatureEnrollmentsForPerpayPlusCrudDataModule = createCrudDataModule({
    create: createFeatureEnrollmentForPerpayPlusDataModule,
    read: fetchFeatureEnrollmentsForPerpayPlusDataModule,
});

const getRoot = (state) => state.dataModules.featureEnrollmentsForPerpayPlus;
export const featureEnrollmentsForPerpayPlusCrudDataModule =
    FeatureEnrollmentsForPerpayPlusCrudDataModule({ getRoot });

const getFeatureEnrollments = (state) => {
    const featureEnrollmentEntities =
        featureEnrollmentsForPerpayPlusCrudDataModule.getData(state);
    return Object.values(featureEnrollmentEntities);
};

const getPerpayPlusEnrollments = (state) => {
    const featureEnrollments = getFeatureEnrollments(state);
    const perpayPlusEnrollments = featureEnrollments.filter(
        (enrollment) =>
            enrollment.feature === PERPAY_PLUS ||
            enrollment.feature === LEGACY_PERPAY_PLUS,
    );
    return perpayPlusEnrollments;
};

const getPerpayPlusEnrollmentStatus = (state) => {
    const perpayPlusEnrollments = getPerpayPlusEnrollments(state);
    // no more than 1 PP+ enrollment expected
    const perpayPlusEnrollment = perpayPlusEnrollments[0];
    return perpayPlusEnrollment ? perpayPlusEnrollment.status : null;
};

export const getHasPerpayPlus = (state) => {
    const status = getPerpayPlusEnrollmentStatus(state);
    return (status && status !== CANCELED) || false;
};

export const getIsPerpayPlusOptedIn = (state) => {
    const status = getPerpayPlusEnrollmentStatus(state);
    return (status && status === OPTED_IN) || false;
};
