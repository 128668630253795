import {
    BACKEND_CREATE_DEDUCTION,
    STORE_REPLACE_DEDUCTION,
    STORE_REPLACE_DEDUCTION_ERROR,
    STORE_DEDUCTION_CLEAR_ERRORS,
    STORE_UPDATE_DIRECT_DEPOSIT_REPLACE_AMOUNT_TO_CHARGE,
    STORE_UPDATE_DIRECT_DEPOSIT_REPLACE_AMOUNT_TYPE,
    STORE_UPDATE_DIRECT_DEPOSIT_REPLACE_COMPANY_NAME,
} from '@perpay-web/fintech/constants/actionTypes';
import { MINIMUM_REQUIRED } from '@perpay-web/fintech/constants/selectAmountTypes';
import { UPDATE_DIRECT_DEPOSIT_STEPS } from '@perpay-web/fintech/constants/steps/updateDirectDepositSteps';
import { withReduxStepsReducer } from '@perpay-web/hooks/useReduxSteps';

const initialState = {
    amountType: MINIMUM_REQUIRED,
    amountToCharge: 0,
    fetchingPinwheelToken: false,
    pinwheelErrors: {},
    selfOnboardingCompanyName: '',
};

const updateDirectDepositReducer = (state = initialState, action = {}) => {
    const { type, payload } = action;

    switch (type) {
        case BACKEND_CREATE_DEDUCTION:
            return {
                ...state,
                fetchingPinwheelToken: true,
                pinwheelErrors: {},
            };
        case STORE_REPLACE_DEDUCTION:
            return { ...state, fetchingPinwheelToken: false };
        case STORE_REPLACE_DEDUCTION_ERROR:
            return {
                ...state,
                fetchingPinwheelToken: false,
                pinwheelErrors: payload,
            };
        case STORE_DEDUCTION_CLEAR_ERRORS:
            return {
                ...state,
                pinwheelErrors: {},
            };
        case STORE_UPDATE_DIRECT_DEPOSIT_REPLACE_AMOUNT_TO_CHARGE:
            return {
                ...state,
                amountToCharge: payload,
            };
        case STORE_UPDATE_DIRECT_DEPOSIT_REPLACE_AMOUNT_TYPE:
            return {
                ...state,
                amountType: payload,
            };
        case STORE_UPDATE_DIRECT_DEPOSIT_REPLACE_COMPANY_NAME:
            return { ...state, selfOnboardingCompanyName: payload };
        default:
            return state;
    }
};

export default withReduxStepsReducer(
    UPDATE_DIRECT_DEPOSIT_STEPS,
    updateDirectDepositReducer,
);
