import { useState } from 'react';

import { useMountAndUnmount } from '@perpay-web/hooks/useMountAndUnmount';
import { windowSubjects } from '@perpay-web/services/windowSubjects';
import { isMobileScreen } from '@perpay-web/utils/responsiveUtils';

export const useIsMobile = () => {
    const [isMobile, setIsMobile] = useState(isMobileScreen());

    useMountAndUnmount(() => {
        const resizeSubscription = windowSubjects.resize.subscribe(() => {
            const newIsMobile = isMobileScreen(window.innerWidth);
            setIsMobile(newIsMobile);
        });

        return () => resizeSubscription.unsubscribe();
    });

    return { isMobile };
};
