import { ofType } from 'redux-observable';
import { concat } from 'rxjs';
import { mergeMap, exhaustMap } from 'rxjs/operators';

import {
    fetchBanks,
    createBank,
} from '@perpay-web/fintech/actions/entities/banks';
import { routeToLocation } from '@perpay-web/fintech/actions/router';
import { verifyABTest } from '@perpay-web/fintech/actions/shared/abTests';
import {
    fetchPerpaySplitSetUpPaymentsDataSuccess,
    fetchPerpaySplitSetUpPaymentsDataError,
} from '@perpay-web/fintech/actions/ui/perpaySplitSetUpPayments';
import { primaryJob, salaryInfo } from '@perpay-web/fintech/actions/ui/signup';
import { PERPAY_SPLIT_STEP } from '@perpay-web/fintech/components/composite/PaymentSetup/PaymentSetupSteps';
import {
    PERPAY_SPLIT_CREATE_PRIMARY_JOB,
    PERPAY_SPLIT_UPDATE_SALARY_INFO,
    STORE_REPLACE_SALARY_INFO,
    FETCH_PERPAY_SPLIT_SET_UP_PAYMENTS_DATA,
    STORE_REPLACE_BANKS,
    PERPAY_SPLIT_CREATE_BANK,
    STORE_CREATE_BANK_ERROR,
    STORE_CREATE_BANK_SUCCESS,
    STORE_REPLACE_JOBS,
} from '@perpay-web/fintech/constants/actionTypes';
import { waitForAbTestAndSetPaymentSetupStep as waitForAbTestEpic } from '@perpay-web/fintech/epics/ui/paymentSetup';
import { paths } from '@perpay-web/fintech/props/appPaths';
import { dispatchObservable } from '@perpay-web/observable/dispatchObservable';

export const waitForAbTestAndSetPaymentSetupStep =
    waitForAbTestEpic(PERPAY_SPLIT_STEP);

export const fetchPerpaySplitSetUpPaymentsData = (action$, state$) =>
    action$.pipe(
        ofType(FETCH_PERPAY_SPLIT_SET_UP_PAYMENTS_DATA),
        exhaustMap(() =>
            dispatchObservable({
                action$,
                state$,
                initialDispatch: [fetchBanks()],
                waitFor: [STORE_REPLACE_BANKS],
                waitForDispatch: () => [
                    fetchPerpaySplitSetUpPaymentsDataSuccess(),
                ],
                errors: [], // TODO: make banks endpoint error handler
                errorDispatch: (error) => [
                    fetchPerpaySplitSetUpPaymentsDataError(error),
                ],
                timeoutDispatch: () => [
                    fetchPerpaySplitSetUpPaymentsDataError(
                        new Error('timeout'),
                    ),
                ],
            }),
        ),
    );

export function perpaySplitSalaryInfo(action$, state$) {
    return action$.pipe(
        ofType(PERPAY_SPLIT_UPDATE_SALARY_INFO),
        mergeMap((action) => {
            const { payload } = action;
            return concat(
                dispatchObservable({
                    action$,
                    state$,
                    initialDispatch: [salaryInfo(payload)],
                    waitFor: [STORE_REPLACE_SALARY_INFO],
                    waitForDispatch: () => [verifyABTest('pinwheel')],
                }),
                waitForAbTestAndSetPaymentSetupStep(action$, state$),
            );
        }),
    );
}

export function perpaySplitPrimaryJob(action$, state$) {
    return action$.pipe(
        ofType(PERPAY_SPLIT_CREATE_PRIMARY_JOB),
        mergeMap((action) => {
            const { payload } = action;
            return concat(
                dispatchObservable({
                    action$,
                    state$,
                    initialDispatch: [primaryJob(payload)],
                    waitFor: [STORE_REPLACE_JOBS],
                    waitForDispatch: () => [verifyABTest('pinwheel')],
                }),
                waitForAbTestAndSetPaymentSetupStep(action$, state$),
            );
        }),
    );
}

export const perpaySplitCreateBank = (action$, state$) =>
    action$.pipe(
        ofType(PERPAY_SPLIT_CREATE_BANK),
        exhaustMap((action) =>
            dispatchObservable({
                action$,
                state$,
                initialDispatch: () => [createBank(action.payload)],
                waitFor: [STORE_CREATE_BANK_SUCCESS],
                waitForDispatch: () => [routeToLocation(paths.dashboard.path)],
                // The UI consumes the errors that are stored by the createBank epic.
                errors: [STORE_CREATE_BANK_ERROR],
                errorDispatch: () => [],
                // Timeouts are handled within the bank entity epic
            }),
        ),
    );
