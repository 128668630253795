import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import {
    bufferToggle,
    bufferCount,
    take,
    filter,
    mergeMap,
} from 'rxjs/operators';

import {
    facebookMiddlewareReady,
    segmentMiddlewareReady,
} from '@perpay-web/fintech/actions/analytics';

const ANALYTICS_EVENT_PREFIX = 'ANALYTICS::';

const analyticsReadyActionTypes = [
    facebookMiddlewareReady().type,
    segmentMiddlewareReady().type,
];

const getAnalyticsBufferObservable = (action$) => () =>
    action$.pipe(
        ofType(...analyticsReadyActionTypes),
        bufferCount(analyticsReadyActionTypes.length),
        take(1),
    );

export function analyticsBuffer(action$) {
    return action$.pipe(
        filter((action) => action.type.indexOf(ANALYTICS_EVENT_PREFIX) === 0),
        // Buffer the events until the buffer action is received. Then emit all buffered payloads.
        bufferToggle(of(true), getAnalyticsBufferObservable(action$)),
        take(1), // we want to stop re-emitting events after the buffer opens
        mergeMap((actions) =>
            actions.map((action) => ({
                type: action.type,
                payload: action.payload,
            })),
        ),
        // We can re-emit the actions as-is rather than wrapped in a *_BUFFERED event
        // because we know that these don't trigger side-effects in the app.
    );
}
