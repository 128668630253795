import { composeDataModules } from '@perpay-web/data-module/composeDataModules';
import {
    fetchMarketplaceDashboardSummaryDataModule,
    getNotification as getMarketplaceNotification,
} from '@perpay-web/fintech/dataModules/fetchMarketplaceDashboardSummary';
import {
    fetchPlatformDashboardNotificationsDataModule,
    getNotification as getPlatformNotification,
} from '@perpay-web/fintech/dataModules/fetchPlatformDashboardNotifications';

const MarketplaceDetailsNotificationsDataModule = composeDataModules([
    fetchMarketplaceDashboardSummaryDataModule,
    fetchPlatformDashboardNotificationsDataModule,
]);

const getRoot = (state) =>
    state.dataModules.marketplaceDetailsNotificationsDataModule;

const marketplaceDetailsNotificationsDataModule =
    MarketplaceDetailsNotificationsDataModule({
        getRoot,
        key: 'MARKETPLACE_DETAILS_NOTIFICATIONS_DATA_MODULE',
    });

export {
    marketplaceDetailsNotificationsDataModule,
    getMarketplaceNotification,
    getPlatformNotification,
};
