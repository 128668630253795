import { ofType } from 'redux-observable';
import { mergeMap, exhaustMap } from 'rxjs/operators';

import {
    cardActivateSuccess,
    cardActivateError,
} from '@perpay-web/fintech/actions/ui/cardActivation';
import { BACKEND_CARD_ACTIVATE } from '@perpay-web/fintech/constants/actionTypes';
import {
    CARD_ACTIVATION_STEPS,
    SUCCESS_STEP,
} from '@perpay-web/fintech/constants/steps/cardActivationSteps';
import { CARD_ACTIVATION_ENDPOINT } from '@perpay-web/fintech/constants/urls';
import { reduxStepsSetStep } from '@perpay-web/hooks/useReduxSteps';
import { handleErrorMessageWithFallback } from '@perpay-web/observable/operators/handleErrorMessageWithFallback';

export const cardActivate = (action$, state$, { post }) =>
    action$.pipe(
        ofType(BACKEND_CARD_ACTIVATE),
        exhaustMap((action) =>
            post(
                CARD_ACTIVATION_ENDPOINT.replace(
                    ':uuid',
                    action.payload.cardUuid,
                ),
                {
                    lastFour: action.payload.lastFour,
                },
            ),
        ),
        mergeMap(() => [
            reduxStepsSetStep(CARD_ACTIVATION_STEPS, SUCCESS_STEP),
            cardActivateSuccess(),
        ]),
        handleErrorMessageWithFallback((errors) => [cardActivateError(errors)]),
    );
