import { composeDataModules } from '@perpay-web/data-module/composeDataModules';
import {
    fetchPaymentSetupSummaryDataModule,
    getAccountNumber,
    getRoutingNumber,
} from '@perpay-web/fintech/dataModules/fetchPaymentSetupSummary';
import {
    fetchPayrollInstructionsDataModule,
    getDirectDepositFormUrl,
} from '@perpay-web/fintech/dataModules/fetchPayrollInstructions';
import {
    generalAccountSummaryDataModule,
    getMinimumPayment,
} from '@perpay-web/fintech/dataModules/generalAccountSummary';

const CardManualPaymentSetupDataModule = composeDataModules([
    generalAccountSummaryDataModule,
    fetchPaymentSetupSummaryDataModule,
    fetchPayrollInstructionsDataModule,
]);

const getRoot = (state) => state.dataModules.cardManualPaymentSetup;

export const cardManualPaymentSetupDataModule =
    CardManualPaymentSetupDataModule({
        getRoot,
        key: 'CARD_MANUAL_PAYMENT_SETUP',
    });

export {
    getMinimumPayment,
    getAccountNumber,
    getRoutingNumber,
    getDirectDepositFormUrl,
};
