import { createAction } from '@reduxjs/toolkit';

export const analyticsNativeUpgradeNotificationViewed = createAction(
    'ANALYTICS::NATIVE_UPGRADE_NOTIFICATION::VIEW',
);

export const analyticsNativeUpgradeNotificationClickCta = createAction(
    'ANALYTICS::NATIVE_UPGRADE_NOTIFICATION::CLICK_CTA',
);

export const analyticsNativeUpgradeNotificationClose = createAction(
    'ANALYTICS::NATIVE_UPGRADE_NOTIFICATION::CLOSE',
);
