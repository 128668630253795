import WarningLoaderCircle from '@perpay-web/components/base/WarningLoaderCircle/WarningLoaderCircle';
import { useHeaderRightNav } from '@perpay-web/fintech/hooks/useHeaderRightNavContext';
import { useMount } from '@perpay-web/hooks/useMount';

import styles from './Logout.scss';

const Logout = ({ onMount }) => {
    useMount(onMount);
    useHeaderRightNav(null);

    return (
        <div className={styles.loader__container}>
            <WarningLoaderCircle />
        </div>
    );
};

export default Logout;
