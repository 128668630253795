export function randomElementOf(arr) {
    const index = Math.floor(Math.random() * arr.length);
    return arr[index];
}

/**
 * Returns the last item of an array
 */
export function lastItemOf(array) {
    if (!array || !Array.isArray(array)) return undefined;
    if (Array.prototype.at) {
        return array.at(-1);
    }

    return array[array.length - 1];
}

/**
 * Returns the first item of an array
 */
export function firstItemOf(array) {
    if (!array || !Array.isArray(array)) return undefined;
    if (Array.prototype.at) {
        return array.at(0);
    }

    return array[0];
}

/**
 * Removes recurring primitive items from an array
 */
export function distinct(array = []) {
    return Array.from(new Set(array));
}
