import { useState } from 'react';
import { useEffect } from 'react';

import { getStateFromNative } from '@perpay-web/native-client/stateFromNative';

const nativeState$ = getStateFromNative();

export const useNativeSelector = (selector) => {
    const [nativeState, setNativeState] = useState(nativeState$.value);

    useEffect(() => {
        const subscription = nativeState$.subscribe((nextNativeState) => {
            setNativeState(nextNativeState);
        });

        return () => subscription.unsubscribe();
    }, []);

    return selector(nativeState);
};
