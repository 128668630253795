import { ofType } from 'redux-observable';
import { exhaustMap, switchMap } from 'rxjs/operators';

import {
    fetchAutoPaySchedules,
    fetchAutoPaySchedulesSuccess,
    createAutoPaySchedule as createAutoPayScheduleAction,
    createAutoPayScheduleSuccess,
    createAutoPayScheduleError,
    updateAutoPaySchedule as updateAutoPayScheduleAction,
    updateAutoPayScheduleSuccess,
    updateAutoPayScheduleError,
} from '@perpay-web/fintech/actions/entities/autoPaySchedules';
import {
    createAutoPayScheduleForCardAutoPay as createAutoPayScheduleForCardAutoPayAction,
    createAutoPayScheduleForCardAutoPaySuccess,
    createAutoPayScheduleForCardAutoPayError,
    cancelAutoPaySchedule as cancelAutoPayScheduleAction,
    cancelAutoPayScheduleSuccess,
    cancelAutoPayScheduleError,
} from '@perpay-web/fintech/actions/ui/autoPaySchedules';
import { resetCardCreateAutoPayDateAndAmountData } from '@perpay-web/fintech/actions/ui/cardCreateAutoPay';
import {
    STORE_CARD_CREATE_AUTO_PAY_REPLACE_DATE,
    STORE_CARD_CREATE_AUTO_PAY_REPLACE_FREQUENCY,
} from '@perpay-web/fintech/constants/actionTypes';
import { FIXED_AMOUNT } from '@perpay-web/fintech/constants/autoPayScheduleAmountTypes';
import { ONE_TIME as ONE_TIME_SCHEDULE_TYPE } from '@perpay-web/fintech/constants/autoPayScheduleScheduleTypes';
import {
    CARD_CREATE_AUTO_PAY_STEPS,
    SELECT_DATE_STEP,
    SELECT_AMOUNT_STEP,
    SUCCESS_STEP,
} from '@perpay-web/fintech/constants/steps/cardCreateAutoPaySteps';
import { cardAutoPayMakePaymentsModalSetIsModalOpen } from '@perpay-web/fintech/hooks/useCardAutoPayMakePaymentModalContext';
import { cardCancelAutoPayScheduleModalSetIsModalOpen } from '@perpay-web/fintech/hooks/useCardCancelAutoPayScheduleModalContext';
import { reduxStepsSetStep } from '@perpay-web/hooks/useReduxSteps';
import { dispatchObservable } from '@perpay-web/observable/dispatchObservable';

export const updateCardAutoPayStepAfterDateSelection = (action$) =>
    action$.pipe(
        ofType(STORE_CARD_CREATE_AUTO_PAY_REPLACE_DATE),
        switchMap(() => [
            reduxStepsSetStep(CARD_CREATE_AUTO_PAY_STEPS, SELECT_AMOUNT_STEP),
        ]),
    );

export const updateCardAutoPayStateAfterFrequencySelection = (action$) =>
    action$.pipe(
        ofType(STORE_CARD_CREATE_AUTO_PAY_REPLACE_FREQUENCY),
        switchMap(() => [
            // when a user updates frequency, reset amount & date data in case
            // they changed frequency and the previous selections no longer are
            // applicable
            resetCardCreateAutoPayDateAndAmountData(),
            reduxStepsSetStep(CARD_CREATE_AUTO_PAY_STEPS, SELECT_DATE_STEP),
        ]),
    );

export const createAutoPayScheduleForCardAutoPay = (action$, state$) =>
    action$.pipe(
        ofType(createAutoPayScheduleForCardAutoPayAction().type),
        exhaustMap((action) =>
            dispatchObservable({
                action$,
                state$,
                initialDispatch: () => [
                    createAutoPayScheduleAction({
                        ...action.payload,
                        amountType: FIXED_AMOUNT,
                        schedule: ONE_TIME_SCHEDULE_TYPE,
                    }),
                ],
                waitFor: [createAutoPayScheduleSuccess().type],
                waitForDispatch: (state, results) => {
                    const { payload } = results[0];
                    return [
                        createAutoPayScheduleForCardAutoPaySuccess(payload),
                        reduxStepsSetStep(
                            CARD_CREATE_AUTO_PAY_STEPS,
                            SUCCESS_STEP,
                        ),
                    ];
                },
                errors: [createAutoPayScheduleError().type],
                errorDispatch: (errorAction) => [
                    createAutoPayScheduleForCardAutoPayError(
                        errorAction.payload,
                    ),

                    cardAutoPayMakePaymentsModalSetIsModalOpen(false),
                ],
                timeoutDispatch: () => [
                    createAutoPayScheduleForCardAutoPayError(
                        new Error('timeout'),
                    ),
                    cardAutoPayMakePaymentsModalSetIsModalOpen(false),
                ],
            }),
        ),
    );

export const cancelAutoPaySchedule = (action$, state$) =>
    action$.pipe(
        ofType(cancelAutoPayScheduleAction().type),
        exhaustMap((action) =>
            dispatchObservable({
                action$,
                state$,
                initialDispatch: () => [
                    updateAutoPayScheduleAction({
                        uuid: action.payload,
                        requestBody: { status: 'inactive' },
                    }),
                ],
                waitFor: [updateAutoPayScheduleSuccess().type],
                waitForDispatch: () =>
                    dispatchObservable({
                        action$,
                        state$,
                        initialDispatch: [
                            cancelAutoPayScheduleSuccess(),
                            fetchAutoPaySchedules(),
                        ],
                        waitFor: [fetchAutoPaySchedulesSuccess().type],
                        waitForDispatch: [
                            cardCancelAutoPayScheduleModalSetIsModalOpen(false),
                        ],
                    }),
                errors: [updateAutoPayScheduleError().type],
                errorDispatch: (errorAction) => [
                    cancelAutoPayScheduleError(errorAction.payload),
                ],
            }),
        ),
    );
