import { useEffect } from 'react';

import BottomSheet from '@perpay-web/components/base/BottomSheet/BottomSheet';
import { IOS_STORE_URL, ANDROID_STORE_URL } from '@perpay-web/constants/urls';
import nativeUpgradeAndroid from '@perpay-web/fintech/assets/images/nativeUpgradeAndroid.png';
import nativeUpgradeIos from '@perpay-web/fintech/assets/images/nativeUpgradeIos.png';
import CustomLink from '@perpay-web/fintech/components/base/CustomLink/CustomLink';
import { createNewNativeUpgradeNotification } from '@perpay-web/fintech/utils/nativeUpgradeNotificationUtils';
import { getClassName } from '@perpay-web/utils/domUtils';
import { isIosAgent } from '@perpay-web/utils/userAgentUtils';

import styles from './NativeUpgradeNotification.scss';

const DAYS_UNTIL_NOTIFICATION_EXPIRATION = 7;

const NativeUpgradeNotification = ({
    className,
    onMount,
    onClickCta,
    onClose,
}) => {
    useEffect(() => {
        onMount();
    }, [onMount]);

    const isIosUserAgent = isIosAgent();

    return (
        <BottomSheet
            onClose={() => {
                createNewNativeUpgradeNotification(
                    DAYS_UNTIL_NOTIFICATION_EXPIRATION,
                );
                onClose();
            }}
            className={getClassName(styles.modal, className)}
        >
            <div className={styles.header}>
                Upgrade Your App for a Faster, Smarter Experience!
            </div>
            <div className={styles['img-container']}>
                <img
                    className={styles['native-upgrade-img']}
                    src={
                        isIosUserAgent ? nativeUpgradeIos : nativeUpgradeAndroid
                    }
                    alt='Illustration of new features'
                    data-chromatic='ignore'
                />
            </div>
            <div className={styles.copy}>
                Our latest update delivers <strong>biometric login</strong>,
                support for virtual wallets, and{' '}
                <strong>lightning-fast performance.</strong>
            </div>
            <div className={styles['upgrade-button-container']}>
                <CustomLink
                    className={styles['upgrade-button']}
                    action='primary'
                    target='_blank'
                    href={isIosUserAgent ? IOS_STORE_URL : ANDROID_STORE_URL}
                    onClick={() => {
                        createNewNativeUpgradeNotification(
                            DAYS_UNTIL_NOTIFICATION_EXPIRATION,
                        );
                        onClickCta();
                    }}
                >
                    Upgrade from {isIosUserAgent ? 'App' : 'Play'} Store
                </CustomLink>
            </div>
        </BottomSheet>
    );
};

export default NativeUpgradeNotification;
