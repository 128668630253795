import { ofType } from 'redux-observable';
import { EMPTY } from 'rxjs';
import { mergeMap, switchMap, timeoutWith } from 'rxjs/operators';

import {
    acknowledgeNotificationError,
    acknowledgeNotificationSuccess,
    fetchNotificationsError,
    fetchNotificationsSuccess,
} from '@perpay-web/fintech/actions/entities/notifications';
import {
    ACKNOWLEDGE_NOTIFICATION,
    FETCH_NOTIFICATIONS,
} from '@perpay-web/fintech/constants/actionTypes';
import { NOTIFICATIONS_ENDPOINT } from '@perpay-web/fintech/constants/urls';
import { handleError } from '@perpay-web/observable/operators/handleError';

const NOTIFICATION_LOAD_TIMEOUT_MS = 500;

export const fetchNotifications = (action$, state$, { get }) =>
    action$.pipe(
        ofType(FETCH_NOTIFICATIONS),
        switchMap(() =>
            // We use a nested pipe because the timeoutWith starts its timer when the operator
            // is created, so we need to start the request and create the operator at the same time.
            // Thus, we cannot call the operator at the top level.
            get(NOTIFICATIONS_ENDPOINT).pipe(
                timeoutWith(NOTIFICATION_LOAD_TIMEOUT_MS, EMPTY),
            ),
        ),
        mergeMap((results) => [fetchNotificationsSuccess(results.response)]),
        handleError((error) => [fetchNotificationsError(error)]),
    );

export const acknowledgeNotification = (action$, state$, { patch }) =>
    action$.pipe(
        ofType(ACKNOWLEDGE_NOTIFICATION),
        switchMap((action) =>
            patch(`${NOTIFICATIONS_ENDPOINT}${action.payload.uuid}/`, {
                acknowledged: true,
            }),
        ),
        mergeMap((results) => [
            acknowledgeNotificationSuccess(results.response),
        ]),
        handleError((error) => [acknowledgeNotificationError(error)]),
    );
