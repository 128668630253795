import { createAction } from '@reduxjs/toolkit';

import {
    BACKEND_FETCH_AUTH_WITH_CREDENTIALS_ERROR,
    BACKEND_FETCH_AUTH_WITH_CREDENTIALS,
    BACKEND_FETCH_AUTH_WITH_REFRESH_TOKEN,
    BACKEND_FETCH_RECAPTCHA_ACTIVE,
    BACKEND_POST_PASSWORD_RESET,
    STORE_CLEAR_PASSWORD_RESET,
    STORE_REPLACE_AUTH,
    STORE_REPLACE_PASSWORD_RESET_ERROR,
    STORE_REPLACE_PASSWORD_RESET_SUCCESS,
    STORE_REPLACE_RECAPTCHA_ACTIVE,
    STORE_RESET_RECAPTCHA,
    STORE_PARTNER_HOSTED_ONBOARDING_REDIRECT_EXISTING_USER,
} from '@perpay-web/fintech/constants/actionTypes';

export function fetchAuthTokens(credentials) {
    return {
        type: BACKEND_FETCH_AUTH_WITH_CREDENTIALS,
        payload: credentials,
    };
}

export function fetchAuthTokensError(error) {
    return {
        type: BACKEND_FETCH_AUTH_WITH_CREDENTIALS_ERROR,
        payload: error,
    };
}

export const authenticationSuccess = createAction('AUTHENTICATION::SUCCESS');

export const fetchAuthTokensSuccess = createAction(
    'store/FETCH::AUTH_WITH_CREDENTIALS_SUCCESS',
);

export function refreshAccessToken() {
    return {
        type: BACKEND_FETCH_AUTH_WITH_REFRESH_TOKEN,
    };
}

export function replaceAuthTokens(tokens) {
    return {
        type: STORE_REPLACE_AUTH,
        payload: tokens,
    };
}

export function resetPassword(payload) {
    return {
        type: BACKEND_POST_PASSWORD_RESET,
        payload,
    };
}

export function replacePasswordSuccess(redirectPath) {
    return {
        type: STORE_REPLACE_PASSWORD_RESET_SUCCESS,
        payload: { redirectPath },
    };
}

export function replacePasswordError(error) {
    const { message } = error;
    return {
        type: STORE_REPLACE_PASSWORD_RESET_ERROR,
        payload: { message },
    };
}

export function clearResetPassword() {
    return {
        type: STORE_CLEAR_PASSWORD_RESET,
    };
}

export const postLogout = createAction('backend/POST::AUTH_LOGOUT');

export const resetAuthLogout = createAction('store/RESET::AUTH_LOGOUT');

export function fetchRecaptchaActive() {
    return {
        type: BACKEND_FETCH_RECAPTCHA_ACTIVE,
    };
}

export function replaceRecaptchaActive(payload) {
    return {
        type: STORE_REPLACE_RECAPTCHA_ACTIVE,
        payload: { active: payload },
    };
}

export function resetRecaptcha() {
    return { type: STORE_RESET_RECAPTCHA };
}

export const initializeIdentity = createAction('INITIALIZE_IDENTITY');

export function partnerOnboardingRedirectExistingUser() {
    return {
        type: STORE_PARTNER_HOSTED_ONBOARDING_REDIRECT_EXISTING_USER,
    };
}

export const storeMFAData = createAction('store/AUTH_MFA::DATA');

export const storeMFAAttemptError = createAction(
    'store/AUTH_MFA::ERROR_ATTEMPT',
);

export const storeMFAResendError = createAction('store/AUTH_MFA::ERROR_RESEND');

export const storeMFAThrottleError = createAction(
    'store/AUTH_MFA::ERROR_THROTTLE',
);

export const verifyMFA = createAction(
    'backend/POST::AUTH_MFA',
    (otpUuid, value) => ({
        payload: {
            otp_uuid: otpUuid,
            code: value,
        },
    }),
);

export const resendMFA = createAction(
    'backend/POST::AUTH_MFA_RESEND',
    (otpUuid) => ({
        payload: {
            otp_uuid: otpUuid,
        },
    }),
);
