import { useCallback, useEffect, useRef, useState } from 'react';
import { Observable, merge } from 'rxjs';

import { emitOrTimeout } from '@perpay-web/utils/observable';

import {
    SIDE_EFFECT_ERROR_STATE,
    SIDE_EFFECT_INITIAL_STATE,
    SIDE_EFFECT_PENDING_STATE,
    composeSideEffects,
    useSideEffect,
} from './useSideEffect';

const getIsUnrequested = (state) =>
    state.requestState === SIDE_EFFECT_INITIAL_STATE;

export const getIsLoading = (state) =>
    state.requestState === SIDE_EFFECT_PENDING_STATE;

export const getIsLoadingOrUnrequested = (state) =>
    getIsLoading(state) || getIsUnrequested(state);

export const getData = (state) => state.value;

export const getErrors = (state) => state.errors;

export const useDataModule = (sideEffect, options = { initialValue: null }) => {
    const { sideEffect$, state$, next, reset } = useSideEffect((...args) => {
        const result$ = sideEffect(...args);
        return emitOrTimeout(result$, options.timeout);
    });
    const [state, setState] = useState({
        requestState: state$.value.state,
        errors: state$.value.errors,
        value: options.initialValue,
    });

    // We use a ref here for the subscription instead of a useEffect.
    // This is because a child's useEffects will run BEFORE a parent's useEffects.
    // In perpay-web, often a parent component will define a hook and then pass
    // a callback to a child component, like an onMount prop.
    // If we define this listener here in a useEffect, and pass the emit callback to a child,
    // then the listener will start listening in the parent useEffect AFTER the child's
    // has already fired its callback within its useEffect.
    // If we use a ref to initialize the listener only once, then we
    // start listening immediately when the component first renders, before
    // any of its children can call dataRequest.
    const subscriptionRef = useRef(null);
    if (!subscriptionRef.current) {
        subscriptionRef.current = state$.subscribe((nextState) => {
            setState((prevState) => {
                if (prevState.requestState === nextState.state) {
                    return prevState;
                }

                let { value } = nextState;
                if (
                    [
                        SIDE_EFFECT_PENDING_STATE,
                        SIDE_EFFECT_ERROR_STATE,
                    ].includes(nextState.state)
                ) {
                    value = prevState.value;
                }

                if (nextState.state === SIDE_EFFECT_INITIAL_STATE) {
                    value = options.initialValue;
                }

                return {
                    requestState: nextState.state,
                    errors: nextState.errors,
                    value,
                };
            });
        });
    }
    useEffect(() => {
        const subscription = subscriptionRef.current;
        return () => subscription.unsubscribe();
    }, []);

    const dataRequest = useCallback(
        (requestPayload) => {
            next(requestPayload);
        },
        [next],
    );
    const dataReset = useCallback(() => {
        reset();
    }, [reset]);

    return {
        state,
        dataRequest,
        dataReset,
        sideEffect$,
    };
};

const isSameDataModule = (first, second) =>
    first.sideEffect$ === second.sideEffect$;

/**
 * Take a list of data modules and compose their side-effects.
 * Usage:
 * ```
 * const composedDataModule = useDataModule(
 *   composeDataModuleSideEffects(
 *     dataModuleOne,
 *     dataModuleTwo));
 *
 * useMount(() => composedDataModule.dataRequest());
 * ```
 *
 * Usage with overrides:
 * ```
 * const composedDataModule = useDataModule(
 *   composeDataModuleSideEffects(
 *     dataModuleOne,
 *      dataModule2));
 * useMount(() => composedDataModule.dataRequest([
 *   {
 *      dataModule: dataModuleOne,
 *      dataRequest: () => dataModuleOne.dataRequest('specific value'),
 *   }
 * ]))
 * ```
 * @returns
 */
export const composeDataModuleSideEffects = (...dataModules) => {
    const composed = composeSideEffects(
        ...dataModules.map((dataModule) => dataModule.sideEffect$),
    );

    // We return a function because a side-effect is a function that returns an Observable.
    return (overrides = []) => {
        const composed$ = composed();

        // We want to subscribe to this Observable and initiate the requests
        // only after the composed$ Observable has been subscribed to and
        // it has started listening for the results.
        const request$ = new Observable((subscriber) => {
            dataModules.forEach((dataModule) => {
                const matchingOverride = overrides.find((override) =>
                    isSameDataModule(override.dataModule, dataModule),
                );
                if (matchingOverride) {
                    matchingOverride.dataRequest();
                    return;
                }

                dataModule.dataRequest();
            });

            // Let listeners know we're done.
            subscriber.complete();
        });

        // First start listening, then kick off the requests
        return merge(composed$, request$);
    };
};
