import { createDataModuleActions } from '@perpay-web/data-module/createDataModuleActions';

const submitCardMigrationActions = createDataModuleActions(
    'SUBMIT_CARD_MIGRATION',
);
export const submitCardMigration = submitCardMigrationActions.dataRequest;
export const submitCardMigrationSuccess =
    submitCardMigrationActions.dataSuccess;
export const submitCardMigrationError = submitCardMigrationActions.dataError;
export const submitCardMigrationReset = submitCardMigrationActions.dataReset;
