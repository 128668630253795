import { reportError } from '@perpay-web/utils/errorHandlers';

export const clearSessionStorage = () => {
    try {
        window.sessionStorage.clear('isApplicationInProgress');
        window.sessionStorage.clear('currentStep');
    } catch (e) {
        reportError('Error clearing window.sessionStorage', e);
    }
};
