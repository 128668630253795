import { trackPageView, trackEvent } from '@redux-beacon/google-analytics';

import { ANALYTICS_SIGNUP_SUCCESS } from '@perpay-web/fintech/constants/actionTypes';
import { analyticsLocationChange } from '@perpay-web/hooks/useTrackLocationChange';

const pageView = trackPageView((action) => ({
    page: action.payload.location.pathname,
}));

const registration = trackEvent(() => ({
    category: 'Registration',
    action: 'Registration',
    label: 'Registration',
    value: 1,
}));

const googleAnalyticsEventsMap = {
    [analyticsLocationChange().type]: pageView,
    [ANALYTICS_SIGNUP_SUCCESS]: registration,
};

export default googleAnalyticsEventsMap;
