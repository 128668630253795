import { ofType } from 'redux-observable';
import { concat } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { verifyABTest } from '@perpay-web/fintech/actions/shared/abTests';
import { fetchAccountSummary } from '@perpay-web/fintech/actions/shared/accountSummary';
import { primaryJob, salaryInfo } from '@perpay-web/fintech/actions/ui/signup';
import { PINWHEEL_STEP } from '@perpay-web/fintech/components/composite/PaymentSetup/PaymentSetupSteps';
import {
    PINWHEEL_CREATE_PRIMARY_JOB,
    PINWHEEL_UPDATE_SALARY_INFO,
    STORE_REPLACE_JOBS,
    STORE_REPLACE_SALARY_INFO,
} from '@perpay-web/fintech/constants/actionTypes';
import { fetchPaymentSetupSummaryDataModule } from '@perpay-web/fintech/dataModules/fetchPaymentSetupSummary';
import { waitForAbTestAndSetPaymentSetupStep as waitForAbTestEpic } from '@perpay-web/fintech/epics/ui/paymentSetup';
import { dispatchObservable } from '@perpay-web/observable/dispatchObservable';

export const waitForAbTestAndSetPaymentSetupStep =
    waitForAbTestEpic(PINWHEEL_STEP);

export function pinwheelUpdateSalaryInfo(action$, state$) {
    return action$.pipe(
        ofType(PINWHEEL_UPDATE_SALARY_INFO),
        mergeMap((action) => {
            const { payload } = action;
            return concat(
                dispatchObservable({
                    action$,
                    state$,
                    initialDispatch: [salaryInfo(payload)],
                    waitFor: [STORE_REPLACE_SALARY_INFO],
                    waitForDispatch: () => [
                        verifyABTest('pinwheel'),
                        fetchAccountSummary(),
                        fetchPaymentSetupSummaryDataModule.dataRequest({
                            getIsInstantShipEligible: true,
                        }),
                    ],
                }),
                waitForAbTestAndSetPaymentSetupStep(action$, state$),
            );
        }),
    );
}

export function pinwheelCreatePrimaryJob(action$, state$) {
    return action$.pipe(
        ofType(PINWHEEL_CREATE_PRIMARY_JOB),
        mergeMap((action) => {
            const { payload } = action;

            return concat(
                dispatchObservable({
                    action$,
                    state$,
                    initialDispatch: [primaryJob(payload)],
                    waitFor: [STORE_REPLACE_JOBS],
                    waitForDispatch: () => [
                        verifyABTest('pinwheel'),
                        fetchAccountSummary(),
                    ],
                }),
                waitForAbTestAndSetPaymentSetupStep(action$, state$),
            );
        }),
    );
}
