import { UNREQUESTED_STATE } from '@perpay-web/data-module/constants';
import { createDataModule } from '@perpay-web/data-module/createDataModule';
import {
    gateCardApply,
    gateCardApplySuccess,
    gateCardApplyError,
    resetGateCardApply,
} from '@perpay-web/fintech/actions/entities/gateCardApply';

const GateCardApplyDataModule = createDataModule({
    dataRequest: gateCardApply,
    dataSuccess: gateCardApplySuccess,
    dataError: gateCardApplyError,
    dataReset: resetGateCardApply,
});

const getRoot = (state) => state.dataModules.gateCardApply;
const initialValue = {};

export const gateCardApplyDataModule = GateCardApplyDataModule({
    getRoot,
    initialValue,
});

export const getIsGateCardApply = (state) => {
    const requestState =
        gateCardApplyDataModule.getRoot(state).requestState || '';
    return requestState !== UNREQUESTED_STATE;
};
