import { ofType } from 'redux-observable';
import { switchMap, mergeMap } from 'rxjs/operators';

import {
    fetchMarketplaceDashboardSummary as fetchMarketplaceDashboardSummaryAction,
    fetchMarketplaceDashboardSummarySuccess,
    fetchMarketplaceDashboardSummaryError,
} from '@perpay-web/fintech/actions/entities/marketplaceDashboardSummary';
import { MARKETPLACE_DASHBOARD_SUMMARY_ENDPOINT } from '@perpay-web/fintech/constants/urls';
import { handleError } from '@perpay-web/observable/operators/handleError';

export const fetchMarketplaceDashboardSummary = (action$, state$, { get }) =>
    action$.pipe(
        ofType(fetchMarketplaceDashboardSummaryAction().type),
        switchMap(() => get(MARKETPLACE_DASHBOARD_SUMMARY_ENDPOINT)),
        mergeMap((results) => [
            fetchMarketplaceDashboardSummarySuccess(
                results.response.marketplace,
            ),
        ]),
        handleError((error) => [fetchMarketplaceDashboardSummaryError(error)]),
    );
