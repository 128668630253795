import { createSlice } from '@reduxjs/toolkit';

import { ADS } from '@perpay-web/fintech/constants/tableNames';
import { noop } from '@perpay-web/utils/noop';

const adsSlice = createSlice({
    name: 'ads',
    initialState: {},
    reducers: (create) => ({
        fetchAds: create.reducer(noop),
        replaceAds: create.reducer((state, action) => ({ ...action.payload })),
        fetchAdsError: create.reducer(noop),
    }),
});

export const getAds = (state) =>
    state.entities[ADS] ? Object.values(state.entities[ADS]) : [];

export const { fetchAds, replaceAds, fetchAdsError } = adsSlice.actions;
export default adsSlice.reducer;
