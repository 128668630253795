import { useNavigate } from 'react-router-dom';

import { SHOP_PREFIX } from '@perpay-web/constants/paths';
import { useMount } from '@perpay-web/hooks/useMount';

const ProductQueryRedirect = () => {
    const params = new URLSearchParams(window.location.search);
    const navigate = useNavigate();

    useMount(() => {
        const id = params.get('id');
        if (id) {
            params.delete('id');
            navigate(`${SHOP_PREFIX}/product/${id}`, { replace: true });
        } else {
            navigate(SHOP_PREFIX, { replace: true });
        }
    });
};

export default ProductQueryRedirect;
