import * as Sentry from '@sentry/react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

const sentryCreateBrowserRouter =
    Sentry.wrapCreateBrowserRouterV6(createBrowserRouter);

/**
 * @type {import('@remix-run/router').Router} Router
 */
let router;

/**
 *
 * @param {import('react-router-dom').RouteObject[]} routes
 * @param {(router: import('@remix-run/router').Router) => void} onInitialize
 * @returns
 */
export const getOrCreateRouter = (routes = [], onInitialize = undefined) => {
    if (!router) {
        // Handle an edge case where a URL constructor fails to process when the path is "//"
        if (window.location.pathname === '//') {
            window.location.pathname = '';
            return null;
        }

        router = sentryCreateBrowserRouter(routes, {
            // Allow easier migration with future feature flags!
            // TODO: Remove this when migrating to React Router V7
            future: {
                v7_fetcherPersist: true,
                v7_normalizeFormMethod: true,
                v7_partialHydration: true,
                v7_relativeSplatPath: true,
                v7_skipActionErrorRevalidation: true,
                v7_startTransition: true,
            },
        });
        if (onInitialize) {
            onInitialize(router);
        }
    }
    return router;
};

export const AppRouterProvider = () => <RouterProvider router={router} />;

/**
 * @param {import('react-router-dom').To} to
 */
export const push = (to) => getOrCreateRouter().navigate(to);

/**
 * @param {import('react-router-dom').To} to
 */
export const replace = (to) =>
    getOrCreateRouter().navigate(to, { replace: true });

export const getRouterState = () => getOrCreateRouter().state;
