import Skeleton from '@perpay-web/components/design-system/Skeleton/Skeleton';
import { getClassName } from '@perpay-web/utils/domUtils';

import styles from './AutocompleteListItem.scss';

export const AutocompleteListItem = ({
    children,
    isLoading = false,
    onClick,
    onMouseDown,
    onClear,
    className,
}) => (
    <div className={getClassName(styles['autocomplete-list-item'], className)}>
        {isLoading ? (
            <div className={styles['autocomplete-list-item__skeleton']}>
                <Skeleton
                    className={getClassName(
                        styles['autocomplete-list-item__skeleton__item'],
                        styles[
                            'autocomplete-list-item__skeleton__item--circular'
                        ],
                    )}
                />
                <Skeleton
                    className={getClassName(
                        styles['autocomplete-list-item__skeleton__item'],
                        styles['autocomplete-list-item__skeleton__item--text'],
                    )}
                />
            </div>
        ) : (
            <div
                className={getClassName(
                    styles['autocomplete-list-item__buttons'],
                )}
            >
                <button
                    className={
                        styles['autocomplete-list-item__buttons__main-button']
                    }
                    onClick={onClick}
                    onMouseDown={onMouseDown}
                    type='button'
                >
                    <div className={styles['autocomplete-list-item__content']}>
                        {children}
                    </div>
                </button>
                {onClear ? (
                    <button
                        type='button'
                        onClick={onClear}
                        className={styles['autocomplete-list-item__clear-icon']}
                        aria-label='Remove item'
                    />
                ) : null}
            </div>
        )}
    </div>
);
