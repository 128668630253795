import {
    AlertIcons,
    AlertSeverity,
} from '@perpay-web/components/base/Alert/Alert';
import { UPDATE_DIRECT_DEPOSIT } from '@perpay-web/fintech/constants/alertCtaTypes';
import { paths } from '@perpay-web/fintech/props/appPaths';

export const DELINQUENT_CARD_AND_MP = 'platform_delinquent';
export const CHARGED_OFF = 'platform_charged_off';
export const PLATFORM_GOOD = 'platform_good';

export const EMPTY_NOTIFICATION = {
    content: '',
    severity: '',
    callToActionRoute: '',
    icon: '',
};

export const PLATFORM_NOTIFICATIONS_LEVEL_1_MAP = {
    [DELINQUENT_CARD_AND_MP]: {
        content:
            'You have past due payments on your account. Your credit score may be impacted.',
        severity: AlertSeverity.DANGER,
        callToActionRoute: paths.updateDirectDeposit.path,
        callToActionType: UPDATE_DIRECT_DEPOSIT,
        icon: '',
    },
    [CHARGED_OFF]: {
        content: 'Your account is past due. Your credit score may be impacted.',
        severity: AlertSeverity.DANGER,
        callToActionRoute: paths.updateDirectDeposit.path,
        callToActionType: UPDATE_DIRECT_DEPOSIT,
        icon: '',
    },
};

export const PLATFORM_NOTIFICATIONS_LEVEL_2_MAP = {
    [DELINQUENT_CARD_AND_MP]: {
        content:
            'You have past due payments on your account. Your credit score may be impacted.',
        severity: 'danger',
        callToActionRoute: paths.updateDirectDeposit.path,
        callToActionType: UPDATE_DIRECT_DEPOSIT,
        icon: AlertIcons.POLICE_CAR_LIGHT,
    },
    [CHARGED_OFF]: {
        content:
            'You have past due payments on your account. Your credit score may be impacted.',
        severity: 'danger',
        callToActionRoute: paths.updateDirectDeposit.path,
        callToActionType: UPDATE_DIRECT_DEPOSIT,
        icon: AlertIcons.POLICE_CAR_LIGHT,
    },
};
