import { getClassName } from '@perpay-web/utils/domUtils';
import { noop } from '@perpay-web/utils/noop';

import styles from './ToggleSwitch.scss';

const ToggleSwitch = ({
    id,
    checked = null,
    disabled = false,
    onChange = noop,
    className = '',
}) => (
    <label htmlFor={id} className={getClassName(styles.switch, className)}>
        <input
            id={id}
            name={id}
            type='checkbox'
            className={styles.input}
            onChange={onChange}
            disabled={disabled}
            checked={checked}
            aria-label='Toggle switch'
        />
        <span
            className={getClassName(
                styles.slider,
                disabled && styles['disabled-slider'],
            )}
        />
    </label>
);

export default ToggleSwitch;
