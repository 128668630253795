import { ofType } from 'redux-observable';
import { switchMap, mergeMap } from 'rxjs/operators';

import {
    coreSummaryRequest,
    coreSummarySuccess,
    coreSummaryError,
} from '@perpay-web/fintech/actions/shared/coreSummary';
import { CORE_SUMMARY_ENDPOINT } from '@perpay-web/fintech/constants/urls';
import { handleErrorMessageWithFallback } from '@perpay-web/observable/operators/handleErrorMessageWithFallback';

export const fetchCoreSummary = (action$, state$, { get }) =>
    action$.pipe(
        ofType(coreSummaryRequest().type),
        switchMap(() => get(CORE_SUMMARY_ENDPOINT)),
        mergeMap((results) => [coreSummarySuccess(results.response)]),
        handleErrorMessageWithFallback((error) => [coreSummaryError(error)]),
    );
