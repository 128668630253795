import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { analyticsBottomNavOnClick } from '@perpay-web/fintech/actions/analytics/bottomNav';
import BottomNav from '@perpay-web/fintech/components/base/BottomNav/BottomNav';
import {
    fetchCardDashboardSummaryDataModule,
    getNotification as getCardNotification,
} from '@perpay-web/fintech/dataModules/fetchCardDashboardSummary';
import { paths } from '@perpay-web/fintech/props/appPaths';
import {
    NO_APPLICATION,
    NOT_ENABLED,
    APPLICATION_REJECTED,
    APPLICATION_ABANDONED,
    NO_MARKETING,
    PRE_APPROVED,
} from '@perpay-web/fintech/utils/cardDashboardNotificationUtils';
import { launchSupportTool } from '@perpay-web/services/supportTool';

const BottomNavContainer = () => {
    const dispatch = useDispatch();

    const cardNotification = useSelector(getCardNotification);

    const cardButtonRoute = [
        NO_MARKETING,
        NO_APPLICATION,
        PRE_APPROVED,
        NOT_ENABLED,
        APPLICATION_REJECTED,
        APPLICATION_ABANDONED,
    ].includes(cardNotification)
        ? paths.cardLearn.path
        : paths.cardDetails.path;

    const launchSupportToolHandler = useCallback(
        () => dispatch(launchSupportTool()),
        [dispatch],
    );

    const analyticsBottomNavOnClickHome = useCallback(
        () => dispatch(analyticsBottomNavOnClick({ type: 'home' })),
        [dispatch],
    );

    const analyticsBottomNavOnClickCard = useCallback(
        () => dispatch(analyticsBottomNavOnClick({ type: 'card' })),
        [dispatch],
    );

    const analyticsBottomNavOnClickShop = useCallback(
        () => dispatch(analyticsBottomNavOnClick({ type: 'shop' })),
        [dispatch],
    );

    const analyticsBottomNavOnClickMarketplace = useCallback(
        () =>
            dispatch(
                analyticsBottomNavOnClick({ type: 'marketplace-details' }),
            ),
        [dispatch],
    );

    const analyticsBottomNavOnClickSupport = useCallback(
        () => dispatch(analyticsBottomNavOnClick({ type: 'support' })),
        [dispatch],
    );

    const onMount = useCallback(
        () => dispatch(fetchCardDashboardSummaryDataModule.dataRequest()),
        [dispatch],
    );

    return (
        <BottomNav
            analyticsBottomNavOnClickCard={analyticsBottomNavOnClickCard}
            analyticsBottomNavOnClickHome={analyticsBottomNavOnClickHome}
            analyticsBottomNavOnClickMarketplace={
                analyticsBottomNavOnClickMarketplace
            }
            analyticsBottomNavOnClickShop={analyticsBottomNavOnClickShop}
            analyticsBottomNavOnClickSupport={analyticsBottomNavOnClickSupport}
            cardButtonRoute={cardButtonRoute}
            launchSupportToolHandler={launchSupportToolHandler}
            onMount={onMount}
        />
    );
};

export default BottomNavContainer;
