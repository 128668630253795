import { ofType } from 'redux-observable';
import { switchMap, mergeMap } from 'rxjs/operators';

import {
    fetchTrustPilotLink as fetchTrustPilotLinkAction,
    fetchTrustPilotLinkError,
    fetchTrustPilotLinkSuccess,
} from '@perpay-web/fintech/actions/entities/trustPilotLink';
import { TRUST_PILOT_ITERABLE_FEED_DATA } from '@perpay-web/fintech/constants/urls';
import { handleError } from '@perpay-web/observable/operators/handleError';

export const fetchTrustPilotLink = (action$, state$, { get }) =>
    action$.pipe(
        ofType(fetchTrustPilotLinkAction().type),
        switchMap(() => get(TRUST_PILOT_ITERABLE_FEED_DATA)),
        mergeMap((results) => [fetchTrustPilotLinkSuccess(results.response)]),
        handleError((error) => [fetchTrustPilotLinkError(error)]),
    );
