import { internalAuthenticatedJsonFetch } from '@perpay-web/authentication/requests';
import { HTTPMethod } from '@perpay-web/constants/httpMethods';
import { jsonFetch } from '@perpay-web/utils/requestUtils';

import { authentication } from './singleton';

export function authenticatedJsonFetch(
    url,
    body,
    method = HTTPMethod.get,
    headers = {},
) {
    return authentication.getRefreshedAccessToken().then(
        (accessToken) =>
            internalAuthenticatedJsonFetch(
                accessToken,
                url,
                body,
                method,
                headers,
            ),
        // Attempt to send unauthenticated if there was an error refreshing.
        () => jsonFetch(url, body, method, headers),
    );
}
