import { authentication } from '@perpay-web/authentication/singleton';
import { useDataModule } from '@perpay-web/data-module/useDataModule';
import { useReduxDataModuleHookConnection } from '@perpay-web/data-module/useReduxDataModuleHookConnection';
import {
    passkeyRegisterBegin,
    passkeyRegisterBeginSuccess,
    passkeyRegisterBeginError,
    passkeyRegisterBeginReset,
} from '@perpay-web/fintech/actions/passkey';
import { REACT_NATIVE_PASSKEY_AUTHENTICATION_IS_LAUNCHED } from '@perpay-web/fintech/constants/flags';
import { hookAction$ } from '@perpay-web/services/singletons';
import { createContextProvider } from '@perpay-web/utils/componentUtils';

const usePasskeyRegisterBeginDataModule =
    REACT_NATIVE_PASSKEY_AUTHENTICATION_IS_LAUNCHED
        ? () => {
              const dataModule = useDataModule(() =>
                  authentication.passkeyRegisterBegin(),
              );

              useReduxDataModuleHookConnection({
                  dataModule,
                  hookAction$,
                  request: passkeyRegisterBegin,
                  success: passkeyRegisterBeginSuccess,
                  error: passkeyRegisterBeginError,
                  reset: passkeyRegisterBeginReset,
              });

              return dataModule;
          }
        : () => ({}); // stub that allows destructuring the value

const providerFactory = (Context) => {
    const Provider = ({ children }) => {
        const dataModule = usePasskeyRegisterBeginDataModule();

        return (
            <Context.Provider value={dataModule}>{children}</Context.Provider>
        );
    };

    return Provider;
};

export const [UsePasskeyRegisterBeginDataModuleProvider] =
    createContextProvider(providerFactory);
