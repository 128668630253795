import { createDataModule } from '@perpay-web/data-module/createDataModule';
import {
    createPassword,
    createPasswordSuccess,
    createPasswordError,
    createPasswordReset,
} from '@perpay-web/fintech/actions/entities/partnerOnboarding';

const CreatePasswordDataModule = createDataModule({
    dataRequest: createPassword,
    dataSuccess: createPasswordSuccess,
    dataError: createPasswordError,
    dataReset: createPasswordReset,
});

const getRoot = (state) => state.dataModules.createPassword;

const initialValue = {
    password: '',
};

export const createPasswordDataModule = CreatePasswordDataModule({
    getRoot,
    initialValue,
});
