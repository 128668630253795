import { ofType } from 'redux-observable';
import { switchMap, mergeMap } from 'rxjs/operators';

import {
    replaceUserStatus,
    userStatusError,
    fetchUserStatusForDataModule as fetchUserStatusForDataModuleAction,
    fetchUserStatusSuccess,
    fetchUserStatusError,
} from '@perpay-web/fintech/actions/entities/userStatus';
import { BACKEND_FETCH_USER_STATUS } from '@perpay-web/fintech/constants/actionTypes';
import { USER_STATUS_ENDPOINT } from '@perpay-web/fintech/constants/urls';
import { handleErrorMessageWithFallback } from '@perpay-web/observable/operators/handleErrorMessageWithFallback';

export const fetchUserStatusForDataModule = (action$, state$, { get }) =>
    action$.pipe(
        ofType(fetchUserStatusForDataModuleAction().type),
        switchMap(() => get(`${USER_STATUS_ENDPOINT}`)),
        mergeMap((results) => [fetchUserStatusSuccess(results.response)]),
        handleErrorMessageWithFallback((error) => [
            fetchUserStatusError(error),
        ]),
    );

export const fetchUserStatus = (action$, state$, { get }) =>
    action$.pipe(
        ofType(BACKEND_FETCH_USER_STATUS),
        switchMap(() => get(`${USER_STATUS_ENDPOINT}`)),
        mergeMap((results) => [replaceUserStatus(results.response)]),
        handleErrorMessageWithFallback((error) => [userStatusError(error)]),
    );
