import { ofType } from 'redux-observable';
import { switchMap, mergeMap } from 'rxjs/operators';

import * as actions from '@perpay-web/fintech/actions/entities/payments';
import { PAYMENTS_ENDPOINT } from '@perpay-web/fintech/constants/urls';
import { handleErrorMessageWithFallback } from '@perpay-web/observable/operators/handleErrorMessageWithFallback';

export const fetchActivePayments = (action$, state$, { get }) =>
    action$.pipe(
        ofType(actions.fetchActivePayments().type),
        switchMap((action) => {
            let endpoint = `${PAYMENTS_ENDPOINT}?type=active_amount`;
            const payload = action.payload || {};

            if (payload.pageNumber) {
                endpoint = `${endpoint}&page=${payload.pageNumber}`;
            }

            return get(endpoint);
        }),
        mergeMap((results) => [
            actions.fetchActivePaymentsSuccess(results.response),
        ]),
        handleErrorMessageWithFallback((error) => [
            actions.fetchActivePaymentsError(error),
        ]),
    );
